import { Col, Form, notification, Row, Select } from "antd";
import React from "react";
import {
  PrimaryButton,
  SecondaryButton
} from "src/components/basic";
import { formatString, getErrorMessageFromResponse } from "src/lib/utils";
import { AUDIENCE_USER_ROLE_OPTIONS } from "../../constants/constants";
import audienceListService from "../../services/audienceListService";

export default function AudienceAddUserForm({
  setAddUserModal,
  formatData,
  audienceId,
  userLists
}) {
  const formRef = React.useRef();
  const [loading, setLoading] = React.useState(false);

  function validateEmail(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }

  return (
    <>
      <div>
        <Form
          requiredMark={false}
          ref={formRef}
          onFinish={async (val) => {
            try {
              setLoading(true);
              const payload = {
                audience_project_id: audienceId,
                user_role: {
                  ...val,
                },
              };
              const res = await audienceListService.addUserToAudience(payload);
              formatData(res);
              setAddUserModal(false);
              notification.success({
                message: "User added successfully",
                duration: 5,
                placement: "bottomRight",
              })
              setLoading(false);
            } catch (error) {
              const { msg } = getErrorMessageFromResponse(error);
              notification.error({
                message: msg,
                duration: 5,
                placement: "bottomRight",
              })
            }
          }}
          labelAlign="left"
          labelCol={{ span: 3 }}
        >
          <Form.Item
            label="Email*"
            name="email"
            rules={[
              {
                required: true,
                message: "Email is required",
              },
              {
                validator: (_, value) => {
                  if (value) {
                    if (!!!value.trim().length) {
                      return Promise.reject(new Error("Email is required."));
                    } else {
                      const test = validateEmail(value);
                      if (test) {
                        if (value.includes("galepartners.com")) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            new Error("You can only add GALE users")
                          );
                        }
                      } else {
                        return Promise.reject(new Error("Invalid Email"));
                      }
                    }
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Select
              placeholder="Select email"
              showSearch
              filterOption={(input, option) =>
                option.value.toLowerCase().includes(input.toLowerCase())
              }
            >
              {userLists.map((user) => (
                <Select.Option key={user.email} value={user.email}>
                  {user.email}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Role*"
            name="role"
            rules={[
              {
                required: true,
                message: "Role is required",
              },
            ]}
          >
            <Select placeholder="Enter role">
              {AUDIENCE_USER_ROLE_OPTIONS?.map((ele) => {
                if (ele !== "OWNER") {
                  return (
                    <Select.Option key={ele} value={ele}>
                      {formatString(ele)}
                    </Select.Option>
                  );
                }
              })}
            </Select>
          </Form.Item>
        </Form>
      </div>
      <Row justify="space-between">
        <Col>
          <SecondaryButton
            disabled={loading}
            comfy
            className="small"
            onClick={() => setAddUserModal(false)}
          >
            Cancel
          </SecondaryButton>
        </Col>
        <Col>
          <PrimaryButton
            loading={loading}
            comfy
            className="small"
            onClick={() => formRef.current.submit()}
          >
            Done
          </PrimaryButton>
        </Col>
      </Row>
    </>
  );
}
