import React from "react";
import ReactECharts from "echarts-for-react";
import { Col, Collapse, Row, Tabs } from "antd";
import LoadingSpinner from "src/components/loadingSpinner";
import "./MediaplanOutput.less";
import NcBreadCrumb from "src/modules/newsAndCulture/components/NcBreadCrumb";
import { PrimaryButton } from "src/components/basic";
import { numberWithCommas } from "src/lib/utils";
import moment from "moment";
import useMediaplanOutput from "./useMediaplanOutput";
import MediaPlanFlighting from "./MediaPlanFlighting";

const MediaplanOutput = () => {
  const {
    getOptions,
    loading,
    gantChartData,
    chhanelDataOptions,
    audienceSpend,
    funnelStage,
    spendByWeek,
    goBackToScenario,
  } = useMediaplanOutput();

  if (loading) {
    return <LoadingSpinner centered />;
  }
  return (
    <>
      <NcBreadCrumb />
      <h2>Media Output</h2>
      <Row justify="end" style={{ marginBottom: "1rem" }}>
        <PrimaryButton
          className="small"
          comfy
          onClick={() => goBackToScenario()}
        >
          Back
        </PrimaryButton>
      </Row>
      <Row className="header-container planning">
        <Col span={6}>
          <b>Media Plan Name:</b> {gantChartData?.mediaplan_name}
        </Col>
        <Col span={6}>
          <b>Budget:</b> ${numberWithCommas(gantChartData?.mediaplan_budget)}
        </Col>
        <Col span={6}>
          <b>Start Date:</b>{" "}
          {moment(gantChartData?.start_date).format("MM/DD/YYYY")}
        </Col>
        <Col span={6}>
          <b>End Date:</b>{" "}
          {moment(gantChartData?.end_date).format("MM/DD/YYYY")}
        </Col>
      </Row>
      <Tabs>
      <Tabs.TabPane tab="Overview" key="1">
          <div className="charts-row">
            <div className="chart-container">
              <h2>Total Spend by Channel</h2>
              <ReactECharts
                option={chhanelDataOptions}
                style={{ height: "400px", width: "100%" }}
              />
            </div>
            <div className="chart-container">
              <h2>Total Spend by Audience</h2>
              <ReactECharts
                option={audienceSpend}
                style={{ height: "400px", width: "100%" }}
              />
            </div>
          </div>
          <div className="charts-row">
            <div className="chart-container">
              <h2>Total Spend by Funnel Stage</h2>
              <ReactECharts
                option={funnelStage}
                style={{ height: "400px", width: "100%" }}
              />
            </div>
            <div className="chart-container">
              <h2>Total Spend by Week</h2>
              <ReactECharts
                option={spendByWeek}
                style={{ height: "400px", width: "100%" }}
              />
            </div>
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Flighting" key="2">
          <MediaPlanFlighting data={gantChartData} />
        </Tabs.TabPane>
      </Tabs>
    </>
  );
};

export default MediaplanOutput;
