import { InfoCircleOutlined, ProfileOutlined, ToolOutlined } from '@ant-design/icons';
import { Button, Card, Input, Radio, Select, Steps, Tooltip, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import LoadingSpinner from 'src/components/loadingSpinner';
import CreateCluster from 'src/modules/audienceTool/clusterTool/components/createCluster/CreateCluster';
import NcBreadCrumb from 'src/modules/newsAndCulture/components/NcBreadCrumb';
import { CLUSTER_BASED, PROPENSITY_TIERS, RULE_BASED, VIEWER } from '../../constants/constants';
import './createAudience.style.less';
import RecommendedAudiencesComponent from './RecommendedAudiencesComponent';
import BehaviourProbabilityComponent from './ruleBased/BehaviourProbabilityComponent';
import DataSourceTabLayout from './ruleBased/DataSourceTabLayout';
import useCreateAudienceHook from './useCreateAudienceHook';

const { Title } = Typography;
const { Step } = Steps;

function CreateAudience({ history, match }) {
    const [audienceAction, setAudienceAction] = useState('target');
    const [showRecommendation, setShowRecommendation] = useState(false);

    const id = match.params.clientId;
    const audienceId = match.params.audienceId;
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const audienceConfigId = queryParams.get('audience-config-id');
    const projectId = queryParams.get('audience-project-id');
    const projectName = queryParams.get('audience-project-name');

    const isEdit = !!audienceId;
    const [currentStep, setCurrentStep] = useState(isEdit ? 2 : 0);

    const {
        personaLoading,
        createPersona,
        selectedAudienceData,
        setSelectedAudienceData,
        createAudiences,
        createAudienceLoading,
        audienceSize,
        baseSize,
        audienceSizeLoading,
        getAudienceSize,
        audienceName,
        audienceDescription,
        setAudienceName,
        setAudienceDescription,
        selectedAudienceType,
        setSelectedAudienceType,
        audienceLoading,
        behaviourProbabilityData,
        setBehaviourSegmentCount,
        errors,
        setErrors,
        behaviourDirectory,
        audienceRole,
        audienceProjectList,
        audienceProjectName,
        setAudienceProjectName,
        audienceProjectId,
        setAudienceProjectId,
    } = useCreateAudienceHook(id, history, audienceId, audienceConfigId, isEdit, setCurrentStep, currentStep, projectId, projectName);

    const handleNext = () => setCurrentStep(currentStep + 1);
    const handleBack = () => setCurrentStep(currentStep - 1);
    const handleSubmit = (profiling) => createAudiences(audienceName, audienceDescription, selectedAudienceData, profiling);
    const handleCreatePersona = () => createPersona(audienceName, audienceDescription, selectedAudienceData);

    useEffect(() => {
        if (selectedAudienceType.length > 0) {
            setSelectedAudienceData({
                ...selectedAudienceData,
                audienceType: selectedAudienceType,
                rules: selectedAudienceType === RULE_BASED ? selectedAudienceData.rules : {},
                selectedClusters: selectedAudienceType === CLUSTER_BASED ? selectedAudienceData.selectedClusters : {}
            });
        }
    }, [selectedAudienceType]);

    if (audienceLoading) {
        return <LoadingSpinner centered />;
    }

    return (
        <div className="createAudienceContainer">
            <NcBreadCrumb className='breadCrumb' />
            <Title level={2} className="title"> {isEdit ? 'Edit Audience' : 'Create Audience'} </Title>
            <Steps current={currentStep} direction="horizontal" className="steps">
                <Step title={<span className={currentStep >= 0 ? 'step-title-active' : 'step-title'}>Select Method</span>} icon={<ToolOutlined className={currentStep >= 0 ? 'step-icon-active' : 'step-icon'} />} />
                <Step title={<span className={currentStep >= 1 ? 'step-title-active' : 'step-title'}> Write Project Brief</span>} icon={<InfoCircleOutlined className={currentStep >= 1 ? 'step-icon-active' : 'step-icon'} />} />
                {selectedAudienceType === RULE_BASED && (
                    <>
                        <Step title={<span className={currentStep >= 2 ? 'step-title-active' : 'step-title'}>Review & Select Features</span>} icon={<ProfileOutlined className={currentStep >= 2 ? 'step-icon-active' : 'step-icon'} />} />
                        <Step title={<span className={currentStep >= 3 ? 'step-title-active' : 'step-title'}>Adjust Precision</span>} icon={<ProfileOutlined className={currentStep >= 3 ? 'step-icon-active' : 'step-icon'} />} />
                    </>
                )}
            </Steps>
            <div style={{
                width: '100%',
                minHeight: '650px',
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
            }}>
                <div className="button-container">
                    {currentStep > 0 && selectedAudienceType !== CLUSTER_BASED && currentStep !== 2 && (
                        <Button
                            className='small button-margin-style' type="default" onClick={handleBack} style={{ marginRight: '10px' }} size="small" disabled={createAudienceLoading}>
                            Back
                        </Button>
                    )}
                    {(selectedAudienceType === RULE_BASED && currentStep < 2) || (selectedAudienceType === CLUSTER_BASED && currentStep === 0) ? (
                        <Button
                            className='small button-margin-style' type="primary" onClick={handleNext} size="small">
                            Next
                        </Button>
                    ) : (
                        (selectedAudienceType !== CLUSTER_BASED && currentStep !== 2) && (
                            <>
                                <Button
                                    className='small button-margin-style'
                                    type="primary"
                                    onClick={() => handleSubmit(false)}
                                    size="small"
                                    loading={createAudienceLoading}
                                    disabled={createAudienceLoading || audienceRole === VIEWER}
                                >
                                    {currentStep === 2 ? 'Save and Continue' : 'Save as Draft'}
                                </Button>
                                {currentStep === 3 && (
                                    <Button
                                        className='small button-margin-style'
                                        type="default"
                                        onClick={() => handleSubmit(true)}
                                        size="small"
                                        loading={createAudienceLoading}
                                        disabled={createAudienceLoading || audienceRole === VIEWER}
                                        style={{ marginLeft: '10px' }}
                                    >
                                        Save And Profile
                                    </Button>
                                )}
                            </>
                        )
                    )}
                </div>
                <div>
                    {currentStep === 0 && (
                        <StepZero
                            showRecommendation={showRecommendation}
                            setShowRecommendation={setShowRecommendation}
                            setSelectedAudienceType={setSelectedAudienceType}
                            selectedAudienceType={selectedAudienceType}
                            match={match}
                            setAudienceName={setAudienceName}
                            setAudienceDescription={setAudienceDescription}
                            setCurrentStep={setCurrentStep}
                            audienceRole={audienceRole}
                        />
                    )}
                    {currentStep === 1 && (
                        <StepOne
                            selectedAudienceType={selectedAudienceType}
                            audienceName={audienceName}
                            setAudienceName={setAudienceName}
                            audienceDescription={audienceDescription}
                            setAudienceDescription={setAudienceDescription}
                            setAudienceAction={setAudienceAction}
                            audienceAction={audienceAction}
                            history={history}
                            match={match}
                            handleBack={handleBack}
                            audienceRole={audienceRole}
                            audienceProjectList={audienceProjectList}
                            audienceProjectName={audienceProjectName}
                            setAudienceProjectName={setAudienceProjectName}
                            audienceProjectId={audienceProjectId}
                            setAudienceProjectId={setAudienceProjectId}
                        />
                    )}
                    {currentStep === 2 && (
                        <StepTwo
                            selectedAudienceType={selectedAudienceType}
                            id={id}
                            audienceName={audienceName}
                            audienceDescription={audienceDescription}
                            selectedAudienceData={selectedAudienceData}
                            setSelectedAudienceData={setSelectedAudienceData}
                            audienceSize={audienceSize}
                            baseSize={baseSize}
                            audienceSizeLoading={audienceSizeLoading}
                            getAudienceSize={getAudienceSize}
                            errors={errors}
                            setErrors={setErrors}
                            behaviourDirectory={behaviourDirectory}
                            currentStep={currentStep}
                            createAudienceLoading={createAudienceLoading}
                            handleNext={handleNext}
                            handleBack={handleBack}
                            handleSubmit={(val) => handleSubmit(val)}
                            audienceRole={audienceRole}
                        />
                    )}
                    {currentStep === 3 && (
                        <StepThree
                            behaviourProbabilityData={behaviourProbabilityData}
                            setBehaviourSegmentCount={setBehaviourSegmentCount}
                            audienceRole={audienceRole}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

const StepZero = ({
    showRecommendation,
    setShowRecommendation,
    setSelectedAudienceType,
    selectedAudienceType,
    match,
    setAudienceName,
    setAudienceDescription,
    setCurrentStep,
    audienceRole
}) => (
    <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '20px',
    }}>
        {!showRecommendation && (
            <>
                <h2 style={{ color: '#fff', textAlign: 'center', marginBottom: '20px', fontWeight: 'bold' }}>
                    How would you like to create the audience?
                </h2>
                <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', marginTop: '20px' }}>
                    <Card
                        hoverable
                        className={`card ${selectedAudienceType === RULE_BASED ? 'card-primary' : 'card-gray'} card-hover`}
                        onClick={() => audienceRole !== VIEWER && setSelectedAudienceType(RULE_BASED)}
                    >
                        <Radio
                            checked={selectedAudienceType === RULE_BASED}
                            onChange={() => setSelectedAudienceType(RULE_BASED)}
                            style={{ position: 'absolute', top: '10px', left: '10px' }}
                            disabled={audienceRole === VIEWER}
                        />
                        <Card.Meta
                            title={<span className="card-meta-title">Rule Based</span>}
                            description={<span className="card-meta-description card-meta-description-digital">Create an audience based on rules.  <CardTooltip title={'Use this method to create a specific, single segment of consumers who match pre-defined requirements for demographics, attitudes or behaviors. For example, “Women age 50+ who exercise 3+ times per week”.'}><InfoCircleOutlined /></CardTooltip></span>}
                        />
                    </Card>
                    <Card
                        hoverable
                        className={`card ${selectedAudienceType === CLUSTER_BASED ? 'card-primary' : 'card-gray'} card-hover`}
                        onClick={() => audienceRole !== VIEWER && setSelectedAudienceType(CLUSTER_BASED)}
                    >
                        <Radio
                            checked={selectedAudienceType === CLUSTER_BASED}
                            onChange={() => setSelectedAudienceType(CLUSTER_BASED)}
                            style={{ position: 'absolute', top: '10px', left: '10px' }}
                            disabled={audienceRole === VIEWER}
                        />
                        <Card.Meta
                            title={<span className="card-meta-title">Cluster</span>}
                            description={<span className="card-meta-description card-meta-description-digital">Create an audience based on clusters. <CardTooltip title={'Use this method to create a specific, single segment of consumers who match pre-defined requirements for demographics, attitudes or behaviors. For example, “Women age 50+ who exercise 3+ times per week”.'}><InfoCircleOutlined /></CardTooltip></span>}
                        />
                    </Card>
                    <Card
                        className="card card-disabled"
                    >
                        <Radio
                            checked={selectedAudienceType === PROPENSITY_TIERS}
                            onChange={() => setSelectedAudienceType(PROPENSITY_TIERS)}
                            style={{ position: 'absolute', top: '10px', left: '10px' }}
                            disabled
                        />
                        <Card.Meta
                            title={<span className="card-meta-title">Propensity Tiers</span>}
                            description={<span className="card-meta-description card-meta-description-digital">Coming Soon.. <CardTooltip title={'Use this method when you want to find the X% most likely consumers to do a certain behavior or agree with a particular attitude statement. For example, “The top 20% most likely consumers to book a luxury cruise package”.'}><InfoCircleOutlined /></CardTooltip></span>}
                        />
                    </Card>
                </div>
            </>
        )}
        {showRecommendation && (
            <RecommendedAudiencesComponent
                match={match}
                setShowRecommendation={setShowRecommendation}
                setAudienceName={setAudienceName}
                setAudienceDescription={setAudienceDescription}
                setCurrentStep={setCurrentStep}
                setSelectedAudienceType={setSelectedAudienceType}
            />
        )}
    </div>
);

const StepOne = ({
    selectedAudienceType,
    audienceName,
    setAudienceName,
    audienceDescription,
    setAudienceDescription,
    setAudienceAction,
    audienceAction,
    history,
    match,
    handleBack,
    audienceRole,
    audienceProjectList,
    audienceProjectName,
    setAudienceProjectName,
    audienceProjectId,
    setAudienceProjectId,
}) => {

    const handleProjectChange = (value, option) => {
        if (option) {
            setAudienceProjectName(option.value);
            setAudienceProjectId(option.key);
        } else {
            setAudienceProjectName(value);
            setAudienceProjectId('');
        }
    };

    const handleProjectBlur = (event) => {
        const value = event.target.value;
        if (value && !audienceProjectList.some(project => project.name === value)) {
            setAudienceProjectName(value);
            setAudienceProjectId('');
        }
    };

    return (
        <>
            {selectedAudienceType === CLUSTER_BASED && (
                <div className="formItem" style={{ marginTop: '10px' }}>
                    <CreateCluster history={history} match={match} fromAudience={true} onBackFromAudience={() => handleBack()} />
                </div>
            )}
            {selectedAudienceType === RULE_BASED && (
                <>

                    <div className="formItem">
                        <label>Project Name</label>
                        <p className="helper-text">Select an existing project name or type a new one.</p>
                        <Select
                            showSearch
                            placeholder="Select Project Name"
                            value={audienceProjectName || undefined} // Ensure placeholder is shown when value is empty
                            onChange={handleProjectChange}
                            onSearch={setAudienceProjectName}
                            onBlur={handleProjectBlur}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            disabled={audienceRole === VIEWER}
                            style={{ width: '100%' }}
                        >
                            {audienceProjectList.map(project => (
                                <Select.Option key={project.id} value={project.name}>
                                    {project.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </div>
                    <div className="formItem">
                        <label>Audience Name</label>
                        <Input
                            placeholder="Enter Name of the Audience"
                            className="input"
                            value={audienceName}
                            onChange={(e) => setAudienceName(e.target.value)}
                            disabled={audienceRole === VIEWER}
                        />
                    </div>
                    <div className="formItem">
                        <label>Describe Audience</label>
                        <TextArea
                            placeholder="Describe the Audience"
                            className="input"
                            value={audienceDescription}
                            rows={4}
                            onChange={(e) => setAudienceDescription(e.target.value)}
                            disabled={audienceRole === VIEWER}
                        />
                    </div>
                </>
            )}
        </>
    );
};

const StepTwo = ({
    selectedAudienceType,
    id,
    audienceName,
    audienceDescription,
    selectedAudienceData,
    setSelectedAudienceData,
    audienceSize,
    baseSize,
    audienceSizeLoading,
    getAudienceSize,
    errors,
    setErrors,
    behaviourDirectory,
    currentStep,
    createAudienceLoading,
    handleNext,
    handleBack,
    handleSubmit,
    audienceRole
}) => (
    <div className="formItem">
        {selectedAudienceType === RULE_BASED && (
            <DataSourceTabLayout
                clientId={id}
                audienceName={audienceName}
                audienceDescription={audienceDescription}
                selectedAudienceData={selectedAudienceData}
                setSelectedAudienceData={setSelectedAudienceData}
                audienceSize={audienceSize}
                baseSize={baseSize}
                audienceSizeLoading={audienceSizeLoading}
                getAudienceSize={getAudienceSize}
                errors={errors}
                setErrors={setErrors}
                behaviourDirectory={behaviourDirectory}
                fromAudience={true}
                currentStep={currentStep}
                selectedAudienceType={selectedAudienceType}
                createAudienceLoading={createAudienceLoading}
                onHandleBackFromDataSoruceTable={handleBack}
                onHandleNextFromDataSoruceTable={handleNext}
                handleSubmitFromDataSourceTable={(val) => handleSubmit(val)}
                audienceRole={audienceRole}
            />
        )}
    </div>
);

const StepThree = ({
    behaviourProbabilityData,
    setBehaviourSegmentCount,
    audienceRole
}) => (
    <div className="formItem">
        <BehaviourProbabilityComponent
            behaviourProbabilityData={behaviourProbabilityData}
            setBehaviourSegmentCount={setBehaviourSegmentCount}
            audienceRole={audienceRole}
        />
    </div>
);

const CardTooltip = ({ title, children }) => (
    <Tooltip
        placement='bottom'
        overlayStyle={{ border: '1px solid #fff', padding: '10px', borderRadius: '5px', backgroundColor: '#000' }}
        title={title}
    >
        {children}
    </Tooltip>
)

export default CreateAudience;