import { Button, Col, Form, Input, Row, Select, Spin } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import { PrimaryButton } from "src/components/basic";
import ConditionalRender from "src/components/conditionalRender";
import LoadingSpinner from "src/components/loadingSpinner";
import appUrls from "src/constants/appUrls";
import { numberWithCommas } from "src/lib/utils";
import { VIEWER } from "src/modules/audienceTool/audienceManager/constants/constants";
import { CustomGptService } from "src/modules/CustomGpt/services/customGptServices";
import NcBreadCrumb from "src/modules/newsAndCulture/components/NcBreadCrumb";
import { CLUSTERING_TYPE } from "../../constants/constants";
import Style from "./createClusters.style";
import useCreateClusterHook from "./useCreateClusterHook";

export default function CreateCluster({ history, match, fromAudience = false, onBackFromAudience }) {
  const {
    loading,
    formRef,
    createClusterConfig,
    configureLoading,
    table,
    getAudienceSize,
    audienceCount,
    audienceSizeLoading,
    initialValues,
    clientSpaceName,
    editDescription,
    setEditDescription,
    clusteringConfig,
    editMode,
    viewMode,
    nextButton,
    id,
    clusterId,
    savedChanges,
    setSavedChanges,
    audienceConfigId,
    audienceRole
  } = useCreateClusterHook({ match, history, fromAudience });
  if (loading) {
    return <LoadingSpinner centered={true} />;
  }
  return (
    <>
      {!fromAudience &&
        <NcBreadCrumb className={Style.breadCrumb} />
      }
      <Row justify="space-between">
        <Col>
        </Col>
        <Col>
          <Row gutter={[20, 0]}>

            <ConditionalRender shouldRender={fromAudience}>
              <Col>
                <Button
                  type="default"
                  className="small"
                  disabled={configureLoading}
                  onClick={() => {
                    onBackFromAudience()
                  }}
                >
                  Back
                </Button>
              </Col>
            </ConditionalRender>
            <ConditionalRender shouldRender={!!clusterId}>
              <Col>
                <PrimaryButton
                  className="small"
                  loading={configureLoading}
                  onClick={() => {
                    if (viewMode) {
                      if (match.path.includes('audience-tool')) {
                        history.push(appUrls.SELECT_FEATURES_TOOL(id, clusterId, audienceConfigId))
                      } else {
                        history.push(appUrls.SELECT_FEATURES(id, clusterId, audienceConfigId));
                      }
                    } else if (savedChanges) {
                      formRef.current.submit()
                    } else {
                      if (match.path.includes('audience-tool')) {
                        history.push(appUrls.SELECT_FEATURES_TOOL(id, clusterId, audienceConfigId))
                      } else {
                        history.push(appUrls.SELECT_FEATURES(id, clusterId, audienceConfigId));
                      }
                    }

                  }}
                >
                  Next
                </PrimaryButton>
              </Col>
            </ConditionalRender>
            <ConditionalRender shouldRender={!!!clusterId}>
              <Col>
                <PrimaryButton loading={configureLoading} className="small" onClick={() => formRef.current.submit()}>
                  Save and continue
                </PrimaryButton>
              </Col>
            </ConditionalRender>
          </Row>
        </Col>
      </Row>
      <div className={Style.formContainer} style={{ flexGrow: "1" }}>
        <Form
          initialValues={initialValues}
          ref={formRef}
          labelAlign="left"
          requiredMark={false}
          layout="vertical"
          scrollToFirstError
          onFinish={(val) => {
            createClusterConfig(val);
          }}
          onValuesChange={(changedValues, allValues) => {
            if (!savedChanges) {
              setSavedChanges(true);
            }
            if (changedValues?.description) {
              setEditDescription(true);
            }
            if (changedValues?.input_table) {
              getAudienceSize(changedValues?.input_table, allValues?.clusters_type);
            }
            if (changedValues?.clusters_type) {
              if (!editDescription) {
                if (clusteringConfig) {
                  formRef.current.setFieldsValue({
                    description: clusteringConfig?.[changedValues?.clusters_type],
                  });
                }
              }
              if (allValues?.input_table) {
                getAudienceSize(allValues?.input_table, changedValues?.clusters_type);
              }
            }
          }}
        >
          <Row gutter={[30, 0]}>
            <Col span={24}>
              <Form.Item
                name="name"
                label="Project Name"
                rules={[
                  {
                    validator: (_, value) => {
                      if (value) {
                        if (value.trim().length === 0) {
                          return Promise.reject(new Error("Cluster name is required"));
                        } else {
                          const regex = /^[a-zA-Z0-9 ]+$/;
                          if (!regex.test(value)) {
                            return Promise.reject(new Error("Cluster name should only contain alphanumeric characters and spaces"));
                          } else {
                            return Promise.resolve();
                          }
                        }
                      }
                      return Promise.resolve();
                    },
                  },
                  { required: true, message: "Cluster Name is required" },
                ]}
              >
                <Input placeholder="Enter name" disabled={editMode || viewMode || audienceRole === VIEWER} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="clusters_type"
                label="What approach would you like to take here? Acquisition will identify audiences outside of your customer base that look like your current customers, while CRM will identify audiences within your first party customer data."
                rules={CustomGptService.validations({ required: true }, "Clusters Type")}
              >
                <Select placeholder="Select Cluster Type" disabled={editMode || viewMode || audienceRole === VIEWER}>
                  {CLUSTERING_TYPE.map((item, idx) => {
                    return (
                      <Select.Option value={item} key={`option-${idx}`}>
                        {item}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="description"
                label="Describe how Client will use Clusters"
                rules={CustomGptService.validations({ required: true }, "Description")}
              >
                <TextArea autoSize={{ maxRows: 6, minRows: 3 }} placeholder="Enter description" disabled={viewMode || audienceRole === VIEWER} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="input_table"
                label="This is the data table that will do the magic. Only change if you have a customized table created for you"
                rules={CustomGptService.validations({ required: true }, "Table")}
              >
                <Select placeholder="Select Table" showSearch loading={audienceSizeLoading} disabled={editMode || viewMode || audienceRole === VIEWER}>
                  {table.map((item, idx) => {
                    return (
                      <Select.Option value={item} key={`option-${idx}`}>
                        {item}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <h4 className={Style.audienceSize}>
                <i>
                  Audience size:&nbsp;{audienceSizeLoading ? <Spin /> : audienceCount ? <b>{numberWithCommas(audienceCount)}</b> : "N/A"}
                </i>
              </h4>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
}
