import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import React, { useEffect } from 'react';
import { GeoJSON, MapContainer, TileLayer, useMap } from 'react-leaflet';
import LoadingSpinner from "src/components/loadingSpinner";
import { formatNumberWithCeil } from 'src/lib/utils';
import useGeographicHook from "./useGeographicHook";

const GeographicHeatMap = ({ audienceId }) => {
    const { geographicLoading, geographicData, dmaData } = useGeographicHook({ audienceId });

    const getColor = (d) => {
        const thresholds = [
          { limit: 200, color: '#990000' },
          { limit: 150, color: '#d7301f' },
          { limit: 100, color: '#ef6548' },
          { limit: 50, color: '#fc8d59' },
          { limit: 20, color: '#fdbb84' },
          { limit: 10, color: '#fdd49e' },
          { limit: 0, color: '#fee8c8' },
        ];
      
        for (const { limit, color } of thresholds) {
          if (d > limit) {
            return color;
          }
        }
      
        return '#fff7ec';
      };

    const style = (feature) => {
        const dma = feature.properties.DMA;
        const featureInfo = geographicData.find(f => f.feature_value === dma);
        const indexValue = featureInfo ? featureInfo.index_value : 0;
        return {
            fillColor: getColor(indexValue),
            weight: 1,
            opacity: 1,
            color: 'black',
            fillOpacity: 0.7
        };
    };

    const onEachFeature = (feature, layer) => {
        if (feature.properties && feature.properties.DMA) {
            const dma = feature.properties.DMA;
            const featureInfo = geographicData.find(f => f.feature_value === dma);
            const indexValue = featureInfo ? Math.round(featureInfo.index_value) : 0;
            const audienceFeaturePercentage = featureInfo ? formatNumberWithCeil(featureInfo.audience_feature_percentage) : 0;
            const baseFeaturePercentage = featureInfo ? formatNumberWithCeil(featureInfo.base_feature_percentage) : 0;
            layer.bindTooltip(
                `<div>
                    <strong>${feature.properties.NAME}</strong><br/>
                    Index Value: ${indexValue}<br/>
                    Audience Feature Percentage: ${audienceFeaturePercentage}%<br/>
                    Base Feature Percentage: ${baseFeaturePercentage}%
                </div>`,
                { permanent: false, direction: 'auto' }
            );
        }
    };

    const Legend = () => {
        const map = useMap();

        useEffect(() => {
            const legend = L.control({ position: "bottomright" });
            legend.onAdd = () => {
                const div = L.DomUtil.create("div", "info legend");
                const grades = [-1, 0, 10, 20, 50, 100, 150, 200];
                const labels = [];

                for (let i = 0; i < grades.length; i++) {
                    labels.push(
                        `<div style="display: flex; align-items: center;">
                            <span style="background:${getColor(grades[i] + 1)}; width: 20px; height: 20px; display: inline-block; margin-right: 8px;"></span>
                            ${grades[i] + 1}${grades[i + 1] ? `&ndash;${grades[i + 1]}` : (grades[i] === -1 ? "" : "+")}
                        </div>`
                    );
                }

                div.innerHTML = `
                    <h4>Index Value</h4>
                    ${labels.join("")}
                `;
                return div;
            };
            legend.addTo(map);
            map.zoomControl.setPosition('bottomleft');

            return () => legend.remove();
        }, [map]);

        return null;
    };

    if (geographicLoading) {
        return <LoadingSpinner centered />;
    }

    return (
        <>
            <p style={{ textAlign: 'left', fontSize: '16px', marginTop: '20px', marginLeft: '20px', color: 'white' }}>
                This interactive heatmap visualizes audience engagement levels across geographic regions, using color intensity to represent varying index values. Higher index values are shown in warmer colors, indicating regions with stronger engagement, while cooler colors represent areas with lower engagement. Hover over each region to see specific metrics, making it easy to identify key audience areas and understand regional performance at a glance.
            </p>
            <MapContainer
                center={[36, -96.5]} // Centered on the United States
                zoom={4.5}
                style={{ height: "900px", width: "100%", backgroundColor: "transparent" }}
                zoomControl={true}
                touchZoom={false}
                doubleClickZoom={false}
                scrollWheelZoom={false}
                boxZoom={false}
                attributionControl={false}
            >
                <TileLayer
                    url="https://api.mapbox.com/styles/v1/mapbox/dark-v10/tiles/{z}/{x}/{y}?access_token=YOUR_MAPBOX_ACCESS_TOKEN"
                    tileSize={512}
                    zoomOffset={-1}
                />
                {dmaData && (
                    <GeoJSON data={dmaData} style={style} onEachFeature={onEachFeature} />
                )}
                <Legend />
            </MapContainer>
        </>
    );
};

export default GeographicHeatMap;