import React from "react";
import "./MediaPlanFlighting.less";
import useMediaPlanFlighting from "./useMediaPlanFlighting";
import { Col, Row } from "antd";
import FlightingTable from "./FlightingTable";

const MediaPlanFlighting = ({ data }) => {
  const { tab, tabData, onTabChange } = useMediaPlanFlighting({ data });
  return (
    <>
      <Row gutter={[20, 20]}>
        {data?.flighting.map((flighting) => {
          return (
            <Col>
              <div
                onClick={() => onTabChange(flighting?.business_goal, flighting?.business_goal_value)}
                className={`tabs-container ${
                  tab === flighting?.business_goal && "activeTab-container"
                }`}
              >
                {flighting?.business_goal}
              </div>
            </Col>
          );
        })}
      </Row>
      {
        tab && <FlightingTable tab={tab} tabData={tabData} />
      }
      
    </>
  );
};

export default MediaPlanFlighting;
