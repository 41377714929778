import { DeleteOutlined, InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, DatePicker, Drawer, Input, Select, Tooltip } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import {
    ADDITIONAL_CATEGORY_OPERATORS,
    CATEGORY_OPERATORS,
    COMBINATOR_AND,
    COMBINATOR_ANY,
    COMBINATOR_OR,
    DATE_OPERATORS,
    LABEL_BEHAVIOUR_GROUP,
    LABEL_DEMOGRAPHIC_GROUP,
    NUMERICAL_OPERATORS,
    OPERATOR_BETWEEN,
    OPERATOR_CONTAINS,
    OPERATOR_ENDS_WITH,
    OPERATOR_EQUALS,
    OPERATOR_IN,
    OPERATOR_IS,
    OPERATOR_LIKE,
    OPERATOR_NOT_BETWEEN,
    OPERATOR_NOT_IN,
    OPERATOR_NOT_LIKE,
    OPERATOR_STARTS_WITH,
    QUERY_OPERATORS,
    SPECIAL_FIELDS
} from "../../../constants/queryBuilderConstants";
import BrowseBehaviourFeaturesComponent from './BrowseBehaviourFeaturesComponent';
import "./dataSourceTabLayout.style.less";

const { Option } = Select;

const QueryBuilder = ({ conditions, setConditions, fields, searchLoading, handleSearch, isReadOnly = false, errors = {}, setErrors, behaviourDirectory = {}, browsedSelectedItems, setBrowsedSelectedItems, addFeaturesToFields }) => {

    const [showBrowseDrawer, setShowBrowseDrawer] = useState(false)
    const [browseParent, setBrowseParent] = useState(null)
    const [applyRules, setApplyRules] = useState(false)
    const [fieldDirectory, setFieldDirectory] = useState({})

    const addBrowsedItemsToGroup = (parent, selectedItems) => {
        if (isReadOnly) return;

        // Remove any rules in parent.rules that are not in selectedItems, ignoring the ones where rule.field is undefined
        parent.rules = parent.rules.filter(rule => rule.field === undefined || selectedItems.includes(rule.field));

        // Iterate over each selected item and add it as a rule to the parent group if it doesn't already exist
        selectedItems.forEach(item => {
            if (!parent.rules.some(rule => rule.field === item)) {
                if (parent.rule_type === 'behavioural') {
                    parent.rules.push({
                        field: item,
                        behaviour_name: item,
                        "value": {
                            "value": "1",
                            "value_description": "True"
                        },
                        "operator": "is",
                    });
                } else {
                    parent.rules.push({ field: item, operator: OPERATOR_EQUALS, value: '' });
                }
            }
        });

        // Update the conditions state
        setConditions({ ...conditions });
    };

    const handleCancel = () => {
        setBrowsedSelectedItems([]);
        setBrowseParent(null);
        setShowBrowseDrawer(false);
    };

    // Example usage when the user confirms their selection
    const handleBrowseConfirm = () => {
        setApplyRules(true);
        addFeaturesToFields(browsedSelectedItems)
        addBrowsedItemsToGroup(browseParent, browsedSelectedItems);
        setShowBrowseDrawer(false);
        setApplyRules(false);
    }

    const addRule = (parent) => {
        if (isReadOnly) return;
        setErrors({});
        if (parent.rule_type === 'behavioural') {
            setFieldDirectory(behaviourDirectory);
            setBrowseParent(parent);
            if (parent.rules && Array.isArray(parent.rules)) {
                const allFields = parent.rules.reduce((acc, rule) => {
                    if (rule.field && rule.field.length > 0) {
                        return acc.concat(rule.field);
                    }
                    return acc;
                }, []);
                setBrowsedSelectedItems(allFields);
            } else {
                setBrowsedSelectedItems([]);
            }
            setShowBrowseDrawer(true);
        } else {
            parent.rules.push({ field: '', operator: OPERATOR_EQUALS, value: '' });
            setConditions({ ...conditions });
        }
    };

    const addGroup = (parent) => {
        if (isReadOnly) return;
        setErrors({});
        parent.rules.push({ combinator: COMBINATOR_AND, rules: [{ field: '', operator: OPERATOR_EQUALS, value: '' }], rule_type: parent?.rule_type });
        setConditions({ ...conditions });
    };

    const addFeatureGroup = (parent, feature) => {
        if (isReadOnly) return;
        parent.rules.push({ combinator: COMBINATOR_AND, rules: [{ field: '', operator: OPERATOR_EQUALS, value: '' }], rule_type: feature });
        setConditions({ ...conditions });
    };

    const removeRuleOrGroup = (parent, index) => {
        if (isReadOnly) return;
        parent.rules.splice(index, 1);
        setConditions({ ...conditions });
    };

    const getOperatorsByValueEditorType = (valueEditorType, name) => {
        switch (valueEditorType) {
            case 'select':
                if (SPECIAL_FIELDS.includes(name)) {
                    return [...CATEGORY_OPERATORS, ...ADDITIONAL_CATEGORY_OPERATORS];
                }
                return CATEGORY_OPERATORS;
            case 'number':
                return NUMERICAL_OPERATORS;
            case 'date':
                return DATE_OPERATORS;
            case 'text':
                return QUERY_OPERATORS;
            default:
                return [];
        }
    };

    const handleFieldChange = (rule, field, value) => {
        if (isReadOnly) return;
        setErrors({});
        rule[field] = value;
        if (field === 'field') {
            const allFields = [...fields.demographic_features, ...fields.behaviour_features];
            const selectedField = allFields.find(f => f.name === value);
            if (selectedField && selectedField.category === 'Behaviour') {
                rule.operator = OPERATOR_IS;
                rule.behaviour_name = value;
            } else {
                rule.operator = OPERATOR_EQUALS;
            }
        }
        if (field === 'value') {
            if (rule.operator === OPERATOR_BETWEEN || rule.operator === OPERATOR_NOT_BETWEEN) {
                if (Array.isArray(value) && value.length === 2) {
                    const formattedValue = value.map(val => (typeof val === 'object' ? {
                        ...val,
                        value_description: val.value
                    } :
                        { value: val, value_description: val }));
                    rule[field] = formattedValue;
                }
            } else if (typeof value === 'object' && value !== null && (rule.operator === OPERATOR_STARTS_WITH || rule.operator === OPERATOR_ENDS_WITH || rule.operator === OPERATOR_CONTAINS)) {
                const formattedValue = { value: value.value, value_description: value.value };
                rule[field] = formattedValue;
            } else if (typeof value !== 'object' && value !== null) {
                const formattedValue = { value: value, value_description: value };
                rule[field] = formattedValue;
            } else if (typeof value === 'object' && value !== null) {
                if (Array.isArray(value)) {
                    const formattedValue = value.map(val => (typeof val === 'object' ? {
                        value: val.value,
                        value_description: val.value_description ? val.value_description : val.value
                    } :
                        { value: val, value_description: val }));
                    rule[field] = formattedValue;
                } else {
                    const formattedValue = { value: value.value, value_description: value.value_description ? value.value_description : value.value };
                    rule[field] = formattedValue;
                }
            }
        }
        setConditions({ ...conditions });
    };

    const handleCombinatorChange = (condition, value) => {
        if (isReadOnly) return;
        condition.combinator = value;
        if (value === COMBINATOR_ANY) {
            condition.number = 1;
        } else {
            delete condition.number;
        }
        setConditions({ ...conditions });
    };

    const handleNumberChange = (condition, value) => {
        if (isReadOnly) return;
        condition.number = value;
        setConditions({ ...conditions });
    };

    const renderValueEditor = (rule, hasPredefinedValues, field) => {
        const format = field.format || 'YYYYMMDD';

        switch (rule.operator) {
            case OPERATOR_IN:
            case OPERATOR_NOT_IN:
                if (field.valueEditorType === 'select') {
                    return (
                        <Select
                            mode="multiple"
                            value={Array.isArray(rule.value) ? rule.value.filter(val => val !== "") : (rule.value !== "" ? rule.value : undefined)}
                            onChange={(value) => {
                                const selectedVals = Array.isArray(value)
                                    ? value.map(val => hasPredefinedValues.find(preVal => preVal.value === val)).filter(val => val !== undefined)
                                    : hasPredefinedValues.find(val => val.value === value);
                                handleFieldChange(rule, 'value', selectedVals);
                            }}
                            placeholder="Select values"
                            style={{ width: "30%", marginRight: 10 }}
                            disabled={isReadOnly}
                        >
                            {hasPredefinedValues.map((val) => (
                                <Select.Option key={val.value} value={val.value}>
                                    {val.value_description}
                                </Select.Option>
                            ))}
                        </Select>
                    );
                } else {
                    return (
                        <Select
                            mode="multiple"
                            value={Array.isArray(rule.value) ? rule.value.filter(val => val !== "") : (rule.value !== "" ? rule.value : undefined)}
                            onChange={(value) => {
                                const selectedVals = Array.isArray(value)
                                    ? value.map(val => hasPredefinedValues.find(preVal => preVal.value === val)).filter(val => val !== undefined)
                                    : hasPredefinedValues.find(val => val.value === value);
                                handleFieldChange(rule, 'value', selectedVals);
                            }}
                            placeholder="Enter values"
                            style={{ width: "30%", marginRight: 10 }}
                            tokenSeparators={[',']}
                            disabled={isReadOnly}
                        />
                    );
                }
            case OPERATOR_BETWEEN:
            case OPERATOR_NOT_BETWEEN:
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {field.valueEditorType === 'date' ? (
                            <>
                                <DatePicker
                                    value={rule?.value?.[0]?.value ? moment(rule.value[0].value, format) : null}
                                    onChange={(date) => handleFieldChange(rule, 'value', [{ value: date ? date.format(format) : null }, rule.value[1]])}
                                    placeholder="Start"
                                    style={{ width: "30%", marginRight: 10 }}
                                    disabled={isReadOnly}
                                />
                                <DatePicker
                                    value={rule?.value?.[1]?.value ? moment(rule.value[1].value, format) : null}
                                    onChange={(date) => handleFieldChange(rule, 'value', [rule.value[0], { value: date ? date.format(format) : null }])}
                                    placeholder="End"
                                    style={{ width: "30%", marginRight: 10 }}
                                    disabled={isReadOnly}
                                />
                            </>
                        ) : (
                            <>
                                <Input
                                    type={field.valueEditorType === 'number' ? 'number' : 'text'}
                                    value={rule?.value?.[0]?.value}
                                    onChange={(e) => handleFieldChange(rule, 'value', [{ value: e.target.value }, rule.value[1]])}
                                    placeholder="Start"
                                    style={{ width: "30%", marginRight: 10 }}
                                    disabled={isReadOnly}
                                />
                                <Input
                                    type={field.valueEditorType === 'number' ? 'number' : 'text'}
                                    value={rule?.value?.[1]?.value}
                                    onChange={(e) => handleFieldChange(rule, 'value', [rule.value[0], { value: e.target.value }])}
                                    placeholder="End"
                                    style={{ width: "30%", marginRight: 10 }}
                                    disabled={isReadOnly}
                                />
                            </>
                        )}
                    </div>
                );
            case OPERATOR_CONTAINS:
            case OPERATOR_STARTS_WITH:
            case OPERATOR_ENDS_WITH:
            case OPERATOR_LIKE:
            case OPERATOR_NOT_LIKE:
                return (
                    <Input
                        type="text"
                        value={rule?.value?.value}
                        onChange={(e) => handleFieldChange(rule, 'value', { value: e.target.value })}
                        placeholder="Value"
                        style={{ width: "30%", marginRight: 10 }}
                        disabled={isReadOnly}
                    />
                );
            default:
                switch (field.valueEditorType) {
                    case 'select':
                        return (
                            <Select
                                value={Array.isArray(rule.value) ? rule.value.filter(val => val !== "") : (rule.value !== "" ? rule.value : undefined)}
                                onChange={(value) => {
                                    const selectedVals = Array.isArray(value)
                                        ? value.map(val => hasPredefinedValues.find(preVal => preVal.value === val)).filter(val => val !== undefined)
                                        : hasPredefinedValues.find(val => val.value === value);
                                    handleFieldChange(rule, 'value', selectedVals);
                                }}
                                placeholder="Select value"
                                style={{ width: "30%", marginRight: 10 }}
                                disabled={isReadOnly}
                            >
                                {hasPredefinedValues.map((val) => (
                                    <Select.Option key={val.value} value={val.value}>
                                        {val.value_description}
                                    </Select.Option>
                                ))}
                            </Select>
                        );
                    case 'number':
                        return (
                            <Input
                                type="number"
                                value={rule?.value?.value}
                                onChange={(e) => handleFieldChange(rule, 'value', { value: e.target.value })}
                                placeholder="Value"
                                style={{ width: "30%", marginRight: 10 }}
                                disabled={isReadOnly}
                            />
                        );
                    case 'date':
                        const dateValue = moment(rule.value?.value, format).toDate();
                        return (
                            <DatePicker
                                selected={dateValue}
                                onChange={(date) => handleFieldChange(rule, 'value', moment(date).format(format))}
                                dateFormat={format}
                                showMonthYearPicker={format === 'YYYYMM'}
                                customInput={
                                    <Input
                                        type="text"
                                        value={moment(dateValue).format(format)}
                                        placeholder="Value"
                                        style={{ width: "30%", marginRight: 10 }}
                                        disabled={isReadOnly}
                                    />
                                }
                            />
                        );
                    default:
                        return (
                            <Input
                                value={rule?.value?.value}
                                onChange={(e) => handleFieldChange(rule, 'value', { value: e.target.value })}
                                placeholder="Value"
                                style={{ width: "30%", marginRight: 10 }}
                                disabled={isReadOnly}
                            />
                        );
                }
        }
    };

    const renderConditions = (condition, parentIndex = '') => {
        return condition.rules.map((rule, index) => {
            const currentIndex = `${parentIndex}-${index}`;
            const error = errors[currentIndex] || {};

            if (rule.rules) {
                return (
                    <div key={index} className="group">
                        <div className="group-header">
                            <div className="combinator-container">
                                <Select
                                    value={rule.combinator}
                                    onChange={(value) => handleCombinatorChange(rule, value)}
                                    style={{ width: 100, marginBottom: 10 }}
                                    disabled={isReadOnly}
                                >
                                    <Option value={COMBINATOR_AND}>AND</Option>
                                    <Option value={COMBINATOR_OR}>OR</Option>
                                    <Option value={COMBINATOR_ANY}>ANY</Option>
                                </Select>
                                {rule.combinator.toUpperCase() === 'ANY' && (
                                    <>
                                        <Input
                                            type="number"
                                            value={rule.number}
                                            onChange={(e) => handleNumberChange(rule, e.target.value)}
                                            style={{ width: 100, marginBottom: 10 }}
                                            min={1}
                                            disabled={isReadOnly}
                                        />
                                        <h4>of the</h4>
                                    </>
                                )}
                            </div>
                            {!isReadOnly && (
                                <Button
                                    type="text"
                                    icon={<DeleteOutlined />}
                                    onClick={() => removeRuleOrGroup(condition, index)}
                                    className="delete-button"
                                />
                            )}
                        </div>
                        {renderConditions(rule, currentIndex)}
                        {!isReadOnly && (
                            <div className="add-buttons">
                                <Button onClick={() => addRule(rule)} className="add-rule-button" icon={<PlusOutlined />}>
                                    {rule.rule_type === 'behavioural' ? 'Behaviour Rule' : 'Demographic Rule'}
                                </Button>
                                {rule.combinator.toUpperCase() !== 'ANY' && (
                                    <Button onClick={() => addGroup(rule)} className="add-group-button" icon={<PlusOutlined />}>Group</Button>
                                )}
                            </div>
                        )}
                    </div>
                );
            } else {
                const allFields = [...fields.demographic_features, ...fields.behaviour_features];
                const field = allFields.find(f => f.name === rule.field) ?? {};
                const hasPredefinedValues = field.values ?? [];
                const availableOperators = field.category === 'Behaviour' ? [{ value: 'IS', label: 'Is' }] : getOperatorsByValueEditorType(field.valueEditorType, field.name);

                return (
                    <div key={index} className="expression">
                        <Select
                            value={rule.field}
                            onChange={(value) => handleFieldChange(rule, 'field', value)}
                            placeholder="Field"
                            style={{ width: '40%', marginRight: 10 }}
                            showSearch
                            onSearch={(value) => handleSearch(value)}
                            filterOption={(input, option) => {
                                const optionText = option.label || option.value;
                                return optionText.toLowerCase().includes(input.toLowerCase());
                            }}
                            loading={searchLoading}
                            disabled={isReadOnly}
                        >
                            {condition.rule_type === 'demographic' ? (
                                <Select.OptGroup label="Demographic Features">
                                    {fields.demographic_features.map((field) => (
                                        <Select.Option key={field.name} value={field.name}>
                                            <Tooltip title={field.label}>{field.label}</Tooltip>
                                        </Select.Option>
                                    ))}
                                </Select.OptGroup>
                            ) : (
                                <Select.OptGroup label="Behaviour Features">
                                    {fields.behaviour_features.map((field) => (
                                        <Select.Option key={field.name} value={field.name}>
                                            <Tooltip title={field.label}>{field.label}</Tooltip>
                                        </Select.Option>
                                    ))}
                                </Select.OptGroup>
                            )}
                        </Select>
                        {rule.field === '' && Object.keys(errors).length !== 0 && <span className="error">Field cannot be empty</span>}

                        <Select
                            value={rule.operator}
                            onChange={(value) => handleFieldChange(rule, 'operator', value)}
                            style={{ width: '15%', marginRight: 10 }}
                            disabled={isReadOnly}
                        >
                            {availableOperators.map((operator) => (
                                <Select.Option key={operator.value} value={operator.value}>
                                    {operator.label}
                                </Select.Option>
                            ))}
                        </Select>
                        {rule.operator === '' && Object.keys(errors).length !== 0 && <span className="error">Operator cannot be empty</span>}

                        {renderValueEditor(rule, hasPredefinedValues, field)}
                        {rule.value === '' && Object.keys(errors).length !== 0 && <span className="error">Value cannot be empty</span>}

                        {rule.reasoning && (
                            <Tooltip title={rule.reasoning} className="delete-button">
                                <InfoCircleOutlined className="info-icon" />
                            </Tooltip>
                        )}
                        {!isReadOnly && (
                            <Button
                                type="text"
                                icon={<DeleteOutlined />}
                                onClick={() => removeRuleOrGroup(condition, index)}
                                className="delete-button"
                            />
                        )}
                    </div>
                );
            }
        });
    };

    return (
        <><div className="group">
            <div className="group-header">
                <div className="combinator-container">
                    <Select
                        value={conditions.combinator}
                        onChange={(value) => handleCombinatorChange(conditions, value)}
                        style={{ width: 100, marginBottom: 10 }}
                        disabled={isReadOnly}
                    >
                        <Option value={COMBINATOR_AND}>AND</Option>
                        <Option value={COMBINATOR_OR}>OR</Option>
                    </Select>
                    {(conditions.combinator === COMBINATOR_ANY) && (
                        <>
                            <Input
                                type="number"
                                value={conditions.number}
                                onChange={(e) => handleNumberChange(conditions, e.target.value)}
                                style={{ width: 100, marginBottom: 10 }}
                                min={1}
                                disabled={isReadOnly} />
                            <h4>of the</h4>
                        </>
                    )}
                </div>
            </div>
            {renderConditions(conditions)}
            {!isReadOnly && (
                <div className="add-buttons">
                    <Button onClick={() => addFeatureGroup(conditions, 'demographic')} icon={<PlusOutlined />}>
                        {LABEL_DEMOGRAPHIC_GROUP}
                    </Button>
                    <Button onClick={() => addFeatureGroup(conditions, 'behavioural')} icon={<PlusOutlined />}>
                        {LABEL_BEHAVIOUR_GROUP}
                    </Button>
                </div>
            )}
        </div>
            {showBrowseDrawer && (

                <Drawer
                    title="Features"
                    placement="right"
                    onClose={handleCancel}
                    visible={showBrowseDrawer}
                    width="60%"
                    footer={
                        <div className="drawer-footer">
                            <Button onClick={handleCancel}>
                                Cancel
                            </Button>
                            <Button onClick={handleBrowseConfirm} type="primary" loading={applyRules} disabled={applyRules}>
                                Apply
                            </Button>
                        </div>
                    }
                >
                    <BrowseBehaviourFeaturesComponent
                        fieldDirectory={fieldDirectory}
                        browsedSelectedItems={browsedSelectedItems}
                        setBrowsedSelectedItems={setBrowsedSelectedItems}
                    />
                </Drawer>
            )}
        </>
    );
};

export default QueryBuilder;