import { Progress, Table, Tooltip } from 'antd';
import moment from 'moment';
import React from 'react';
import * as TableStyle from './styles';
import { Link } from "react-router-dom";
import {
    CheckOutlined,
    ClockCircleOutlined,
    CloseOutlined,
    FileOutlined,
    RightOutlined,
    SyncOutlined,
} from "@ant-design/icons";

function ListingTable({
    clientId,
    tableDataSource,
    onPageChange,
    total,
    currentPage,
    loading,
}) {
    
    const PAGE_SIZE = 10;

    const statusConfig = {
        DRAFT: {
            icon: <FileOutlined />,
            color: "rgba(245, 121, 0, 0.8)",
            text: "Draft",
        },
        SCHEDULED: {
            icon: <ClockCircleOutlined />,
            color: "rgba(0, 172, 193, 0.8)",
            text: "Scheduled",
        },
        RUNNING: {
            icon: <SyncOutlined spin />,
            color: "rgba(0, 156, 255, 0.9)",
            text: "Running",
        },
        COMPLETED: {
            icon: <CheckOutlined />,
            color: "rgba(50, 205, 50, 0.8)",
            text: "Completed",
        },
        FAILED: {
            icon: <CloseOutlined />,

            color: "rgba(220, 20, 60, 0.8)",
            text: "Failed",
        },
    };

    const TABLE_COLUMNS =  [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text) => {
                return(
                    <div className={TableStyle.nameSection}>
                        <Tooltip  title={text}>{text}</Tooltip>
                    </div>
                )
            },
        },

        {
            title: 'Progress',
            dataIndex: 'progress',
            key: 'progress',
            render: (record, data) => {
                const { text, color } = statusConfig[data.status];
                return (
                    <Tooltip title={text}>
                        <Progress
                                className={TableStyle.progressSection}
                                percent={record}
                                strokeColor={color}
                                trailColor="rgba(255,255,255,0.1)"
                                status={data.status === "RUNNING" ? "active" : "normal"}
                                format={(record) => (
                                    <span className={TableStyle.metaInfo}>{`${record}%`}</span>
                                )}
                            />
                    </Tooltip>                    
                )
            }
        },
        {
            title: 'Source',
            dataIndex: 'data_source',
            key: 'data_source',
            render: (record) => {
                return (
                    <div className={TableStyle.dataSourceSection}>
                        <span className={TableStyle.metaInfo}>{record.type}</span>
                    </div>
                )
            }
        },

        {
            title: 'Created On',
            dataIndex: 'created_on',
            key: 'created_on',

        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (_, data) => {
                return (
                <Link
                    to={`/client/${clientId}/labeler/${data.id}`}
                    className={TableStyle.viewDetailsLink}
                >
                    View <RightOutlined />
                </Link>)
            }

        },

    ];


    return (
        <Table
            columns={TABLE_COLUMNS}
            dataSource={tableDataSource}
            className='dark-table'
            style={{ backgroundColor: '#333', color: '#fff', marginBottom: '20px' }}
            pagination={{
                pageSize: PAGE_SIZE,
                total: total,
                showSizeChanger: false,
                current: currentPage,
                position: ['bottomCenter'],
                onChange: (page) => onPageChange(page)
            }}
            loading={loading}
        />
    )
}

export default ListingTable