import {
  DeleteOutlined,
  InfoCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Form, Input, Select, Tooltip } from "antd";
import React, { useCallback, useState } from "react";
import * as Styles from "./styles";

const InputColumnsForm = ({ columns }) => {
  const [hoveredRow, setHoveredRow] = useState(null);

  const validateInputColumns = useCallback(async (_, inputColumns) => {
    if (!inputColumns?.length) {
      return Promise.reject(
        new Error("At least one input column is required.")
      );
    }
  }, []);

  const columnOptions = React.useMemo(
    () =>
      columns.map((col) => (
        <Select.Option key={col.name} value={col.name}>
          {col.name}
        </Select.Option>
      )),
    [columns]
  );

  const typeOptions = React.useMemo(
    () => [
      {
        value: "TEXT",
        label: "Text",
        description: "Plain text content for analysis",
      },
      {
        value: "IMAGE",
        label: "Image",
        description: "Image URLs for visual analysis",
      },
      {
        value: "URL",
        label: "Webpage",
        description: "Web pages for content extraction and analysis",
      },
      {
        value: "VIDEO",
        label: "Video",
        description: "Video URLs for visual analysis",
      },
    ],
    []
  );

  return (
    <Form.List
      name="inputColumns"
      rules={[{ validator: validateInputColumns }]}
    >
      {(fields, { add, remove }, { errors }) => (
        <div className={Styles.formContainer}>
          <div className={Styles.formHeader}>
            <div className={Styles.titleContainer}>
              <h3 className={Styles.formTitle}>Configure Input Columns</h3>
              <Tooltip
                overlayInnerStyle={{
                  width: "300px",
                  whiteSpace: "normal",
                  wordBreak: "break-word",
                }}
                title="Input columns define the data that will be analyzed. Select columns from your data source and specify their types (Text, Image, or Webpage) to determine how they should be processed."
                placement="right"
              >
                <InfoCircleOutlined className={Styles.infoIcon} />
              </Tooltip>
            </div>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => add()}
              className={Styles.addColumnButton}
            >
              Add Column
            </Button>
          </div>

          {fields.length === 0 ? (
            <div className={Styles.emptyState}>
              <p>
                No input columns added yet. Click "Add Column" to get started.
              </p>
            </div>
          ) : (
            <div className={Styles.columnsContainer}>
              <div className={Styles.columnHeaders}>
                <div className={Styles.headerColumn}>Column</div>
                <div className={Styles.headerColumn}>Description</div>
                <div className={Styles.headerColumn}>Type</div>
                <div className={Styles.headerAction}></div>
              </div>
              <div className={Styles.scrollableContainer}>
                {fields.map((field, index) => (
                  <div
                    className={Styles.inputColumnRow}
                    key={field.key}
                    onMouseEnter={() => setHoveredRow(index)}
                    onMouseLeave={() => setHoveredRow(null)}
                  >
                    <Form.Item
                      name={[field.name, "name"]}
                      rules={[
                        { required: true, message: "Column is required" },
                      ]}
                    >
                      <Select
                        className={Styles.columnSelect}
                        placeholder="Input Column"
                        showSearch
                        optionFilterProp="children"
                      >
                        {columnOptions}
                      </Select>
                    </Form.Item>

                    <Form.Item name={[field.name, "description"]}>
                      <Input
                        className={Styles.columnDescription}
                        placeholder="Add a description (Optional)"
                      />
                    </Form.Item>

                    <Form.Item
                      name={[field.name, "type"]}
                      rules={[{ required: true, message: "Type is required" }]}
                    >
                      <Select
                        className={Styles.columnTypeSelect}
                        placeholder="Type"
                      >
                        {typeOptions.map(({ value, label, description }) => (
                          <Select.Option key={value} value={value}>
                            <Tooltip title={description} placement="right">
                              {label}
                            </Tooltip>
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <Tooltip title="Delete column">
                      <DeleteOutlined
                        className={`${Styles.deleteIcon} ${
                          hoveredRow === index ? Styles.deleteIconVisible : ""
                        }`}
                        onClick={() => remove(field.name)}
                      />
                    </Tooltip>
                  </div>
                ))}
              </div>
            </div>
          )}

          {errors && (
            <Form.ErrorList errors={errors} className={Styles.errorList} />
          )}
        </div>
      )}
    </Form.List>
  );
};

export default React.memo(InputColumnsForm);
