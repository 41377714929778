import { notification } from 'antd';
import { useState, useEffect, useRef } from 'react';
import { getErrorMessageFromResponse } from 'src/lib/utils';
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom";
import moment from 'moment';
import AppUrls from 'src/constants/appUrls';
import mediaPlanService from '../../../services/mediaPlanService';
import audienceListService from '../../../services/audienceListService';


const useMediaPlanAudienceInformation = (initialData) => {
  const [loading, setLoading] = useState(true);
  const [configureLoading, setConfigureLoading] = useState(false);
  const [addAudienceModal, setAddAudienceModal] = useState(false);
  const [audienceOptions, setAudienceOptions] = useState([]);
  const [selectedAudience, setSelectedAudience] = useState(null);
  const history = useHistory();
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  })
  const { clientId, mediaPlanId } = useParams();
  const [metaData, setMetaData] = useState(null);
  const formRef = useRef(null);
  const filterRef = {
    currentPage: 1,
    searchName: "",
    filterType: "",
    sortBy: "created_on",
    isAscending: false,
    statusFilter: "",
  };
  const [allocatedBudget, setAllocatedBudget] = useState(0);
  const [audienceInfo, setAudienceInfo] = useState(null);

  const disabledDate = (current) => {
    if (!dateRange?.startDate && !dateRange?.endDate) {
      return false; // No constraints if both dates are null
    }

    if (dateRange?.startDate && !dateRange?.endDate) {
      return current && current.isBefore(dateRange?.startDate, "day"); // Disable dates before start date
    }

    if (!dateRange?.startDate && dateRange?.endDate) {
      return current && current.isAfter(dateRange?.endDate, "day"); // Disable dates after end date
    }

    return (
      current &&
      (current.isBefore(dateRange?.startDate, "day") ||
        current.isAfter(dateRange?.endDate, "day")) // Disable dates outside range
    );
  };

  const getMetaData = async () => {
    try {
      const res = await mediaPlanService.getMetaData(clientId);
      setMetaData(res?.data);
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        message: msg,
        duration: 5,
        placement: "bottomRight",
      });
    }
  };

  const getMediaPlanAudienceInfo = async () => {
    try {
      const res = await mediaPlanService.getMediaPlanAudienceInfo(mediaPlanId);
      setAllocatedBudget(res?.data?.mediaplan_budget);
      const audiences = [];
      res?.data?.audience_mapping?.forEach((audience) => {
        let obj = {...audience};
        if (audience?.start_date && audience?.end_date) {
          obj = { ...obj, dateRange: [moment(audience.start_date), moment(audience.end_date)] }
        }
        audiences.push(obj);
      })
      setAudienceInfo({
        ...res.data,
        audience_mapping: audiences,
      });
      setDateRange({
        startDate: moment(res.data?.mediaplan_start_date),
        endDate: moment(res.data?.mediaplan_end_date),
      })
    } catch (error) {
      const { msg} = getErrorMessageFromResponse(error);
      notification.error({ 
        message: msg,
        duration: 5,
        placement: "bottomRight",
      })
    }
  }

  const getAudience = async () => {
    try {
      setLoading(true);
      const res = await audienceListService.getAudienceList(
        clientId,
        filterRef.currentPage,
        10,
        filterRef.searchName,
        filterRef.filterType,
        filterRef.sortBy,
        filterRef.isAscending,
        filterRef.statusFilter
      );
      await getMetaData();
      await getMediaPlanAudienceInfo();
      setAudienceOptions(
        res.data.data?.map((audience) => ({
          label: audience.name,
          value: audience.audience_config?.id,
        }))
      );
      setLoading(false)
    } catch (error) {
      setLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        message: msg,
        duration: 5,
        placement: "bottomRight",
      });
    }
  };

  const saveAndRunReccomendAudience = async (values) => {
    try {
      setConfigureLoading(true);
      let audiences = [];
      values?.audience_mapping?.forEach((audience) => {
        let obj = {
          audience_id: audience.audience_id,
          budget: audience.budget,
          start_date: moment(audience.dateRange[0]).format("YYYY-MM-DD"),
          end_date: moment(audience.dateRange[1]).format("YYYY-MM-DD"),
          json_data: audience.json_data,
        }
        audiences.push(obj);
      })
      const payload = {
        mediaplan_id: mediaPlanId,
        audience_mapping: audiences,
      }
      const res = await mediaPlanService.updateMediaPlanAudience(payload);
      const reccomPayload = {
        mediaplan_id: mediaPlanId
      }
      await mediaPlanService.triggerRecommendation(reccomPayload);
      history.push(AppUrls.MEDIA_PLAN_LISTING(clientId));
      setConfigureLoading(false);
    } catch(error) {
      setConfigureLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        message: msg,
        duration: 5,
        placement: "bottomRight",
      });
    }
  }



  const deleteMediaPlanAudience = async (mediaPlanAudienceId) => {
    try {
      // const res = await mediaPlanService.deleteMediaPlanAudience(mediaPlanAudienceId);
      // await getMediaPlanAudienceInfo();
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        message: msg,
        duration: 5,
        placement: "bottomRight",
      });
    }
  }

  useEffect(() => {
    getAudience();
  }, []);

  return {
    loading,
    formRef,
    audienceOptions,
    addAudienceModal,
    setAddAudienceModal,
    selectedAudience,
    setSelectedAudience,
    metaData,
    deleteMediaPlanAudience,
    audienceInfo,
    disabledDate,
    saveAndRunReccomendAudience,
    configureLoading,
    mediaPlanId,
    allocatedBudget,
    setAllocatedBudget,
    clientId,
    history
  };
};

export default useMediaPlanAudienceInformation;