import { useState, useEffect } from 'react';

const useMediaPlanFlighting = ({ data }) => {
  let initialObjective = '';
  let initialTabData = null;
  if (data?.flighting.length > 0) {
    initialObjective = data.flighting[0].business_goal;
    initialTabData = data.flighting[0].business_goal_value;
  }

  const [tab, setTab] = useState(initialObjective);
  const [tabData, setTabData] = useState(initialTabData);

  const onTabChange = (objective, data) => { 
    setTab(objective);
    setTabData(data);
  }
  

  return { 
    tab,
    setTab,
    tabData,
    setTabData,
    onTabChange
  };
};

export default useMediaPlanFlighting;