import { notification } from "antd";
import { useEffect, useState } from "react";
import { formatNumberWithCeil, getErrorMessageFromResponse } from "src/lib/utils";
import audienceListService from "../../../services/audienceListService";

const colors = [
  "#5470C6",
  "#91CC75",
  "#FAC858",
  "#EE6666",
  "#73C0DE",
  "#3BA272",
  "#FC8452",
  "#9A60B4",
  "#EA7CCC",
  "#3EAF7C",
  "#E57373",
  "#81C784",
  "#64B5F6",
  "#FFB74D",
  "#4DB6AC",
  "#BA68C8",
  "#7986CB",
  "#F06292",
  "#FFD54F",
  "#4FC3F7",
];

const useDemographicHook = ({ audienceFeatures, audienceId }) => {
  const [loading, setLoading] = useState(true);
  const [pageLoading, setPageLoading] = useState(true);
  const [audienceChart, setAudienceChart] = useState(null);
  const [baselineChart, setBaselineChart] = useState(null);
  const [selectedFeature, setSelectedFeature] = useState(audienceFeatures?.demographic_features?.[0]?.feature_name);
  const [genderData, setGenderData] = useState(null);
  const [ageData, setAgeData] = useState(null);
  const [householdData, setHouseHoldData] = useState(null);
  const [educationData, setEducationData] = useState(null);
  const [selectedFeatureReadableName, setSelectedFeatureReadableName] = useState(audienceFeatures?.demographic_features?.[0]?.feature_readable_name);

  const defaultLoad = async () => {
    try {
      setPageLoading(true);
      const gender = await audienceListService.getAudienceFeatureProfiles(audienceId, "", "GENDER");
      const age = await audienceListService.getAudienceFeatureProfiles(audienceId, "", "EXACT_AGE", 1, "", undefined, 100);
      const household = await audienceListService.getAudienceFeatureProfiles(audienceId, "", "ESTIMATED_HOUSEHOLD_INCOME", 1, "", undefined, 100);
      const education = await audienceListService.getAudienceFeatureProfiles(audienceId, "", "OCCUPATION_GROUP", 1, "", undefined, 100);
      if (gender?.data?.data?.length > 0) {
        const male = gender?.data?.data?.find((item) => item?.feature_value === "M");
        const female = gender?.data?.data?.find((item) => item?.feature_value === "F");
        setGenderData({ male: formatNumberWithCeil(male?.audience_feature_percentage), female: formatNumberWithCeil(female?.audience_feature_percentage) });
      }
      const ageOption = {
        tooltip: {},
        xAxis: { type: "category", data: age?.data?.data?.map((item) => item?.feature_readable_value) },
        yAxis: { type: "value" },
        series: [
          {
            type: "bar",
            data: age?.data?.data?.map((item) => formatNumberWithCeil(item?.audience_feature_percentage)),
          },
        ],
      };
      setAgeData(ageOption);
      const householdOption = {
        tooltip: {},
        series: [
          {
            type: "pie",
            radius: "50%",
            data: household?.data?.data?.map((item) => {
              return {
                value: formatNumberWithCeil(item?.audience_feature_percentage),
                name: item?.feature_readable_value,
              };
            }),
            label: {
              color: "white",
              formatter: "{b}: {d}%",
            },
          },
        ],
      };
      setHouseHoldData(householdOption);

      const occupation = {
        tooltip: {},
        xAxis: {
          axisLabel: {
            interval: 0,
            rotate: 30,
            hideOverlap: true, // hide this to show the overlap value
            formatter: (value) => {
              const len = value.length;
              const ellipsis = len > 17 ? "..." : "";
              if (len > 17) {
                return value.slice(0, 4) + ellipsis + value.slice(-14);
              }
              return value;
            },
            textStyle: {
              color: "#ffffff",
            },
          },
          type: "category",
          data: education?.data?.data?.map((item) => item?.feature_readable_value),
        },
        yAxis: { type: "value" },
        series: [
          {
            type: "bar",
            data: education?.data?.data?.map((item) => formatNumberWithCeil(item?.audience_feature_percentage)),
          },
        ],
      };

      setEducationData(occupation);

      if (audienceFeatures?.demographic_features.length > 0) {
        await getChartData(audienceFeatures.demographic_features[0]?.feature_name);
      }
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: "bottomRight",
      });
    }
  };

  const getChartData = async (featureName) => {
    try {
      setLoading(true);
      const audienceChartData = await audienceListService.getAudienceFeatureProfiles(audienceId, "", featureName);
      const formatedAudiencePieChart = formatAudiencePieChart(audienceChartData?.data?.data);
      const formatedBaselinePieChart = formatBaselinePieChart(audienceChartData?.data?.data);
      setAudienceChart(formatedAudiencePieChart);
      setBaselineChart(formatedBaselinePieChart);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: "bottomRight",
      });
    }
  };

  const onFeatureChange = (featureName) => {
    const obj = audienceFeatures.demographic_features?.find((item) => item?.feature_name === featureName);
    if (obj) {
      setSelectedFeatureReadableName(obj?.feature_readable_name);
    }
    setSelectedFeature(featureName);
    getChartData(featureName);
  };

  const addUnknownValuesforPieChart = (data, key, name) => {
    let total = 0;
    let formattedData = []
    data?.map((item) => {
      total += item?.[key];
    });
    data?.map((item) => {
      if (item?.[key] != 0) {
        let value = parseFloat(formatNumberWithCeil(item?.[key]).replace(/,/g, ''));
        formattedData.push({
          value: value,
          name: item?.[name],
        });
      }
    });
    if (total <= 100) {
      formattedData.push({
        value: formatNumberWithCeil(100 - total),
        name: "Unknown",
        itemStyle: { color: "#8D8D8D" },
      });
    }
    return formattedData;
  };

  const formatAudiencePieChart = (data) => {
    let obj = {};
    const formattedData = addUnknownValuesforPieChart(
      data,
      "audience_feature_percentage",
      "feature_readable_value"
    );

    if (data) {
      obj = {
        tooltip: {},
        series: [
          {
            type: "pie",
            color: colors,
            radius: "50%",
            data: formattedData,
            label: {
              color: "white",
              formatter: "{b}: {d}%",
            },
          },
        ],
      };
    }
    return obj;
  };

  const formatBaselinePieChart = (data) => {
    let obj = {};
    const formattedData = addUnknownValuesforPieChart(
      data,
      "base_feature_percentage",
      "feature_readable_value"
    );
    if (data) {
      obj = {
        tooltip: {},
        series: [
          {
            type: "pie",
            radius: "50%",
            data: formattedData,
            label: {
              color: "white",
              formatter: "{b}: {d}%",
            },
          },
        ],
      };
    }
    return obj;
  };

  useEffect(() => {
    defaultLoad();
  }, []);

  return {
    pageLoading,
    loading,
    audienceChart,
    baselineChart,
    selectedFeature,
    onFeatureChange,
    selectedFeatureReadableName,
    genderData,
    ageData,
    householdData,
    educationData,
  };
};

export default useDemographicHook;
