import { useEffect, useRef, useState } from "react";
import { getErrorMessageFromResponse } from "src/lib/utils";
import { AiAppApiServices } from "../services/aiAppApiServices";
import { message, Modal, notification } from "antd";
import ApiUrls from "src/constants/apiUrls";
import Cookie from "src/lib/cookie";
import Style from "../components/aiApp.style";
import appUrls from "src/constants/appUrls";
import axios from "axios";
import { exception } from "react-ga";
import { setUser } from "@sentry/react";
import { set } from "dot-object";
import { be } from "date-fns/locale";

export default function useAiAppHook({ history, data, sessionId, appContext, addNewSession, mode, clientId, scrollRef }) {
  const mAiApp = data.id;
  const [session, setSessions] = useState([]);
  const [initialLoading, setInitialLoading] = useState(false);
  const [userMessage, setUserMessage] = useState("");
  const [generatingResponse, setGeneratingResponse] = useState(false);
  const [feedbackFormModal, setFeedBackFormModal] = useState(false);
  const [feedbackData, setFeedBackData] = useState(null);
  const [feedbackLoading, setFeedbackLoading] = useState(false);
  const [streamingData, setStreamingData] = useState([]);
  const [formUploadModal, setFormUploadModal] = useState(false);
  const [fileList, setFileList] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const [fileDelete, setFileDelete] = useState(false);
  const [llmOutput, setLlmOutput] = useState("");
  const [streamingChatID, setStreamingChatID] = useState(null);
  const [terminatingStream, setTerminatingStream] = useState(false);
  const cancellationRef = useRef({ terminated: false });
  const [chatWindowLoading, setChatWindowLoading] = useState(true);
  const [streamingUserData, setStreamingUserData] = useState(null);
  const cancellationStreamingRef = useRef({ terminated: false });
  const createNewSessionRef = useRef({ created: false });
  const switchedToNewChatRef = useRef({ active: false });
  const [cancelToken, setCancelToken] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [nextPageLoading, setNextPageLoading] = useState(false);

  const getNewChat = async () => {
    setChatWindowLoading(true);
    switchedToNewChatRef.current.active = true;
    setSessions([]);
    setFileList(null);
    setFilePreview(null);
    setChatWindowLoading(false);
  };

  const cancelApiCall = () => {
    if (cancelToken) {
      cancelToken.cancel("Operation canceled by the user.");
      setCancelToken(null);
    }
  };

  const getSession = async (sessionId, pageNum = 1) => {
    try {
      const source = axios.CancelToken.source();
      setCancelToken(source);
      pageNum === 1 && setFileList(null);
      pageNum === 1 && setFilePreview(null);
      pageNum === 1 && setChatWindowLoading(true);
      const res = await AiAppApiServices.getChatList(mAiApp, sessionId, source.token, pageNum);
      if (res?.data?.total_pages === res?.data?.current_page) {
        setHasMore(false);
      } 
      else if (res?.data?.total_pages === 0) {
        setHasMore(false);
      }
       else {
        setHasMore(true);
      }
      if (data?.allow_file_upload && pageNum === 1) {
        await getFileUploadList(sessionId, source);
      }
      const sessions_data = res?.data?.data;
      const sessions_item = sessions_data?.length && sessions_data[0];
      let newSessions = [];
      if (pageNum !== 1) {
        newSessions = [...session];
      }
      if (sessions_item && sessions_item.output_status === "IN_PROGRESS") {
        setStreamingUserData(sessions_item);
        setStreamingChatID(sessions_item.id);
        setSessions([...sessions_data.slice(1, sessions_data.length).reverse()]);
      } else {
        setSessions([...sessions_data.reverse(), ...newSessions]);
      }
      if (pageNum === 1) {
        window.setTimeout(() => {
          window.scrollTo("masterChat");
        }, 50);
      } else {
        window.setTimeout(() => {
          scrollRef.current[newSessions[0].id].scrollIntoView({
            block: 'start',
          });
        },)
      }
      if (sessions_data.length && sessions_item.output_status === "IN_PROGRESS") {
        setUserMessage(sessions_item.user_input);
        getResponse({
          message: sessions_item.user_input,
          chatID: sessions_item.id,
          sessions: [...sessions_data.slice(1, sessions_data.length).reverse()],
          newSessionId: sessionId,
        });
      }
      setChatWindowLoading(false);
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      if (!(msg === "Operation canceled by the user.")) {
        notification.error({
          message: msg || "Unable to fetch the conversations",
          duration: 6,
          placement: "bottomRight",
        });
        setChatWindowLoading(false);
      }
    }
  };

  const loadNextPage = async () => {
    if (hasMore && !nextPageLoading) {
      setNextPageLoading(true);
      setPage((page) => page + 1);
      await getSession(sessionId, page + 1);
      setNextPageLoading(false);
    }
  };

  const getResponse = async ({ message, chatID = null, sessions = null, newSessionId = null }) => {
    try {
      cancellationStreamingRef.current.terminated = false;
      setGeneratingResponse(true);
      if (!chatID) {
        setStreamingUserData(appContext.metaData);
      }
      setLlmOutput("");
      setUserMessage(message);
      cancellationRef.current.terminated = false;
      window.setTimeout(() => {
        window.scrollTo("masterChat");
      }, 50);
      const payload = {
        user_input: message,
      };
      const testMode = false;
      let res;
      if (chatID) {
        res = await AiAppApiServices.streamInProgressResponse(chatID, mAiApp, newSessionId);
      } else {
        res = await AiAppApiServices.postResponse(payload, mAiApp, testMode, sessionId || newSessionId);
      }
      setFilePreview(null);
      if (!res.ok || !res.body) {
        throw res.statusText;
      }
      const reader = res.body.getReader();
      const decoder = new TextDecoder();
      let accumulatedData = "";
      let lastExtractedMatch = null;
      let stringBuffer = "";
      function receiveData(stream) {
        accumulatedData += stream;

        const regex = /<aai>(.*?)<\/aai>/gs;
        let match;
        let lastMatchIndex = 0;

        // Array to keep track of processed segments
        let processedSegments = [];

        while ((match = regex.exec(accumulatedData)) !== null) {
          const extractedData = match[1];
          lastExtractedMatch = extractedData; // Update the last extracted match

          // Add the processed segment to the array
          processedSegments.push(match[0]);

          // Update the last match index to the end of the current match
          lastMatchIndex = match.index + match[0].length;
        }

        // If any segments were processed, remove them from accumulatedData
        if (processedSegments.length > 0) {
          // Join all processed segments (this assumes they are in order)
          const processedData = processedSegments.join("");
          // Remove the processed data from accumulatedData
          // This approach removes all processed segments up to the last match's end index
          // It's efficient and works well if segments are neatly ordered and non-overlapping
          accumulatedData = accumulatedData.substring(lastMatchIndex);
        }

        // Return the latest extracted match
        return lastExtractedMatch;
      }
      while (true) {
        if (cancellationStreamingRef.current.terminated) {
          break;
        }
        if (cancellationRef.current.terminated) {
          await reader.cancel();
          setStreamingData([]);
          setGeneratingResponse(false);
          setStreamingUserData(null);
          setUserMessage("");
          setStreamingChatID(null);
          cancellationRef.current.terminated = false;
          break;
        }
        const { value, done } = await reader.read();
        if (done) {
          setStreamingData([]);
          setGeneratingResponse(false);
          setUserMessage("");
          setStreamingUserData(null);
          setStreamingChatID(null);
          cancellationRef.current.terminated = false;
          break;
        }
        let decodedChunk = decoder.decode(value, { stream: true });
        const latestBuffer = receiveData(decodedChunk);
        stringBuffer = latestBuffer;
        if (stringBuffer) {
          let parsedJson = JSON.parse(stringBuffer);
          if (!streamingChatID) {
            setStreamingChatID(parsedJson.id);
          }
          if (parsedJson.ai_output) {
            const newArray = session.length === 0 ? sessions || [] : session;
            let obj = {
              ...parsedJson,
              appAgentCode: mAiApp,
              generated_steps: parsedJson.intermediate_steps,
            };
            if (!cancellationStreamingRef.current.terminated) {
              newArray.push(obj);
              if (!chatID) {
                if (session.length > 0 && session.length % 11 === 0) {
                  newArray.shift();
                  setSessions([...newArray]);
                  setHasMore(true);
                } else {
                  setSessions([...newArray]);
                }
              } else {
                setSessions([...newArray]);
              }
            }
            setGeneratingResponse(false);
            setUserMessage("");
            setStreamingUserData(null);
            setStreamingChatID(null);
            window.setTimeout(() => {
              window.scrollTo("masterChat");
            }, 50);
          } else {
            setStreamingData(parsedJson?.intermediate_steps);
            if (parsedJson?.intermediate_steps?.length > 0) {
              if (parsedJson?.intermediate_steps[parsedJson?.intermediate_steps.length - 1]?.type === "LLM_OUTPUT") {
                setLlmOutput(parsedJson?.intermediate_steps[parsedJson?.intermediate_steps.length - 1]?.value);
              }
            }
          }
        }
      }
      if (!cancellationStreamingRef.current.terminated) {
        setStreamingData([]);
        setGeneratingResponse(false);
        setUserMessage("");
        setStreamingUserData(null);
        setStreamingChatID(null);
        cancellationRef.current.terminated = false;
      }
    } catch (error) {
      setStreamingData([]);
      setGeneratingResponse(false);
      setStreamingChatID(null);
      if (!cancellationRef.current.terminated) {
        const { msg } = getErrorMessageFromResponse(error);
        notification.error({
          message: msg || "The attempt to retrieve the response failed. Please try again later.",
          duration: 6,
          placement: "bottomRight",
        });
      }
      cancellationRef.current.terminated = false;
    }
  };

  const terminateChatProcessing = async ({ chatID }) => {
    try {
      setTerminatingStream(true);
      await AiAppApiServices.terminateInProgressChat(chatID, mAiApp, sessionId);
      cancellationRef.current.terminated = true;
      setTerminatingStream(false);
      setStreamingData([]);
      setGeneratingResponse(false);
      setStreamingChatID(null);
      notification.success({
        message: "Stopped query successfully.",
        duration: 6,
      });
    } catch (error) {
      setTerminatingStream(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        message: msg || "Could not terminate message processing. Please try again.",
        duration: 6,
        placement: "bottomRight",
      });
    }
  };

  const openFeedBackFormModal = (data, reaction, idx) => {
    const obj = {
      data: data,
      reaction: reaction,
      idx: idx,
    };
    setFeedBackData(obj);
    setFeedBackFormModal(true);
  };

  const closeFeedBackmodal = () => {
    setFeedBackFormModal(false);
    setFeedBackData(null);
  };

  const feedbackReaction = async (data, reaction, idx, userFeedback) => {
    try {
      setFeedbackLoading(true);
      if (!userFeedback?.user_reaction || userFeedback?.user_reaction !== reaction) {
        const payload = {
          chat_id: data?.id,
          user_reaction: reaction,
        };
        const res = await AiAppApiServices.postFeedback(mAiApp, payload, sessionId);
        let newSessionObj = {
          ...data,
          feedbacks: res.data,
        };
        let newSession = session;
        newSession[idx] = newSessionObj;
        setSessions([...newSession]);
        openFeedBackFormModal(newSessionObj, reaction, idx);
      } else if (userFeedback?.user_reaction === reaction) {
        openFeedBackFormModal(data, reaction, idx);
      }
      setFeedbackLoading(false);
    } catch (error) {
      setFeedbackLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        message: msg,
        duration: 6,
        placement: "bottomRight",
      });
    }
  };

  const submitFeedback = async (data, payload, idx) => {
    try {
      const res = await AiAppApiServices.postFeedback(mAiApp, payload, sessionId);
      let newSessionObj = {
        ...data.data,
        feedbacks: res.data,
      };
      let newSession = session;
      newSession[idx] = newSessionObj;
      setSessions([...newSession]);
      closeFeedBackmodal();
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        message: msg,
        duration: 6,
        placement: "bottomRight",
      });
    }
  };

  const deleteChatHIstory = async (id) => {
    Modal.warn({
      title: "Are you sure you want to delete chat history.",
      okText: "Okay",
      cancelText: "Cancel",
      okCancel: true,
      onOk: async () => {
        try {
          await AiAppApiServices.deleteChatHistory(mAiApp, id);
          setPage(1);
          await getSession(sessionId, 1);
        } catch (error) {
          const { msg } = getErrorMessageFromResponse(error);
          notification.error({
            message: msg || "Failed to delete the conversation",
            duration: 6,
            placement: "bottomRight",
          });
        }
      },
      className: Style.confirmModal,
    });
  };

  const uploadFile = async (data, tempSesionId) => {
    try {
      const formData = new FormData();
      formData.append("file", data.file);
      if (data.name) {
        formData.append("name", data.name);
      }
      if (data.description) {
        formData.append("description", data.description);
      }
      await AiAppApiServices.fileUpload(mAiApp, formData, sessionId || tempSesionId);
      notification.success({
        message: "File uploaded succesfully",
        duration: 6,
        placement: "bottomRight",
      });
      setFilePreview(data);
      await getFileUploadList(sessionId || tempSesionId);
      setFormUploadModal(false);
      window.setTimeout(() => {
        window.scrollTo("masterChat");
      }, 50);
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        message: msg || "Failed to upload the file",
        duration: 6,
        placement: "bottomRight",
      });
    }
  };

  const getFileUploadList = async (sessionId) => {
    try {
      const res = await AiAppApiServices.getFileList(mAiApp, sessionId);
      setFileList(res?.data?.data);
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      if (!(msg === "Operation canceled by the user.")) {
        notification.error({
          message: msg,
          duration: 6,
          placement: "bottomRight",
        });
      }
    }
  };

  const deleteFile = async (fileId) => {
    try {
      setFileDelete(true);
      await AiAppApiServices.archiveFile(mAiApp, fileId, sessionId);
      await getFileUploadList(sessionId);
      setFileDelete(false);
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        message: msg,
        duration: 6,
        placement: "bottomRight",
      });
      setFileDelete(false);
    }
  };

  const createSession = async (message) => {
    try {
      setHasMore(false);
      const payload = {
        session_type: "SINGLE_USER",
      };
      const res = await AiAppApiServices.createSession(mAiApp, payload);
      createNewSessionRef.current.created = true;
      history.push(appUrls.APP_PAGE(clientId, mAiApp, res.data.id, mode));
      addNewSession(res.data);
      getResponse({ message: message.trim(), newSessionId: res.data.id });
    } catch (error) {}
  };

  const createSesionWithFile = async (val) => {
    try {
      const payload = {
        session_type: "SINGLE_USER",
      };
      const res = await AiAppApiServices.createSession(mAiApp, payload);
      createNewSessionRef.current.created = true;
      history.push(appUrls.APP_PAGE(clientId, mAiApp, res.data.id, mode));
      addNewSession(res.data);
      await uploadFile(val, res.data.id);
    } catch (error) {}
  };

  useEffect(() => {
    setPage(1);
    if (sessionId) {
      if (!createNewSessionRef.current.created) {
        getSession(sessionId, 1);
      }
    } else {
      window.setTimeout(() => {
        getNewChat();
      }, 500);
    }
  }, [window.location.search]);

  return {
    session,
    initialLoading,
    getResponse,
    generatingResponse,
    userMessage,
    setUserMessage,
    feedbackFormModal,
    feedbackData,
    openFeedBackFormModal,
    closeFeedBackmodal,
    submitFeedback,
    feedbackReaction,
    feedbackLoading,
    deleteChatHIstory,
    streamingData,
    formUploadModal,
    setFormUploadModal,
    uploadFile,
    fileList,
    deleteFile,
    filePreview,
    fileDelete,
    llmOutput,
    terminateChatProcessing,
    streamingChatID,
    terminatingStream,
    chatWindowLoading,
    streamingUserData,
    cancellationStreamingRef,
    setStreamingChatID,
    setGeneratingResponse,
    createSession,
    createNewSessionRef,
    cancelApiCall,
    createSesionWithFile,
    loadNextPage,
    nextPageLoading,
  };
}
