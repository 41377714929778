import React from "react";
import "./MediaplanScenarioPlan.less";
import NcBreadCrumb from "src/modules/newsAndCulture/components/NcBreadCrumb";
import { Col, Collapse, Form, Row, Slider, Table } from "antd";
import { PrimaryButton, SecondaryButton } from "src/components/basic";
import LoadingSpinner from "src/components/loadingSpinner";
import { numberWithCommas } from "src/lib/utils";
import { LineChartOutlined } from "@ant-design/icons";
import appUrls from "src/constants/appUrls";
import moment from "moment";
import useMediaPlanScenarioPlan from "./useMediaPlanScenarioPlan";

export const MediaplanScenarioPlan = () => {
  const {
    loading,
    scenario,
    formRef,
    getColumns,
    history,
    clientId,
    responseId,
  } = useMediaPlanScenarioPlan();
  const formatter = (value) => `$${numberWithCommas(value)}`;
  if (loading) {
    return <LoadingSpinner centered />;
  }
  return (
    <div>
      <NcBreadCrumb />
      <h2>Scenario Planning</h2>
      <Row justify="end" gutter={[20, 0]} style={{ marginBottom: "1rem" }}>
        <Col>
          <SecondaryButton
            comfy
            className="small"
            onClick={() =>
              history.push(
                appUrls.MEDIA_PLAN_AUDIENCE_INFO(
                  clientId,
                  scenario?.mediaplan_id
                )
              )
            }
          >
            Back
          </SecondaryButton>
        </Col>
        <Col>
          <PrimaryButton
            className="small"
            comfy
            onClick={() =>
              history.push(
                appUrls.MEDIA_PLAN_SCENARIO_OUTPUT_SCREEN(clientId, responseId)
              )
            }
          >
            Dashboard &nbsp;
            <LineChartOutlined />
          </PrimaryButton>
        </Col>
      </Row>
      <Row className="header-container planning">
        <Col span={6}>
          <b>Media Plan Name:</b> {scenario?.mediaplan_name}
        </Col>
        <Col span={6}>
          <b>Budget:</b> ${numberWithCommas(scenario?.mediaplan_budget)}
        </Col>
        <Col span={6}>
          <b>Start Date:</b>{" "}
          {moment(scenario?.mediaplan_start_date).format("MM/DD/YYYY")}
        </Col>
        <Col span={6}>
          <b>End Date:</b>{" "}
          {moment(scenario?.mediaplan_end_date).format("MM/DD/YYYY")}
        </Col>
      </Row>
      <h4>Audiences:</h4>
      <div style={{ marginLeft: "1rem" }}>
        <Form initialValues={scenario} requiredMark={false} ref={formRef}>
          <Form.List name="scenario">
            {(fields, { add, remove }) => {
              return (
                <Form.Item shouldUpdate noStyle>
                  {({ getFieldsValue, setFieldsValue }) => {
                    return (
                      <>
                        {fields.map(
                          ({ key, name, fieldKey, ...restField }, idx) => {
                            const audiences = getFieldsValue("scenario");
                            const audienceName =
                              audiences?.scenario?.[idx]?.audience_name;
                            const max = audiences?.scenario?.[idx]?.budget;
                            const min = 0;
                            let formattedColumns = [];
                            if (
                              audiences?.scenario?.[idx]?.channels?.length > 0
                            ) {
                              const columns = Object.keys(
                                audiences?.scenario?.[idx]?.channels[0]
                              );
                              let filteredColumns = columns.filter(
                                (column) => column !== "budget"
                              );
                              filteredColumns = filteredColumns.filter(
                                (column) => column !== "channel_name"
                              );
                              formattedColumns = getColumns(filteredColumns);
                            }
                            return (
                              <Collapse
                                className={"regularCollapse"}
                                style={{ flexGrow: "1" }}
                                defaultActiveKey={["0"]}
                              >
                                <Collapse.Panel
                                  forceRender
                                  header={<b>{audienceName}</b>}
                                  key={idx}
                                >
                                  <h4>
                                    Budget: <b>${numberWithCommas(max)}</b>
                                  </h4>
                                  <Form.List name={[name, "channels"]}>
                                    {(channels) => (
                                      <>
                                        {channels.map((channel, index) => {
                                          const channelTableData =
                                            audiences?.scenario?.[idx]
                                              ?.channels?.[index];
                                          return (
                                            <>
                                              <Row
                                                key={channel.key}
                                                gutter={[20, 20]}
                                                className="channel-row"
                                              >
                                                <Col xl={6} sm={24} md={24}>
                                                  <div>
                                                    <span>
                                                      <b>
                                                        {
                                                          channelTableData?.channel_name
                                                        }
                                                      </b>
                                                    </span>
                                                  </div>
                                                  <Form.Item
                                                    name={[
                                                      channel.name,
                                                      "budget",
                                                    ]}
                                                    rules={[
                                                      {
                                                        required: true,
                                                        message:
                                                          "Please set a budget!",
                                                      },
                                                    ]}
                                                  >
                                                    <Slider
                                                      min={min}
                                                      max={
                                                        scenario?.mediaplan_budget
                                                      }
                                                      tooltip={{
                                                        formatter,
                                                      }}
                                                      marks={{
                                                        [min]: `$${numberWithCommas(
                                                          min
                                                        )}`,
                                                        [scenario?.mediaplan_budget]: `$${numberWithCommas(
                                                          scenario?.mediaplan_budget
                                                        )}`,
                                                      }}
                                                    />
                                                  </Form.Item>
                                                </Col>
                                                <Col xl={18} sm={24} md={24}>
                                                  <Table
                                                    className="listing-table"
                                                    columns={
                                                      formattedColumns || []
                                                    }
                                                    dataSource={[
                                                      channelTableData,
                                                    ]}
                                                    pagination={false}
                                                    rowKey="id"
                                                    scroll={{
                                                      x: "max-content",
                                                    }}
                                                  />
                                                </Col>
                                              </Row>
                                            </>
                                          );
                                        })}
                                      </>
                                    )}
                                  </Form.List>
                                </Collapse.Panel>
                              </Collapse>
                            );
                          }
                        )}
                      </>
                    );
                  }}
                </Form.Item>
              );
            }}
          </Form.List>
        </Form>
      </div>
    </div>
  );
};