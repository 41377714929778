import { PlusOutlined } from "@ant-design/icons";
import { Button, Empty, Progress, Spin, Table, message } from "antd";
import debounce from "lodash/debounce";
import React, { useCallback, useEffect, useReducer, useRef } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import ApiUrls from "src/constants/apiUrls";
import {
  APPEND_TASKS,
  SET_CURRENT_PAGE,
  SET_LOADING,
  SET_LOADING_MORE,
  SET_SEARCH_TERM,
  SET_SORT_BY,
  SET_TASKS,
  SET_TOTAL_PAGES,
  SET_TOTAL,
  UPDATE_TASKS,
  SET_TABLE_DATA_SOURCE
} from "../components/Dashboard/actionTypes";
import { initialState, reducer } from "../components/Dashboard/reducer";
import SearchFilter from "../components/SearchAndFilter";
import TaskListItem from "../components/TaskListItem";
import WelcomeState from "../components/WelcomeState";
import LabelerApiService from "../services/api";
import { createWebSocketConnection } from "../utils";
import * as Styles from "./Dashboard.styles";
import moment from "moment";
import ListingTable from "../components/ListingTable/ListingTable";

const PAGE_SIZE = 10;
const MIN_SEARCH_LENGTH = 2;
const SEARCH_DEBOUNCE_MS = 500;

const Dashboard = () => {
  const history = useHistory();
  const match = useRouteMatch();

  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    tasks,
    loading,
    loadingMore,
    searchTerm,
    sortBy,
    currentPage,
    totalPages,
    tableDataSource,
    total
  } = state;

  const observer = useRef(null);
  const closeSocketRef = useRef(null);
  const isInitialMount = useRef(true);
  const hasExistingTasks = useRef(false);

  // Create refs for state variables
  const currentPageRef = useRef(currentPage);
  const totalPagesRef = useRef(totalPages);
  const loadingRef = useRef(loading);
  const loadingMoreRef = useRef(loadingMore);

  // Update refs when state changes
  useEffect(() => {
    currentPageRef.current = currentPage;
  }, [currentPage]);

  useEffect(() => {
    totalPagesRef.current = totalPages;
  }, [totalPages]);

  useEffect(() => {
    loadingRef.current = loading;
  }, [loading]);

  useEffect(() => {
    loadingMoreRef.current = loadingMore;
  }, [loadingMore]);

  const showErrorMessage = useCallback((errorText) => {
    message.error({
      content: errorText,
      className: Styles.customErrorMessage,
    });
  }, []);

  // Format Data for Table 
  const getTasksDatasource = (data) => {
      return data.map((item, index) => ({
        key: (index + 1).toString(),
        id: item.id,
        name: item.name,
        progress: item.progress,
        status: item.status,
        data_source: item.data_source,
        created_on: moment(item.created_on).format('MMMM D, YYYY'),
      }));   
  }
  

  useEffect(()=> {
    const action = getTasksDatasource(tasks);
    dispatch({type: SET_TABLE_DATA_SOURCE, payload: action})
  },[tasks]);

  const fetchLabelingTasks = useCallback(
    async (page = currentPageRef.current, isLoadMore = false) => {
      try {
        if (isLoadMore) {
          dispatch({ type: SET_LOADING_MORE, payload: true });
        } else {
          dispatch({ type: SET_LOADING, payload: true });
        }

        const response = await LabelerApiService.getLabelingTasks(
          match.params.clientId,
          searchTerm,
          sortBy,
          currentPageRef.current,
          PAGE_SIZE
        );

        if (response.success) {
          const { data, total_pages , total } = response.data;         
          dispatch({ type: SET_TASKS, payload: data });
          dispatch({ type: SET_TOTAL_PAGES, payload: total_pages });
          dispatch({type: SET_TOTAL, payload: total })
        } else {
          showErrorMessage("Failed to fetch labeling tasks");
        }
      } catch (err) {
        const errorMessage =
          err.response?.data?.message ||
          err.message ||
          "An error occurred while fetching labeling tasks";
        showErrorMessage(errorMessage);
      } finally {
        if (isLoadMore) {
          dispatch({ type: SET_LOADING_MORE, payload: false });
        } else {
          dispatch({ type: SET_LOADING, payload: false });
        }
      }
    },
    [match.params.clientId, searchTerm, sortBy, showErrorMessage]
  );

  // Initial fetch
  useEffect(() => {
    fetchLabelingTasks(1, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle search and sort changes
  useEffect(() => {
    if (!isInitialMount.current) {
      fetchLabelingTasks(1, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, sortBy]);

  // Debounced search function
  const debouncedSearch = useRef(
    debounce((value) => {
      if (value && value.length < MIN_SEARCH_LENGTH) {
        return;
      }
      dispatch({ type: SET_SEARCH_TERM, payload: value });
      dispatch({ type: SET_CURRENT_PAGE, payload: 1 });
    }, SEARCH_DEBOUNCE_MS)
  ).current;

  const handleSearch = useCallback(
    (value) => {
      debouncedSearch(value);
    },
    [debouncedSearch]
  );

  const handleSort = useCallback((value) => {
    dispatch({ type: SET_SORT_BY, payload: value });
    dispatch({ type: SET_CURRENT_PAGE, payload: 1 });
  }, []);

  const handleCreateTask = useCallback(() => {
    history.push(`${match.url}/create`);
  }, [history, match.url]);

  // Update tasks when currentPage changes
  useEffect(() => {
    if (currentPage >= 1) {
      fetchLabelingTasks(currentPage, true);
    }
  }, [currentPage, fetchLabelingTasks]);

  // Intersection Observer setup
  const loaderRef = useCallback((node) => {
    if (observer.current) {
      observer.current.disconnect();
    }

    if (node) {
      observer.current = new IntersectionObserver(
        (entries) => {
          const [entry] = entries;
          if (
            entry.isIntersecting &&
            !loadingRef.current &&
            !loadingMoreRef.current &&
            currentPageRef.current < totalPagesRef.current
          ) {
            dispatch({
              type: SET_CURRENT_PAGE,
              payload: currentPageRef.current + 1,
            });
          }
        },
        {
          root: null,
          rootMargin: "20px",
          threshold: 0.1,
        }
      );
      observer.current.observe(node);
    }
  }, []);

  // WebSocket connection
  const handleWebSocketMessage = useCallback(
    (data) => {
      if (data.tasks && Array.isArray(data.tasks)) {
        dispatch({
          type: UPDATE_TASKS,
          payload: data.tasks,
        });
      }
    },
    [dispatch]
  );

  useEffect(() => {
    closeSocketRef.current = createWebSocketConnection(
      ApiUrls.LABELING_TASK_WS_URL(match.params.clientId),
      handleWebSocketMessage,
      (error) => {
        console.error("WebSocket error:", error);
      }
    );

    return () => {
      if (closeSocketRef.current) {
        closeSocketRef.current();
      }
    };
  }, [match.params.clientId, handleWebSocketMessage]);

  const renderTopBar = () => {
    // Only show top bar if we've had tasks before or currently have tasks
    if (!hasExistingTasks.current && tasks.length === 0) {
      return null;
    }

    return (
      <div className={Styles.topBar}>
        <SearchFilter
          onSearch={handleSearch}
          onSort={handleSort}
          sortValue={sortBy}
          minSearchLength={MIN_SEARCH_LENGTH}
        />
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={handleCreateTask}
        >
          Create
        </Button>
      </div>
    );
  };

  const renderTasksList = () => {
    if (loading && !loadingMore || tasks.length === 0) {
      return (
        <div className={Styles.loadingContainer}>
          <Spin />
        </div>
      );
    }

    if (tasks.length === 0) {
      return (
        <div className={Styles.emptyStateContainer}>
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            imageStyle={{
              filter: "invert(0.8) brightness(1.5) contrast(0.8)",
            }}
            description={
              <span className={Styles.emptyStateDescription}>
                {searchTerm
                  ? `No results found for "${searchTerm}"`
                  : "No labeling tasks found"}
              </span>
            }
          />
        </div>
      );
    }

    return (
      <div className={Styles.taskList}>
          <div className={Styles.taskList}>
            <ListingTable 
              clientId={match.params.clientId}
              tableDataSource={tableDataSource} 
              onPageChange={(page)=> dispatch({type: SET_CURRENT_PAGE, payload: (page)})}
              total={total}
              currentPage={currentPage}
              loading={loading || loadingMore}
            />
          </div>
      </div>
    );
  };

  const renderContent = () => {
    // Show welcome state only if:
    // 1. Not loading
    // 2. Never had tasks before
    // 3. Currently have no tasks
    // 4. No active search
    if (
      !loading &&
      !hasExistingTasks.current &&
      tasks.length === 0 &&
      !searchTerm
    ) {
      return <WelcomeState onCreateTask={handleCreateTask} />;
    }

    return (
      <>
        {renderTopBar()}
        {renderTasksList()}
      </>
    );
  };

  return (
    <div className={Styles.dashboardContainer}>
      <div className={Styles.contentParent}>{renderContent()}</div>
    </div>
  );
};

export default Dashboard;
