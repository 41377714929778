import { Col, Collapse, Row, Tabs } from "antd";
import React from "react";
import Style from "./ShowCluster.style";
import { useShowCluster } from "./useShowCluster";
import ShowClusterGraph from "./showClusterGraph";
import NcBreadCrumb from "src/modules/newsAndCulture/components/NcBreadCrumb";
import LoadingSpinner from "src/components/loadingSpinner";
import ClusterPersonas from "./ClusterPersonas";
import { ErrorBoundary } from "react-error-boundary";
import ClusterComparison from "./ClusterComparison/ClusterComparison";
import DownloadReports from "./DownloadReports/DownloadReports";

const ShowCluster = ({ history, match }) => {
  const { loading, clusterData, cloneModal, setCloneModal } = useShowCluster();
  if (loading) {
    return <LoadingSpinner centered />;
  }
  return (
    <>
      <NcBreadCrumb className={Style.breadCrumb} />
      <div className={Style.headerContainer}>
        <div>
          <h3>
            The moment you have been waiting for! 😀 Here are your clustering results, complete with descriptions, personas and activation
            strategies generated by AI. 🎨
          </h3>
        </div>
      </div>
      <Collapse className={"drawerCollapse "} defaultActiveKey={["1"]}>
        <Collapse.Panel header="Cluster Summary" key="1">
          <Row gutter={[30, 30]}>
            <Col xs={24} md={8}>
              <div className={Style.clusterBasicDetailsContainer}>
                <h3>Name: {clusterData?.name}</h3>
                <h3>Description: {clusterData?.description}</h3>
                <h3>Clusters Type: {clusterData?.clusters_type}</h3>
                <h3>No of Clusters: {clusterData?.clusters?.length}</h3>
              </div>
            </Col>
            <Col xs={24} md={16}>
              <ErrorBoundary fallback={<div>Cannot render chart</div>}>
                <ShowClusterGraph data={clusterData?.clusters} clusterType={clusterData?.clusters_type} />
              </ErrorBoundary>
            </Col>
          </Row>
        </Collapse.Panel>
      </Collapse>
      <Tabs defaultActiveKey="cluster_personas">
        <Tabs.TabPane tab="Cluster Description" key="cluster_personas">
          <ClusterPersonas data={clusterData?.clusters} clusterData={clusterData} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Cluster Comparison" key="cluster_comaprison">
          <ClusterComparison clusterData={clusterData} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Cluster Reports" key="cluster_reports">
          <DownloadReports clusterData={clusterData} />
        </Tabs.TabPane>
      </Tabs>
    </>
  );
};

export default ShowCluster;
