import { Button, Col, DatePicker, Form, Row, Select } from "antd";
import moment from "moment";
import React from "react";
import { InputNo, InputText } from "src/components/basic/Input";

import "./CreateMediaPlanForm.less";
import { PrimaryButton, SecondaryButton } from "src/components/basic";
import LoadingSpinner from "src/components/loadingSpinner";
import NcBreadCrumb from "src/modules/newsAndCulture/components/NcBreadCrumb";
import useCreateMediaPlan from "./useCreateMediaPlan";
import appUrls from "src/constants/appUrls";

const { RangePicker } = DatePicker;

const CreateMediaPlanForm = () => {
  const {
    configureLoading,
    loading,
    form,
    onFinish,
    state,
    history,
    selectedAudience,
    audienceOptions,
    metaData,
    mediaPlanData,
    mediaPlanId,
    clientId
  } = useCreateMediaPlan();

  if (loading) {
    return <LoadingSpinner centered />;
  }
  const mediaPlanState = mediaPlanData?.state;
  const disableEdit = mediaPlanState === "CREATING";
  const mediaPlanCreated = mediaPlanState === "CREATED";
  return (
    <div>
      <NcBreadCrumb />
      <h2 style={{ marginBottom: 24 }}>Create Media Plan</h2>
      <Form
        form={form}
        layout="vertical"
        className="custom-scenario-plan-form"
        requiredMark={false}
        onFinish={onFinish}
        initialValues={{
          audience: selectedAudience ? [selectedAudience.id] : [],
          generateBy: "LLM",
        }}
      >
        <Form.Item>
          <div
            style={{
              flexDirection: "row",
              justifyContent: "end",
              display: "flex",
            }}
          >
            <PrimaryButton
              onClick={() => {
                if (mediaPlanId) {
                  history.push(appUrls.MEDIA_PLAN_AUDIENCE_INFO(clientId, mediaPlanId));
                } else {
                  form.submit();
                }
              }}
              className="small"
              loading={configureLoading}
            >
             {mediaPlanId ? 'Next' : 'Save & Next'}
            </PrimaryButton>
          </div>
        </Form.Item>
        <Row gutter={[20, 20]}>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Form.Item
              name="name"
              label="Media Plan Name*"
              rules={[
                { required: true, message: "Media Plan Name is required" },
              ]}
              extra="Enter a unique name for this media plan to easily identify and compare it with others."
            >
              <InputText disabled={disableEdit}/>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Form.Item
              name="audience"
              label="Audience "
              rules={[
                { required: true, message: "Please select the audience" },
              ]}
              extra="Select the target audience for this media plan."
            >
              <Select
                mode="multiple"
                showArrow
                disabled={disableEdit || mediaPlanCreated}
                options={
                  state?.state?.audiencelist?.map((audience) => ({
                    label: audience.name,
                    value: audience.id,
                  })) || audienceOptions
                }
              />
            </Form.Item>
          </Col>
        </Row>
        {/* <Row gutter={[20, 20]}>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Form.Item
              name="brand"
              label="Brand"
              rules={[{ required: true, message: "Brand is required" }]}
              extra="Select the brand for this media plan."
            >
              <Select>
                {metaData?.brand?.map((brand) => (
                  <Select.Option value={brand} key={brand}>
                    {brand}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Form.Item
              name="category"
              label="Category"
              rules={[{ required: true, message: "Category is required" }]}
              extra="Select the category for this media plan."
            >
              <Select>
                {metaData?.category?.map((category) => (
                  <Select.Option value={category} key={category}>
                    {category}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row> */}
        <Row gutter={[20, 20]}>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Form.Item
              name="goals"
              label="Media Plan Goals*"
              rules={[{ required: true, message: "Objective is required" }]}
              extra="Define the primary goal of this media plan."
            >
              <Select mode="multiple" maxTagCount={5} disabled={disableEdit || mediaPlanCreated}>
                {metaData?.goals?.map((goals) => (
                  <Select.Option value={goals} key={goals}>
                    {goals}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Form.Item
              name="channel"
              label="Channel"
              rules={[{ required: true, message: "Please select the channel" }]}
              extra="Select the channels for this media plan."
            >
              <Select mode="multiple" maxTagCount={5} disabled={disableEdit || mediaPlanCreated}>
                {metaData?.channels?.map((channels) => (
                  <Select.Option value={channels} key={channels}>
                    {channels}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[20, 20]}>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Form.Item
              name="kpis"
              label="Marketing KPIs"
              rules={[{ required: true, message: "KPI is required" }]}
              extra="Select the KPI's for this media plan."
            >
              <Select mode="multiple" maxTagCount={5} disabled={disableEdit || mediaPlanCreated}>
                {metaData?.kpis?.map((kpis) => (
                  <Select.Option value={kpis} key={kpis}>
                    {kpis}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Form.Item
              name="budget"
              label="Specify your Budget"
              extra="Set your media plan budget and see auto-calculated suggestions based on your campaign duration."
            >
              <InputNo
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                } // Format to USD with commas
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")} // Remove dollar sign and commas for parsing
                min={0}
                disabled={disableEdit || mediaPlanCreated}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[20, 20]}>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Form.Item
              name="dateRange"
              label="Start & End Date"
              extra="Set the duration for this media plan to evaluate performance over a specific period."
            >
              <RangePicker style={{ width: "100%" }} disabled={disableEdit || mediaPlanCreated}/>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default CreateMediaPlanForm;
