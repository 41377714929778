import React from "react";
import useFlightingTable from "./useFlightingTable";
import { Collapse, Table } from "antd";
import { format } from "date-fns";
import "./MediaplanOutput.less";

const FlightingTable = ({ tab, tabData }) => {
  const { getColumns, formatData } = useFlightingTable();
  return (
    <div>
      <h3>Audiences:</h3>
      <div style={{marginLeft: '1rem'}}>
        {tabData?.map((audience, idx) => {
          const audienceName = audience?.audience_name;
          const columns =
            audience?.channels?.length > 0
              ? getColumns(audience?.channels[0])
              : [];
          const formattedData =
            audience?.channels?.length > 0
              ? formatData(audience?.channels)
              : [];
          return (
            <Collapse
              className={"regularCollapse"}
              style={{ flexGrow: "1" }}
              defaultActiveKey={["0"]}
            >
              <Collapse.Panel header={<b>{audienceName}</b>} key={idx}>
                <div className="tableContainer">
                  <Table
                    className="dark-table"
                    columns={columns}
                    dataSource={formattedData}
                    pagination={false}
                    rowKey="id"
                    scroll={{
                      x: "max-content",
                    }}
                  />
                </div>
              </Collapse.Panel>
            </Collapse>
          );
        })}
      </div>
    </div>
  );
};

export default FlightingTable;
