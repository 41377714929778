import { notification } from "antd";
import axios from "axios";
import Cookie from "src/lib/cookie";
import AppUrls from 'src/constants/appUrls';

/**
 * Axios instance to talk to the API.
 */
const instance = axios.create({
  timeout: 1000 * 600, // 60 seconds timeout
  headers: {
    "Cache-Control": "no-cache",
  }
});

let networkErrorNotification;

export function initHttp() {
  /**
   * Adds Interceptor to axios to add token in every request header
   */
  instance.interceptors.request.use(
    function(config) {
      // eslint-disable-line
      // Add user token to header
      if (Cookie.get("token")) {
        config.headers.authorization = Cookie.get("token"); // eslint-disable-line
      }
      return config;
    },
    function(error) {
      // eslint-disable-line
      // Do something with request error
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    function(config) {
      // eslint-disable-line
      try {
        if (networkErrorNotification) {
          networkErrorNotification = false;
          notification.close("network_error");
        }
      } catch (ex) {
        /* ignored */
      }
      return config;
    },
    function(error) {
      // eslint-disable-line
      if (error?.response?.status === 503) {
        if (!networkErrorNotification) {
          networkErrorNotification = true;
          notification.error({
            key: "network_error",
            message:
              "Could not reach our servers. Please re-try or refresh your browser.",
            duration: 0
          });
        }

        return Promise.reject(error);
      }

      if (error.message === "Network Error") {
        if (!networkErrorNotification) {
          networkErrorNotification = true;
          notification.error({
            key: "network_error",
            message:
              "Could not reach our servers. Please check your internet connection",
            duration: 0
          });
        }

        return Promise.reject(error);
      }
      if (error.response && error.response.status === 403) {
        // When token fails, The below authorization error occurs
        if (error?.response?.data?.detail?.includes("Invalid Token")) {
          try {
            Cookie.remove('token');
            Cookie.remove('accountId');
            window.location.href = AppUrls.AUTH_LOGIN;
          } catch (ex) {
            /* ignored */
          }
        }
      }

      return Promise.reject(error);
    }
  );

}

export default instance;
