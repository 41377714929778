import { css } from "@linaria/core";

export const listItemContainer = css`
  display: flex;
  align-items: center;
  padding: 16px 20px;
  background-color: var(--color-90-black);
  border-radius: var(--card-border-radius);
  transition: all 0.3s ease;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  border-left: 4px solid transparent;

  &:hover {
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.3);
    background-color: var(--color-hover-black);
  }
`;

export const nameSection = css`
  flex: 2;
  min-width: 150px;
  padding-right: 16px;
`;

export const taskName = css`
  color: var(--color-white);
  font-size: 16px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const statusSection = css`
  width: 120px;
`;

export const statusText = css`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  font-weight: 500;
`;

export const progressSection = css`
  flex: 1;
  min-width: 180px;

  .ant-progress {
    display: flex;
    align-items: center;
  }

  .ant-progress-outer {
    flex-grow: 1;
  }
`;

export const dataSourceSection = css`
  flex: 1;
`;

export const dateSection = css`
  flex: 1;
  min-width: 120px;
  padding-right: 16px;
`;

export const actionSection = css`
  flex: 0 0 auto;
  min-width: 80px;
  display: flex;
  justify-content: flex-end;
`;

export const metaInfo = css`
  color: var(--color-30-gray);
  font-size: 12px;
`;

export const viewDetailsLink = css`
  color: var(--color-link);
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  gap: 4px;
`;
