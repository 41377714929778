/* Authentication related URL */
const AUTH_LOGIN = `/login`;

const OKTA_LOGIN = "/okta-callback";

const HOMEPAGE = "/home";

const CREATE_GPT = (id) => `/client/${id}/create/custom`;

const CLIENT_HOME = (id) => `/client/${id}`;

const APP_PAGE = (clientId, id, sessionId, mode) => {
  const params = new URLSearchParams();
  if (sessionId) {
    params.set("session-id", sessionId);
  }
  if (mode === "socialInsights") {
    params.set("mode", mode);
  }
  return `/client/${clientId}/chat/${id}?${params}`;
};

const EDIT_CUSTOM_GPT = (clientId, id, mode = "configure") =>
  `/client/${clientId}/edit/custom/${id}/${mode}`;

const AUDIENCE_TOOL = (id) => `/client/${id}/audience-tool`;
const AUDIENCE_DETAIL = (clientId, audienceId, audienceProfileId) => {
  const params = new URLSearchParams();
  if (audienceProfileId) {
    params.set("audience-profile-id", audienceProfileId);
  }
  return `${AUDIENCE_TOOL(clientId)}/audience/${audienceId}?${params}`;
}
const CLUSTER_TOOL = (id) => `/client/${id}/cluster-tool`;
const AUDIENCE_MANAGER = (id) => `${AUDIENCE_TOOL(id)}/audience-manager`;
const CREATE_AUDIENCE = (id) => `${AUDIENCE_TOOL(id)}/create-audience`;
const EDIT_AUDIENCE = (id, audienceId, audienceConfigId) => {
  const params = new URLSearchParams();
  if (audienceConfigId) {
    params.set("audience-config-id", audienceConfigId);
  }
  return `${AUDIENCE_TOOL(id)}/edit-audience/${audienceId}?${params}`;
}
const CREATE_MEDIA_PLAN_AUDIENCE = (id, audienceId) =>
  `${AUDIENCE_TOOL(id)}/create-media-plan/${audienceId}`;

const MEDIA_PLAN_LISTING_LAYOUT = (id) => `/client/${id}/media-plan`;
const EDIT_MEDIA_PLAN = (clientId, mediaPlanId) => `${MEDIA_PLAN_LISTING_LAYOUT(clientId)}/edit-media-plan/${mediaPlanId}`;
const MEDIA_PLAN_LISTING = (id) => `${MEDIA_PLAN_LISTING_LAYOUT(id)}/listing`;
const CREATE_MEDIA_PLAN = (id) => `${MEDIA_PLAN_LISTING_LAYOUT(id)}/create-media-plan`;
const MEDIA_PLAN_DASH = (id, mediaPlanId) =>
  `${MEDIA_PLAN_LISTING_LAYOUT(id)}/dashboard/${mediaPlanId}`;
const MEDIA_PLAN_AUDIENCE_INFO = (id, mediaPlanId) =>
  `${MEDIA_PLAN_LISTING_LAYOUT(id)}/audience-information/${mediaPlanId}`;
const MEDIA_PLAN_SCENARIO_PLANNING_SCREEN = (id, mediaPlanId) =>
  `${MEDIA_PLAN_LISTING_LAYOUT(id)}/scenario-planning/${mediaPlanId}`;

const MEDIA_PLAN_SCENARIO_OUTPUT_SCREEN = (id, mediaPlanId) =>
  `${MEDIA_PLAN_LISTING_LAYOUT(id)}/media-flighting/${mediaPlanId}`;

const SQL_TOOL = (clientId, id, mode = "capability") =>
  `/client/${clientId}/edit/custom/${id}/${mode}/sql-tool`;

const CREATE_CLUSTER = (id) => `/client/${id}/cluster-tool/create-cluster`;

const EDIT_CLUSTER = (id, clusterId, audienceConfigId) => {
  const params = new URLSearchParams();
  if (audienceConfigId) {
    params.set("audience-config-id", audienceConfigId);
  }
  return `/client/${id}/cluster-tool/edit-cluster/${clusterId}?${params}`;
}

const SELECT_FEATURES = (id, clusterId, audienceConfigId) => {
  const params = new URLSearchParams();
  if (audienceConfigId) {
    params.set("audience-config-id", audienceConfigId);
  }
  return `/client/${id}/cluster-tool/select-features/${clusterId}?${params}`;
}


const SHOW_CLUSTER = (appId, clusterId) =>
  `/client/${appId}/cluster-tool/cluster/${clusterId}`;

const CLUSTER_CONFIG_FEATURES = (id, clusterId, audienceConfigId) => {
  const params = new URLSearchParams();
  if (audienceConfigId) {
    params.set("audience-config-id", audienceConfigId);
  }
  return `/client/${id}/cluster-tool/features/${clusterId}?${params}`;
}
const NEWS_CULTURE = (id) => `/client/${id}/news-culture`;

const ANALYSIS_OVERVIEW_SCREEN = (clientId, analysisId) =>
  `/client/${clientId}/news-culture/analysis-overview/${analysisId}`;

const CREATE_LISTING_SCREEN = (clientId, analysisId = "") => {
  return `/client/${clientId}/news-culture/create-listing${analysisId ? `/${analysisId}` : ""
    }`;
};

const UPDATE_LISTING_SCREEN = (id, analysisId) =>
  `/client/${id}/news-culture/update-listing/${analysisId}`;

const CREATE_ANALYSIS_QUERY = (clientId, analysisId) =>
  `/client/${clientId}/news-culture/create-analysis-query/${analysisId}`;

// Labeler
const LABELER = (id) => `/client/${id}/labeler`;

const CREATE_CLIENT = `/create-client-space`;

const EDIT_CLIENT_SPACE = (id) => `/client/${id}/edit`;

/* Cluster Tool URLs moved under Audience Tool */
const CREATE_CLUSTER_TOOL = (id) => `${AUDIENCE_TOOL(id)}/create-cluster`;
const EDIT_CLUSTER_TOOL = (id, clusterId, audienceConfigId) => {
  const params = new URLSearchParams();
  if (audienceConfigId) {
    params.set("audience-config-id", audienceConfigId);
  }
  return `${AUDIENCE_TOOL(id)}/edit-cluster/${clusterId}?${params}`;
}
const SELECT_FEATURES_TOOL = (id, clusterId, audienceConfigId) => {
  const params = new URLSearchParams();
  if (audienceConfigId) {
    params.set("audience-config-id", audienceConfigId);
  }
  return `${AUDIENCE_TOOL(id)}/select-features/${clusterId}?${params}`;
}

const SHOW_CLUSTER_TOOL = (appId, clusterId) => `${AUDIENCE_TOOL(appId)}/cluster/${clusterId}`;

const CLUSTER_CONFIG_FEATURES_TOOL = (id, clusterId, audienceConfigId) => {
  const params = new URLSearchParams();
  if (audienceConfigId) {
    params.set("audience-config-id", audienceConfigId);
  }
  return `${AUDIENCE_TOOL(id)}/features/${clusterId}?${params}`;
}
export default {
  AUTH_LOGIN,
  OKTA_LOGIN,
  HOMEPAGE,
  CLIENT_HOME,
  CREATE_GPT,
  EDIT_CUSTOM_GPT,
  APP_PAGE,
  CLUSTER_TOOL,
  AUDIENCE_MANAGER,
  CREATE_AUDIENCE,
  EDIT_AUDIENCE,
  EDIT_CLUSTER,
  SELECT_FEATURES,
  CREATE_CLUSTER,
  SHOW_CLUSTER,
  CLUSTER_CONFIG_FEATURES,
  NEWS_CULTURE,
  ANALYSIS_OVERVIEW_SCREEN,
  CREATE_LISTING_SCREEN,
  SQL_TOOL,
  UPDATE_LISTING_SCREEN,
  CREATE_ANALYSIS_QUERY,
  AUDIENCE_TOOL,
  AUDIENCE_DETAIL,
  CREATE_MEDIA_PLAN_AUDIENCE,
  EDIT_MEDIA_PLAN,
  MEDIA_PLAN_DASH,
  MEDIA_PLAN_LISTING_LAYOUT,
  MEDIA_PLAN_LISTING,
  CREATE_MEDIA_PLAN,
  MEDIA_PLAN_AUDIENCE_INFO,
  MEDIA_PLAN_SCENARIO_PLANNING_SCREEN,
  MEDIA_PLAN_SCENARIO_OUTPUT_SCREEN,
  LABELER,
  CREATE_CLIENT,
  EDIT_CLIENT_SPACE,
  CREATE_CLUSTER_TOOL,
  EDIT_CLUSTER_TOOL,
  SELECT_FEATURES_TOOL,
  SHOW_CLUSTER_TOOL,
  CLUSTER_CONFIG_FEATURES_TOOL,
};
