import moment from "moment";
import { useState, useEffect } from "react";
import { numberWithCommas } from "src/lib/utils";

const useFlightingTable = () => {
  let gradient = [
    [0, [255, 247, 236]],
    [15, [254, 232, 200]],
    [30, [253, 212, 158]],
    [45, [253, 187, 132]],
    [60, [252, 141, 89]],
    [75, [239, 101, 72]],
    [90, [215, 48, 31]],
    [100, [179, 0, 0]],
  ];

  function pickHex(color1, color2, weight) {
    let p = weight;
    let w = p * 2 - 1;
    let w1 = (w / 1 + 1) / 2;
    let w2 = 1 - w1;
    let rgb = [
      Math.round(color1[0] * w1 + color2[0] * w2),
      Math.round(color1[1] * w1 + color2[1] * w2),
      Math.round(color1[2] * w1 + color2[2] * w2),
    ];
    return rgb;
  }

  function getColor(val) {
    let colorRange = [];
    // Iterate over the gradient array
    for (let index = 0; index < gradient.length; index++) {
      let value = gradient[index];
      if (val <= value[0]) {
        colorRange = [index - 1, index];
        break;
      }
    }
    // Get the two closest colors
    let firstcolor = gradient[colorRange[0]][1];
    let secondcolor = gradient[colorRange[1]][1];

    // Calculate ratio between the two closest colors
    let firstcolor_x = gradient[colorRange[0]][0] / 100;
    let secondcolor_x = gradient[colorRange[1]][0] / 100 - firstcolor_x;
    let slider_x = val / 100 - firstcolor_x;
    let ratio = slider_x / secondcolor_x;

    // Get the color with pickHex
    let result = pickHex(secondcolor, firstcolor, ratio);
    console.log(result); // Output the resulting color
    return result;
  }
  const getColumns = (data) => {
    let columns = [
      {
        title: () => <span style={{ fontWeight: "700" }}>Channel</span>,
        dataIndex: "channel",
        key: "channel",
        fixed: "left",
        width: 150,
        render: (text) => <div style={{padding: '8px 16px'}}>{text}</div>,
      },
      {
        title: () => <span style={{ fontWeight: "700" }}>Channel Budget</span>,
        dataIndex: "channel_budget",
        key: "channel_budget",
        fixed: "left",
        width: 150,
        render: (text) => {
          return <div style={{padding: '8px 16px'}}>{`$${numberWithCommas(text)}`}</div> ;
        },
      }
    ];
    data?.weekly_plan?.forEach((item) => {
      columns.push({
        title: () => {
          return (
            <div style={{ textAlign: "center" }}>
              <span style={{ marginBottom: "10px", fontWeight: "700" }}>
                <i>Week {item?.week_number} </i>
                <br />
              </span>
              <div style={{ fontWeight: 600 }}>
                {`${moment(item?.week_start_date).format(
                  "MMM-DD-YYYY"
                )} To ${moment(item?.week_end_date).format("MMM-DD-YYYY")}`}
              </div>
            </div>
          );
        },
        dataIndex: item?.week_start_date,
        key: item?.week_start_date,
        render: (res) => {
          if (res?.budget !== 0) {
            return (
              <div
              style={{
                background: res?.color,
                color: "black",
                padding: "8px 16px",
                fontWeight: "500",
              }}
            >
              {res?.budget !== 0 ? `$${numberWithCommas(res?.budget)}` : "N/A"}
            </div>
            )
          }
          return (
            <div style={{padding: '8px 16px'}}>
            </div>
          )
        },
      });
    });
    return columns;
  };

  const formatData = (data) => {
    let formattedData = [];
    data?.forEach((item) => {
      let obj = {
        channel: item?.channel,
        channel_budget: item?.channel_budget,
      };
      const maxBudget = Math.max(...item?.weekly_plan?.map((week) => week.budget));
      item?.weekly_plan?.forEach((week) => {
        const percentage = (week?.budget / maxBudget) * 100;
        let color = [];
        if (percentage !== 0) {
          color = getColor(percentage);
        } else if (percentage === 0) {
          color = [255, 255, 255];
        }
        let rgbColor = "";
        if (color.length > 0) {
          rgbColor = "rgb(" + color.join(",") + ")";
        }
        obj = {
          ...obj,
          [week?.week_start_date]: { ...week, color: rgbColor },
        };
      });
      formattedData.push(obj);
    });
    return formattedData;
  };

  return {
    getColumns,
    formatData,
  };
};

export default useFlightingTable;
