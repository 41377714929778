
import React from "react";
import "./MediaPlanAudienceInformation.less";
import {
  Col,
  Collapse,
  DatePicker,
  Form,
  InputNumber,
  Modal,
  Row,
  Select,
  Slider,
} from "antd";
import { PrimaryButton, SecondaryButton } from "src/components/basic";
import LoadingSpinner from "src/components/loadingSpinner";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import NcBreadCrumb from "src/modules/newsAndCulture/components/NcBreadCrumb";
import { formatString, numberWithCommas } from "src/lib/utils";
import moment from "moment";
import MediaPlanAudienceDrawer from "./MediaPlanAddAudienceDrawer";
import useMediaPlanAudienceInformation from "./useMediaPlanAudienceInformation";
import appUrls from "src/constants/appUrls";

export const MediaPlanAudienceInformation = () => {
  const {
    loading,
    formRef,
    setAddAudienceModal,
    addAudienceModal,
    audienceOptions,
    selectedAudience,
    setSelectedAudience,
    metaData,
    audienceInfo,
    deleteMediaPlanAudience,
    disabledDate,
    saveAndRunReccomendAudience,
    configureLoading,
    mediaPlanId,
    allocatedBudget,
    setAllocatedBudget,
    clientId,
    history,
  } = useMediaPlanAudienceInformation();
  const formatter = (value) => `$${numberWithCommas(value)}`;
  if (loading) {
    return <LoadingSpinner centered />;
  }
  return (
    <div>
      <NcBreadCrumb />
      <h2>Audience Information</h2>
      <Row gutter={[20, 0]} justify="end" style={{ marginBottom: "1rem" }}>
        <Col>
          <SecondaryButton
            comfy
            className="small"
            onClick={() =>
              history.push(appUrls.EDIT_MEDIA_PLAN(clientId, mediaPlanId))
            }
          >
            Back
          </SecondaryButton>
        </Col>
        <Col>
        <PrimaryButton
          loading={configureLoading}
          className="small"
          comfy
          onClick={() => formRef.current.submit()}
        >
          {audienceInfo?.mediaplan_state === "DRAFT" ? "Save and Continue" : "Next"}
        </PrimaryButton>
        </Col>
        
      </Row>
      <Row className="header-container">
        <Col span={6}>
          <b>Media Plan Name:</b> {audienceInfo?.mediaplan_name}
        </Col>
        <Col span={4}>
          <b>Budget:</b> ${numberWithCommas(audienceInfo?.mediaplan_budget)}
        </Col>
        <Col span={4}>
          <b>Allocated Budget:</b> ${numberWithCommas(allocatedBudget)}
        </Col>
        <Col span={5}>
          <b>Start Date:</b>{" "}
          {moment(audienceInfo?.start_date).format("MM/DD/YYYY")}
        </Col>
        <Col span={5}>
          <b>End Date:</b> {moment(audienceInfo?.end_date).format("MM/DD/YYYY")}
        </Col>
      </Row>
      <h3>Audiences:</h3>
      <div style={{ marginLeft: "1rem" }}>
        <Form
          initialValues={audienceInfo}
          requiredMark={false}
          ref={formRef}
          onFinish={(val) => {
            if (audienceInfo?.mediaplan_state === "DRAFT") {
              saveAndRunReccomendAudience(val);
            } else {
              history.push(appUrls.MEDIA_PLAN_SCENARIO_PLANNING_SCREEN(clientId, audienceInfo?.mediaplan_response_id));
            }
          }}
          onValuesChange={(changedValues, allValues) => {
            let budget = 0;
            if (changedValues?.audience_mapping) {
              allValues.audience_mapping.forEach((audience, idx) => {
                if (audience?.budget) {
                  budget += audience.budget;
                }
              });
            }
            if (budget !== allocatedBudget) {
              setAllocatedBudget(budget);
            }
          }}
        >
          <Form.Item name="audience_budget_mapping">
            <Form.List name="audience_mapping">
              {(fields, { add, remove }) => {
                return (
                  <Form.Item shouldUpdate noStyle>
                    {({ getFieldsValue, setFieldsValue }) => {
                      return (
                        <>
                          {fields.map(
                            ({ key, name, fieldKey, ...restField }, idx) => {
                              const audiences = getFieldsValue("audiences");
                              const audienceName =
                                audiences?.audience_mapping?.[idx]
                                  ?.audience_name;
                              return (
                                <div
                                  className="audienceItemContainer"
                                  key={key}
                                >
                                  <Collapse
                                    className={"regularCollapse"}
                                    style={{ flexGrow: "1" }}
                                    defaultActiveKey={["0"]}
                                  >
                                    <Collapse.Panel
                                      forceRender
                                      header={<b>{audienceName}</b>}
                                      key={idx}
                                    >
                                      <Row key={key} gutter={[20, 20]}>
                                        <Col span={24}>
                                          <Form.Item
                                            {...restField}
                                            name={[name, "json_data", "goals"]}
                                            fieldKey={[
                                              fieldKey,
                                              "audienceObjective",
                                            ]}
                                            labelCol={{ span: 4 }}
                                            labelAlign="left"
                                            label="Audience Objective"
                                            rules={[
                                              {
                                                required: true,
                                                message:
                                                  "Missing audience objective",
                                              },
                                            ]}
                                          >
                                            <Select
                                              mode="multiple"
                                              maxTagCount={6}
                                              placeholder="Select objective for audience"
                                              showArrow
                                            >
                                              {audienceInfo?.mediaplan_goals?.map(
                                                (goal) => (
                                                  <Select.Option value={goal}>
                                                    {goal}
                                                  </Select.Option>
                                                )
                                              )}
                                            </Select>
                                          </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                          <Form.Item
                                            {...restField}
                                            name={[
                                              name,
                                              "json_data",
                                              "channels",
                                            ]}
                                            fieldKey={[
                                              fieldKey,
                                              "audienceChannels",
                                            ]}
                                            label="Channels"
                                            labelCol={{ span: 4 }}
                                            labelAlign="left"
                                            rules={[
                                              {
                                                required: true,
                                                message:
                                                  "Channels is required.",
                                              },
                                            ]}
                                          >
                                            <Select
                                              mode="multiple"
                                              placeholder="Select channels"
                                              maxTagCount={6}
                                              showArrow
                                            >
                                              {audienceInfo?.mediaplan_channels?.map(
                                                (channels) => (
                                                  <Select.Option
                                                    value={channels}
                                                  >
                                                    {channels}
                                                  </Select.Option>
                                                )
                                              )}
                                            </Select>
                                          </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                          <Form.Item
                                            {...restField}
                                            name={[name, "dateRange"]}
                                            fieldKey={[fieldKey, "dateRange"]}
                                            labelAlign="left"
                                            labelCol={{ span: 8 }}
                                            label="Time"
                                            rules={[
                                              {
                                                required: true,
                                                message: "Missing time range",
                                              },
                                            ]}
                                          >
                                            <DatePicker.RangePicker
                                              disabledDate={disabledDate}
                                            />
                                          </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                          <Form.Item
                                            {...restField}
                                            name={[name, "budget"]}
                                            fieldKey={[fieldKey, "budget"]}
                                            labelCol={{ span: 4 }}
                                            labelAlign="left"
                                            label="Budget"
                                            rules={[
                                              {
                                                required: true,
                                                message: "Budget is required.",
                                              },
                                            ]}
                                          >
                                            <Slider
                                              min={0}
                                              // style={{ width: "45%" }}
                                              max={
                                                audienceInfo?.mediaplan_budget
                                              }
                                              tooltip={{
                                                formatter,
                                              }}
                                              marks={{
                                                [0]: `$${numberWithCommas(0)}`,
                                                [audienceInfo?.mediaplan_budget]: `$${numberWithCommas(
                                                  audienceInfo?.mediaplan_budget
                                                )}`,
                                              }}
                                            />
                                          </Form.Item>
                                        </Col>
                                      </Row>
                                    </Collapse.Panel>
                                  </Collapse>
                                  <DeleteOutlined
                                    style={{ marginTop: "-10px" }}
                                    onClick={() => remove(name)}
                                  />
                                </div>
                              );
                            }
                          )}
                          <Form.Item>
                            <SecondaryButton
                              className="small"
                              onClick={() => setAddAudienceModal(true)}
                            >
                              <PlusOutlined />
                              Add Audience
                            </SecondaryButton>
                          </Form.Item>
                          <Modal
                            open={addAudienceModal}
                            onCancel={() => setAddAudienceModal(false)}
                            title="Add Audience"
                            footer={null}
                            destroyOnClose
                          >
                            <MediaPlanAudienceDrawer
                              audienceOptions={audienceOptions}
                              add={add}
                              setAddAudienceModal={setAddAudienceModal}
                              mediaPlanId={mediaPlanId}
                            />
                          </Modal>
                        </>
                      );
                    }}
                  </Form.Item>
                );
              }}
            </Form.List>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
