import { DeleteOutlined, LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Col, Input, List, Pagination, Row } from "antd";
import moment from "moment";
import React from "react";
import { PrimaryButton } from "src/components/basic";
import ConditionalRender from "src/components/conditionalRender";
import AppUrls from "src/constants/appUrls";
import { NEW_CAMPAIGN_STATUS_BACKGROUND_COLOR } from "src/components/globalTag/globalTagConstant";
import useClusterToolListingHook from "./useClusterToolListingHook";
import Style from "./clusterToolListing.style";
import { formatString, shortenStr } from "src/lib/utils";
import GlobalTag from "src/components/globalTag/GlobalTag";

export default function ClusterToolListing({ history, match }) {
  const id = match.params.clientId;
  const { loading, filters, data, deleteCluster, page, searchQuery, setPageNumber, setSearchQuery, changeSearchQuery, debouncedSearch } =
    useClusterToolListingHook({ id });
  return (
    <div>
      <h1>Welcome to the AutoCluster App!</h1>
      <Row justify="space-between" style={{ marginTop: "1rem" }}>
        <Col span={8}>
          <Input placeholder="Search" value={searchQuery} onChange={(val) => {
            setSearchQuery(val.target.value);
            debouncedSearch.current = setTimeout(() => {
              if (debouncedSearch.current) {
                clearTimeout(debouncedSearch.current);
                changeSearchQuery(val.target.value);
              }
            }, 2000);
          }} />
        </Col>
        <Col>
          <PrimaryButton onClick={() => history.push(AppUrls.CREATE_CLUSTER(id))}>
            Create
            <PlusOutlined />
          </PrimaryButton>
        </Col>
      </Row>
      <ConditionalRender shouldRender={true}>
        <List
          className={Style.listContainer}
          dataSource={data?.data || []}
          loading={loading}
          header={
            <div className={Style.listHeader}>
              <div style={{ width: "20%" }}>Name</div>
              <div style={{ width: "20%" }}>Description</div>
              <div style={{ width: "15%" }}>Type</div>
              <div style={{ width: "15%" }}>Status</div>
              <div style={{ width: "25%" }}>Created on</div>
            </div>
          }
          renderItem={(item, idx) => {
            return (
              <div className={Style.listItemContainer}>
                <button
                  className={Style.listItem + " transition-all"}
                  key={`cluster-tool-list-${idx}`}
                  disabled={item?.state === "ERROR"}
                  onClick={() => {
                    if (item?.state === "RELEVANT_FEATURES_FOUND" || item?.state === "CREATING_AUDIENCES") {
                      history.push(AppUrls.CLUSTER_CONFIG_FEATURES(item?.client_space_id, item?.id, item?.audience_config_id));
                    } else if (item?.state === "AUDIENCES_CREATED") {
                      history.push(AppUrls.SHOW_CLUSTER(item?.client_space_id, item?.id));
                    } else if (item?.state === "PROJECT_BRIEF_WRITTEN" || item?.state === "FINDING_RELEVANT_FEATURES") {
                      history.push(AppUrls.SELECT_FEATURES(item?.client_space_id, item?.id, item?.audience_config_id));
                    }
                  }}
                >
                  {item?.state === "DONE" || item?.state === "SELECTED_FEATURES" ? (
                    <div className="name">{item?.name}</div>
                  ) : (
                    <div className="name">{item?.name}</div>
                  )}
                  <div className="description">{shortenStr(item?.description, 32)}</div>
                  <div className="type">{item?.clusters_type}</div>
                  <div className="status">
                    <GlobalTag color={NEW_CAMPAIGN_STATUS_BACKGROUND_COLOR[item?.state]}>
                      {formatString(item?.state)}{" "}
                      {/* {(item?.state === "IDENTIFYING_SALIENT_FEATURES" ||
                        item?.state === "CREATING_SAMPLE_CLUSTERS" ||
                        item?.state === "CREATING_FINAL_CLUSTERS") && <LoadingOutlined style={{fontSize: '4px'}}/>} */}
                    </GlobalTag>
                  </div>
                  <div className="date">{moment(item?.created_on).format("LLL")}</div>
                </button>
                <div className={Style.deleteContainer}>
                  <DeleteOutlined onClick={() => deleteCluster(item?.id)} />
                </div>
              </div>
            );
          }}
        />
        <Pagination
          current={page}
          pageSize={10}
          total={data?.total_pages}
          onChange={(page) => {
            setPageNumber(page);
          }}
          style={{ textAlign: "right", marginTop: 16 }}
          showSizeChanger={false}
        />
      </ConditionalRender>
    </div>
  );
}
