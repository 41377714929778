import { useState } from 'react';
import audienceListService from '../../../services/audienceListService';
const columns = [
    { label: 'Feature Name', value: 'feature_readable_name' },
    { label: 'Feature Value', value: 'feature_readable_value' },
    { label: 'Feature Type', value: 'feature_type' },
    { label: 'Feature Source', value: 'feature_source' },
    { label: 'Audience Feature Count', value: 'audience_feature_count' },
    { label: 'Base Feature Count', value: 'base_feature_count' },
    { label: 'Audience Feature Percentage', value: 'audience_feature_percentage' },
    { label: 'Base Feature Percentage', value: 'base_feature_percentage' },
    { label: 'Index Value', value: 'index_value' },
    { label: 'Profile Type', value: 'profile_type' },
];

const useDownloadManagerHook = (audienceProfileId, handleDownloadModalCancel) => {
    const [selectedFeatureType, setSelectedFeatureType] = useState("");
    const [selectedColumns, setSelectedColumns] = useState(columns.map(col => col.value));
    const [numRows, setNumRows] = useState(10);
    const [sortOrder, setSortOrder] = useState('-index_value');
    const [isDownloading, setIsDownloading] = useState(false);

    const escapeCSVValue = (value) => {
        if (value === null || value === undefined) {
            return '';
        }
        return `"${String(value).replace(/"/g, '""')}"`;
    };

    const generateCSV = (data, selectedColumns) => {
        const selectedCols = columns.filter(col => selectedColumns.includes(col.value));
        const header = selectedCols.map(col => escapeCSVValue(col.label)).join(',');
        const rows = data.map(item => {
            return selectedCols.map(col => escapeCSVValue(item[col.value])).join(',');
        });
        return [header, ...rows].join('\n');
    };

    const downloadCSV = (csvData, filename) => {
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        setIsDownloading(false);
        handleDownloadModalCancel();
    };

    const handleFeatureTypeChange = (value) => {
        setSelectedFeatureType(value);
    };

    const handleColumnChange = (checkedValues) => {
        setSelectedColumns(checkedValues);
    };

    const handleNumRowsChange = (value) => {
        setNumRows(value);
    };

    const handleSortOrderChange = (value) => {
        setSortOrder(value);
    };

    const handleDownload = async () => {
        setIsDownloading(true);
        const featureData = await audienceListService.getAudienceFeatureProfiles(audienceProfileId, sortOrder, "", 1, selectedFeatureType, undefined, numRows);
        const csvData = generateCSV(featureData.data.data, selectedColumns);
        downloadCSV(csvData, 'audience_data.csv');
    };

    return {
        selectedFeatureType,
        selectedColumns,
        columns,
        numRows,
        sortOrder,
        handleFeatureTypeChange,
        handleColumnChange,
        handleNumRowsChange,
        handleSortOrderChange,
        handleDownload,
        isDownloading
    };
};

export default useDownloadManagerHook;