import { Col, Form, notification, Row, Select } from "antd";
import React from "react";
import { PrimaryButton } from "src/components/basic";
import { set } from "dot-object";
import { getErrorMessageFromResponse } from "src/lib/utils";
import audienceListService from "../../../services/audienceListService";

export default function MediaPlanAudienceDrawer({ audienceOptions, add, setAddAudienceModal, mediaPlanId }) {
  const formRef = React.useRef();
  const [loading, setLoading] = React.useState(false);
  return (
    <div>
      <Form
        ref={formRef}
        onFinish={async (val) => {
          try {
            setLoading(true);
            const audiences = [{ audience_id: val.audience }];
            const payload = {
              mediaplan_id: mediaPlanId,
              audience_mapping: audiences,
            }
            const res = await audienceListService.updateMediaPlanAudience(payload);
            const audienceMapping = res.data.audience_mapping;
            const addedAudience = audienceMapping.find((audience) => audience.audience_id === val.audience);
            if (addedAudience) {
              add(addedAudience);
            }
            setAddAudienceModal(false);
            setLoading(false);
            notification.success({
              message: "Audience added successfully",
              duration: 5,
              placement: "bottomRight",
            })
          } catch (error) {
            setLoading(false);
            const { msg } = getErrorMessageFromResponse(error);
            notification.error({
              message: msg,
              duration: 5,
              placement: "bottomRight",
            });
          }
        }}
      >
        <Form.Item
          name="audience"
          rules={[{ required: true, message: "Please select an audience" }]}
        >
          <Select placeholder="Select Audience">
            {audienceOptions.map((audience) => (
              <Select.Option key={audience.value} value={audience.value}>
                {audience.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
      <Row justify="end">
        <Col>
          <PrimaryButton
            className="small"
            loading={loading}
            comfy
            onClick={() => {
              formRef.current.submit();
            }}
          >
            Add Audience
          </PrimaryButton>
        </Col>
      </Row>
    </div>
  );
}
