import ApiUrls from "src/constants/apiUrls";
import axios from "src/http/index";
export default class audienceListService {
  static async getAudienceList(clientId, currentPage, size = 10, searchName, filterType, sortBy, isAscending, statusFilter) {
    const url = ApiUrls.GET_AUDIENCE_LIST(clientId, currentPage, size, searchName, filterType, sortBy, isAscending, statusFilter);
    const response = await axios.get(url);
    return response.data;
  }

  static async cloneAudience(audienceProjectId, clonedProjectName) {
    const url = ApiUrls.CLONE_AUDIENCE(audienceProjectId, clonedProjectName);
    const response = await axios.get(url)
    return response.data;
  }

  static async cloneAudienceProfile(audienceProfileId, clonedProfileName) {
    const url = ApiUrls.CLONE_AUDIENCE_PROFILE(audienceProfileId, clonedProfileName);
    const response = await axios.get(url)
    return response.data;
  }

  static async getAudienceUsers(audienceId) {
    const url = ApiUrls.GET_AUDIENCE_USERS(audienceId);
    const response = await axios.get(url);
    return response.data;
  }

  static async addUserToAudience(payload) {
    const url = ApiUrls.ADD_UPDATE_AUDIENCE_USER;
    const response = await axios.put(url, payload);
    return response.data;
  }

  static async deleteUserFromAudience(audienceId, userId) {
    const url = ApiUrls.DELETE_USER_FROM_AUDIENCE(audienceId, userId);
    const response = await axios.delete(url);
    return response.data;
  }

  static async createMediaplanRecommendation(payload) {
    const url = ApiUrls.CREATE_MEDIAPLAN_RECOMMENDATION;
    const response = await axios.post(url, payload);
    return response.data;
  }

  static async getAudienceByid(audienceId) {
    const url = ApiUrls.GET_AUDIENCE_BY_ID(audienceId);
    const response = await axios.get(url);
    return response.data;
  }

  static async getAudienceRecommendations(clientId, useCache, refreshPrompt) {
    const url = ApiUrls.GET_CLIENT_AUDIENCES_RECOMMENDATIONS(
      clientId,
      useCache,
      refreshPrompt
    );
    const response = await axios.get(url);
    return response.data;
  }

  static async getAudienceFeatureProfiles(audienceId, orderBy, featureName, currentPage, featureType, groupByParent, pageSize) {
    const url = ApiUrls.GET_AUDIENCE_FEATURE_PROFILES(audienceId, orderBy, featureName, currentPage, featureType, groupByParent, pageSize);
    const response = await axios.get(url);
    return response.data;
  }

  static async archiveAudience(audienceId, action) {
    const url = ApiUrls.ARCHIVE_AUDIENCE(audienceId, action);
    const response = await axios.get(url);
    return response.data;
  }

  static async archiveAudienceProfile(audienceId, action) {
    const url = ApiUrls.ARCHIVE_AUDIENCE_PROFILE(audienceId, action);
    const response = await axios.get(url);
    return response.data;
  }

  static async hideAudience(audienceId, action) {
    const url = ApiUrls.HIDE_AUDIENCE(audienceId, action);
    const response = await axios.get(url);
    return response.data;
  }

  static async replaceAudienceBehaviouralFeature(payload) {
    const url = ApiUrls.REPLACE_BEHAVIOUR_FEATURE();
    const response = await axios.put(url, payload);
    return response.data;
  }

  static async profileAudience(audienceProfileId) {
    const url = ApiUrls.PROFILE_AUDIENCE(audienceProfileId)
    const response = await axios.get(url);
    return response.data;
  }

  static async getAudienceConfigById(audienceConfigId) {
    const url = ApiUrls.GET_AUDIENCE_CONFIG(audienceConfigId);
    const response = await axios.get(url);
    return response.data;
  }

  static async getAudienceProjectList(clientId, search) {
    const url = ApiUrls.GET_AUDIENCE_PROJECT_LIST(clientId, search);
    const response = await axios.get(url);
    return response.data;
  }
}
