import { useState, useEffect } from "react";
import { Form, notification } from "antd";
import { useParams, useLocation, useHistory } from "react-router-dom";
import NcApiServices from "../services/NcApiServices";
import { getErrorMessageFromResponse } from "src/lib/utils";
import appUrls from "src/constants/appUrls";
import moment from "moment";
import { scheduleIntervals } from "../constants";

const useCreateListing = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [sections, setSections] = useState([
    {
      key: 0,
      sectionName: "",
      newsQueries: [{ key: 0 }],
      facebookQueries: [{ key: 0 }],
      twitterQueries: [{ key: 0 }],
      threadsQueries: [{ key: 0 }],
      tiktokQueries: [{ key: 0 }],
      instagramQueries: [{ key: 0 }],
    },
  ]);
  const [instaFormData, setInstaFormData] = useState({
    handle: [],
    hashtag: [],
    max_count: "",
  });
  const [isFormComplete, setIsFormComplete] = useState(false);
  const [isNameValid, setIsNameValid] = useState(false);
  const [showActionSteps, setShowActionSteps] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    clientDetails: "",
    problemStatement: "",
    actionSteps: "",
  });
  const [analysisUuId, setAnalysisUuId] = useState(null);
  const [form] = Form.useForm();
  const { clientId, analysisId } = useParams();
  const location = useLocation();
  const mode = location.state?.mode || "Create";
  const [analysisCategories, setAnalysisCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [saveDraftLoading, setSaveDraftLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [initialAnalysisData, setInitialAnalysisData] = useState(null);
  const history = useHistory();
  const [schedule, setSchedule] = useState(0);
  const [isScheduled, setIsScheduled] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [queryData, setQueryData] = useState(null);
  const [sectionData, setSectionData] = useState(null);

  useEffect(() => {
    if (currentStep === 2) {
      validateFormFields();
    }
  }, [sections, showActionSteps, currentStep]);

  useEffect(() => {
    getAnalysisCategories();
    if (analysisId) {
      getAnalysisConfig(analysisId);
      setCurrentStep(2);
    }
  }, [analysisId]);

  const handleSectionNameBlur = () => {
    // Do nothing here to prevent validation
  };

  const handleNext = async (values) => {
    setFormData((prevFormData) => {
      const updatedFormData = {
        ...prevFormData,
        ...(values.name && { name: values.name }),
        ...(values.clientDetails && { clientDetails: values.clientDetails }),
        ...(values.problemStatement && {
          problemStatement: values.problemStatement,
        }),
        ...(values.actionSteps && { actionSteps: values.actionSteps }),
      };
      if (mode === "Create") {
        if (!analysisId) {
          createAnalysis({
            client_space_id: clientId,
            ...(values.name && { name: values.name }),
            ...(values.clientDetails && { client_info: values.clientDetails }),
            ...(values.problemStatement && {
              problem_statement: values.problemStatement,
            }),
            ...(values.actionSteps && {
              proposed_plan_of_action: values.actionSteps,
            }),
          }).then((res) => {
            if (res?.success) {
              const { data } = res;
              setSectionData(data);
              setAnalysisUuId(data.id);
              history.push(`${location.pathname}/${data.id}`);
              setCurrentStep(2);
              prefillSecondPage(data);
            }
          });
        } else {
          setCurrentStep(2);
        }
      } else {
        setCurrentStep(2);
      }

      // Return the updated state
      return updatedFormData;
    });
  };

  const onRadioChange = (e) => {
    setSchedule(e.target.value);
  };

  useEffect(() => {
    if (schedule === 0) {
      form.setFieldsValue({
        scheduleEndDate: null,
        scheduleInterval: null,
      });
    }
  }, [schedule]);

  const handleBack = () => {
    setCurrentStep(1);
  };

  const onRangeChange = (sectionIndex, dates) => {
    if (dates) {
      form.setFieldsValue({
        [`queryDate_${sectionIndex}`]: dates,
      });
    } else {
      form.setFieldsValue({
        [`queryDate_${sectionIndex}`]: [],
      });
    }
  };

  const addSection = () => {
    setSections([
      ...sections,
      {
        key: sections.length,
        sectionName: "",
        newsQueries: [{ key: 0 }],
        facebookQueries: [{ key: 0 }],
        twitterQueries: [{ key: 0 }],
        tiktokQueries: [{ key: 0 }],
        instagramQueries: [{ key: 0 }],
        threadsQueries: [{ key: 0 }],
      },
    ]);
    form.validateFields(); // Validate fields to update form state
  };

  const removeSection = (sectionId) => {
    const newSections = sections.filter((item) => item.sectionId !== sectionId);
    setSections(newSections);
    validateFormFields();
  };

  const getAnalysisCategories = async () => {
    try {
      setLoading(true);
      const res = await NcApiServices.getAnalysisCategories();
      if (res?.success) {
        setAnalysisCategories(res?.data || []);
      }
      setLoading(false);
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: "bottomRight",
      });
      setLoading(false);
    }
  };

  const getAnalysisConfig = async (analysisId) => {
    try {
      setPageLoading(true);
      const res = await NcApiServices.getAnalysisOverview(analysisId);
      if (res?.success) {
        const { data } = res;
        setInitialAnalysisData(data);
        setFormData({
          name: data?.name,
          clientDetails: data?.client_info,
          problemStatement: data?.problem_statement,
          actionSteps: data?.proposed_plan_of_action,
        });
        data?.is_scheduled ? setSchedule(1) : setSchedule(0);
        setIsScheduled(data?.is_scheduled);
        prefillSecondPage(data);
      }
      setPageLoading(false);
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: "bottomRight",
      });
      setPageLoading(false);
    }
  };

  const dateFormat = "YYYY-MM-DD";

  const prefillSecondPage = (data) => {
    const newSections = data.section_data.map((section, sectionIndex) => ({
      key: sectionIndex,
      sectionName: section.section_name,
      sectionId: section.section_id,

      newsQueries: section.social_media
        ?.filter((sm) => sm.social_media_platform === "news")
        .map((newsItem, newsIndex) => ({
          key: newsIndex,
          newsId: newsItem.social_media_config_id,
          itemData: newsItem,
          queryTitle: "News Query",
          json_query: newsItem.social_media_config?.[0]?.json_query,
          lucene_query: newsItem.social_media_config?.[0]?.lucene_query,
          max_count: newsItem.social_media_config?.[0]?.max_count,
        })) || [{ key: 0 }],

      facebookQueries: section.social_media
        ?.filter((sm) => sm.social_media_platform === "facebook")
        .map((facebookItem, facebookIndex) => ({
          key: facebookIndex,
          facebookId: facebookItem.social_media_config_id,
          itemData: facebookItem,
          queryTitle: "Facebook Query",
          json_query: facebookItem.social_media_config?.[0]?.json_query,
          lucene_query: facebookItem.social_media_config?.[0]?.lucene_query,
          max_count: facebookItem.social_media_config?.[0]?.max_count,
        })) || [{ key: 0 }],

      twitterQueries: section.social_media
        ?.filter((sm) => sm.social_media_platform === "twitter")
        .map((twitterItem, twitterIndex) => ({
          key: twitterIndex,
          twitterId: twitterItem.social_media_config_id,
          itemData: twitterItem,
          queryTitle: "Twitter Query",
          json_query: twitterItem.social_media_config?.[0]?.json_query,
          lucene_query: twitterItem.social_media_config?.[0]?.lucene_query,
          max_count: twitterItem.social_media_config?.[0]?.max_count,
        })) || [{ key: 0 }],

      instagramQueries: section.social_media
        ?.filter((sm) => sm.social_media_platform === "instagram")
        .map((instagramItem, instagramIndex) => ({
          key: instagramIndex,
          instagramId: instagramItem.social_media_config_id,
          itemData: instagramItem,
          queryTitle: "Instagram Query",
          json_query: instagramItem.social_media_config?.[0]?.json_query,
          lucene_query: instagramItem.social_media_config?.[0]?.lucene_query,
          max_count: instagramItem.social_media_config?.[0]?.max_count,
        })) || [{ key: 0 }],

      threadsQueries: section.social_media
        ?.filter((sm) => sm.social_media_platform === "threads")
        .map((threadsItem, threadsIndex) => ({
          key: threadsIndex,
          threadsId: threadsItem.social_media_config_id,
          itemData: threadsItem,
          queryTitle: "Threads Query",
          json_query: threadsItem.social_media_config?.[0]?.json_query,
          lucene_query: threadsItem.social_media_config?.[0]?.lucene_query,
          max_count: threadsItem.social_media_config?.[0]?.max_count,
        })) || [{ key: 0 }],

      tiktokQueries: section.social_media
        ?.filter((sm) => sm.social_media_platform === "tiktok")
        .map((tiktokItem, tiktokIndex) => ({
          key: tiktokIndex,
          tiktokId: tiktokItem.social_media_config_id,
          itemData: tiktokItem,
          queryTitle: "TikTok Query",
          json_query: tiktokItem.social_media_config?.[0]?.json_query,
          lucene_query: tiktokItem.social_media_config?.[0]?.lucene_query,
          max_count: tiktokItem.social_media_config?.[0]?.max_count,
        })) || [{ key: 0 }],
    }));

    setSections(newSections);

    const initialFormValues = {
      name: data.name,
      clientDetails: data.client_info,
      problemStatement: data.problem_statement,
      actionSteps: data.proposed_plan_of_action,
      custom_tags: data.custom_tags || [],
    };
    initialFormValues["radioBtn"] = data.is_scheduled ? 1 : 0;
    initialFormValues["scheduleEndDate"] = data.schedule_end_date
      ? moment(data.schedule_end_date, dateFormat)
      : null;
    initialFormValues["scheduleInterval"] = data.run_every_n_hours || null;
    data.section_data.forEach((section, sectionIndex) => {
      initialFormValues[`sectionName_${sectionIndex}`] = section.section_name;

      // Convert date strings to moment objects
      const startDate = section.start_date
        ? moment(section.start_date, dateFormat)
        : null;
      const endDate = section.end_date
        ? moment(section.end_date, dateFormat)
        : null;
      initialFormValues[`queryDate_${sectionIndex}`] = [startDate, endDate];

      section.news?.forEach((news, newsIndex) => {
        initialFormValues[`news_keywords_${sectionIndex}_${newsIndex}`] =
          news.news_config[0].keywords || undefined;
        initialFormValues[
          `news_negative_keywords_${sectionIndex}_${newsIndex}`
        ] = news.news_config[0].negative_keywords || undefined;
        initialFormValues[`news_categories_${sectionIndex}_${newsIndex}`] =
          news.news_config[0].categories || undefined;

        initialFormValues[`news_countryCode_${sectionIndex}_${newsIndex}`] =
          news.news_config[0].country_code?.toUpperCase();
        initialFormValues[`news_publisher_${sectionIndex}_${newsIndex}`] =
          news.news_config[0].publisher;
        initialFormValues[`news_articles_${sectionIndex}_${newsIndex}`] =
          news.news_config[0].max_count;
      });
      section.social_media
        ?.filter((sm) => sm.social_media_platform === "facebook")
        .forEach((facebook, facebookIndex) => {
          initialFormValues[
            `facebook_keywords_${sectionIndex}_${facebookIndex}`
          ] = facebook.social_media_config?.[0]?.keywords || undefined;
          initialFormValues[
            `facebook_negative_keywords_${sectionIndex}_${facebookIndex}`
          ] = facebook.social_media_config?.[0]?.negative_keywords || undefined;
          initialFormValues[
            `facebook_handle_${sectionIndex}_${facebookIndex}`
          ] = facebook.social_media_config?.[0]?.handle;
          initialFormValues[
            `facebook_articles_${sectionIndex}_${facebookIndex}`
          ] = facebook.social_media_config?.[0]?.max_count;
        });
      section.social_media
        ?.filter((sm) => sm.social_media_platform === "twitter")
        .forEach((twitter, twitterIndex) => {
          initialFormValues[`twitter_handle_${sectionIndex}_${twitterIndex}`] =
            twitter.social_media_config?.[0]?.handle;
          initialFormValues[
            `twitter_hashtags_${sectionIndex}_${twitterIndex}`
          ] = twitter.social_media_config?.[0]?.hashtags || undefined;
          initialFormValues[
            `twitter_countryCode_${sectionIndex}_${twitterIndex}`
          ] = twitter.social_media_config?.[0]?.country_code?.toUpperCase();
          initialFormValues[`twitter_posts_${sectionIndex}_${twitterIndex}`] =
            twitter.social_media_config?.[0]?.max_count;
        });
    });
    form.setFieldsValue(initialFormValues);
    form.validateFields();
  };

  const addNewsQuery = (sectionIndex) => {
    const newSections = [...sections];
    newSections[sectionIndex].newsQueries.push({
      key: newSections[sectionIndex].newsQueries.length,
    });
    setSections(newSections);
    form.validateFields(); // Validate fields to update form state
  };

  const addFacebookQuery = (sectionIndex) => {
    const newSections = [...sections];
    newSections[sectionIndex].facebookQueries.push({
      key: newSections[sectionIndex].facebookQueries.length,
    });
    setSections(newSections);
    form.validateFields(); // Validate fields to update form state
  };

  const addTwitterQuery = (sectionIndex) => {
    const newSections = [...sections];
    newSections[sectionIndex].twitterQueries.push({
      key: newSections[sectionIndex].twitterQueries.length,
    });
    setSections(newSections);
    form.validateFields(); // Validate fields to update form state
  };

  const addInstagramQuery = (sectionIndex) => {
    const newSections = [...sections];
    newSections[sectionIndex].instagramQueries.push({
      key: newSections[sectionIndex].instagramQueries.length,
    });
    setSections(newSections);
    form.validateFields(); // Validate fields to update form state
  };

  const addTiktokQuery = (sectionIndex) => {
    const newSections = [...sections];
    newSections[sectionIndex].tiktokQueries.push({
      key: newSections[sectionIndex].tiktokQueries.length,
    });
    setSections(newSections);
    form.validateFields(); // Validate fields to update form state
  };

  const addThreadsQuery = (sectionIndex) => {
    const newSections = [...sections];
    newSections[sectionIndex].threadsQueries.push({
      key: newSections[sectionIndex].threadsQueries.length,
    });
    setSections(newSections);
    form.validateFields(); // Validate fields to update form state
  };

  const removeQuery = (sectionId, queryType, query) => {
    const newSections = [...sections];
    const sectionIndex = newSections.findIndex(
      (section) => section.sectionId === sectionId
    );
    if (sectionIndex === -1) {
      console.error("Section not found");
      return;
    }
    let queryId, queryTypeId;
    switch (queryType) {
      case "twitterQueries":
        queryTypeId = "twitterId";
        queryId = query.twitterId;
        break;
      case "facebookQueries":
        queryTypeId = "facebookId";
        queryId = query.facebookId;
        break;
      case "newsQueries":
        queryTypeId = "newsId";
        queryId = query.newsId;
        break;
      case "tiktokQueries":
        queryTypeId = "tiktokId";
        queryId = query.tiktokId;
        break;
      case "instagramQueries":
        queryTypeId = "instagramId";
        queryId = query.instagramId;
        break;
      case "threadsQueries":
        queryTypeId = "threadsId";
        queryId = query.threadsId;
        break;
      default:
        console.error("Invalid query type");
        return;
    }

    const queries = newSections[sectionIndex][queryType];
    const queryIndex = queries.findIndex(
      (query) => query[queryTypeId] == queryId
    );
    if (queryIndex === -1) {
      console.error("Query not found");
      return;
    }

    newSections[sectionIndex][queryType] = queries.filter(
      (_, i) => i !== queryIndex
    );

    setSections(newSections);
    validateFormFields();
  };

  const handleFinish = (values) => {
    const payload = generatePayload(values);
    generateAnalysis(payload);
  };

  const handleSaveDraft = (values) => {
    const payload = generatePayload(values);
    updateAnalysis(payload);
  };

  const addUpdateQueryInstagram = (formData, queryData) => {
    const newSections = [...sections];
    const { sectionIndex } = queryData;

    let luceneQueryParts = [];

    if (formData.handle?.length > 0) {
      luceneQueryParts.push(
        formData.handle.map((item) => `handle:${item}`).join(" OR ")
      );
    }
    if (formData.hashtag?.length > 0) {
      luceneQueryParts.push(
        formData.hashtag.map((item) => `hashtag:${item}`).join(" OR ")
      );
    }

    let luceneQuery = luceneQueryParts.join(" OR ");

    const findAndUpdateOrAdd = (queries) => {
      return queries.map((dt) => {
        if (dt.key === queryData?.query?.key) {
          return {
            ...dt,
            lucene_query: luceneQuery,
            json_query: formData,
            max_count: formData.max_count,
          };
        }
        return dt;
      });
    };

    newSections[sectionIndex].instagramQueries = findAndUpdateOrAdd(
      newSections[sectionIndex].instagramQueries
    );
    notification.success({
      duration: 5,
      message: "Query Validated.",
      placement: "bottomRight",
    });
    setDrawerVisible(false);
  };

  const addUpdateQuery = async (query, queryData, numberOfPosts) => {
    let response;
    try {
      response = await NcApiServices.addQuery({
        social_media_platform: queryData.queryType.replace(/Queries$/, ""),
        json_query: query,
        max_count: numberOfPosts,
      });
      if (response?.success) {
        notification.success({
          duration: 5,
          message: "Query Validated.",
          placement: "bottomRight",
        });
        setDrawerVisible(false);
      }
    } catch (error) {
      console.error(error);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: "bottomRight",
      });
    }
    const newSections = [...sections];
    const { sectionIndex } = queryData;
    const findAndUpdateOrAdd = (queries) => {
      return queries.map((dt) => {
        if (dt.key === queryData?.query?.key) {
          return { ...dt, ...response.data, max_count: numberOfPosts };
        }
        return dt;
      });
    };

    switch (queryData.queryType) {
      case "newsQueries":
        newSections[sectionIndex].newsQueries = findAndUpdateOrAdd(
          newSections[sectionIndex].newsQueries
        );
        break;
      case "facebookQueries":
        newSections[sectionIndex].facebookQueries = findAndUpdateOrAdd(
          newSections[sectionIndex].facebookQueries
        );
        break;
      case "twitterQueries":
        newSections[sectionIndex].twitterQueries = findAndUpdateOrAdd(
          newSections[sectionIndex].twitterQueries
        );
        break;
      // case "instagramQueries":
      //   newSections[sectionIndex].instagramQueries = findAndUpdateOrAdd(
      //     newSections[sectionIndex].instagramQueries
      //   );
      //   break;
      case "tiktokQueries":
        newSections[sectionIndex].tiktokQueries = findAndUpdateOrAdd(
          newSections[sectionIndex].tiktokQueries
        );
        break;
      case "threadsQueries":
        newSections[sectionIndex].threadsQueries = findAndUpdateOrAdd(
          newSections[sectionIndex].threadsQueries
        );
        break;
      default:
        break;
    }

    setSections(newSections);
  };

  const createAnalysis = async (payload) => {
    try {
      setPageLoading(true);
      const res = await NcApiServices.createAnalysis(payload);
      if (res?.success) {
        setInitialAnalysisData(res?.data);
      }
      setPageLoading(false);
      return res;
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: "bottomRight",
      });
      setPageLoading(false);
    }
  };

  const generateAnalysis = async (payload) => {
    try {
      setButtonLoading(true);
      setPageLoading(true);
      const res = await NcApiServices.generateAnalysis(payload);
      if (res?.success) {
        history.push(appUrls.NEWS_CULTURE(clientId));
      }
      setButtonLoading(false);
      setPageLoading(false);
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: "bottomRight",
      });
      setButtonLoading(false);
      setPageLoading(false);
    }
  };

  const updateAnalysis = async (payload) => {
    try {
      setSaveDraftLoading(true);
      const res = await NcApiServices.updateAnalysis(payload);
      if (res?.success) {
        notification.success({
          duration: 5,
          message: "Draft saved successfully",
          placement: "bottomRight",
        });
        history.push(appUrls.NEWS_CULTURE(clientId));
      }
      setSaveDraftLoading(false);
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: "bottomRight",
      });
      setSaveDraftLoading(false);
    }
  };

  const generatePayload = (values) => {
    const sectionData = sections.map((section, sectionIndex) => {
      const queryDate = values[`queryDate_${sectionIndex}`];

      const startDate =
        queryDate && queryDate[0]
          ? moment(queryDate[0]).format("YYYY-MM-DDTHH:mm:ss[Z]")
          : null;
      const endDate =
        queryDate && queryDate[1]
          ? moment(queryDate[1]).format("YYYY-MM-DDTHH:mm:ss[Z]")
          : null;
      const newsConfigs = section.newsQueries
        .map((query, queryIndex) => ({
          social_media_config_id: query.newsId || undefined,
          social_media_platform: "news",
          social_media_config: [
            {
              json_query: query.json_query,
              lucene_query: query.lucene_query,
              max_count: query.max_count,
            },
          ],
          social_media_data_source: query.itemData?.social_media_data_source,
        }))
        .filter(
          (config) =>
            config.social_media_config?.[0]?.json_query ||
            config.social_media_config?.[0]?.lucene_query ||
            config.social_media_config?.[0]?.max_count ||
            config.social_media_data_source === "MANUAL_UPLOAD",
        );

      const facebookConfigs = section.facebookQueries
        .map((query, queryIndex) => ({
          social_media_config_id: query.facebookId || undefined,
          social_media_platform: "facebook",
          social_media_config: [
            {
              json_query: query.json_query,
              lucene_query: query.lucene_query,
              max_count: query.max_count,
            },
          ],
          social_media_data_source: query.itemData?.social_media_data_source,
        }))
        .filter(
          (config) =>
            config.social_media_config?.[0]?.json_query ||
            config.social_media_config?.[0]?.lucene_query ||
            config.social_media_config?.[0]?.max_count ||
            config.social_media_data_source === "MANUAL_UPLOAD",
        );

      const twitterConfigs = section.twitterQueries
        .map((query, queryIndex) => ({
          social_media_config_id: query.twitterId || undefined,
          social_media_platform: "twitter",
          social_media_config: [
            {
              json_query: query.json_query,
              lucene_query: query.lucene_query,
              max_count: query.max_count,
            },
          ],
          social_media_data_source: query.itemData?.social_media_data_source,
        }))
        .filter(
          (config) =>
            config.social_media_config?.[0]?.json_query ||
            config.social_media_config?.[0]?.lucene_query ||
            config.social_media_config?.[0]?.max_count ||
            config.social_media_data_source === "MANUAL_UPLOAD",
        );

      const tiktokConfigs = section.tiktokQueries
        .map((query, queryIndex) => ({
          social_media_config_id: query.tiktokId || undefined,
          social_media_platform: "tiktok",
          social_media_config: [
            {
              json_query: query.json_query,
              lucene_query: query.lucene_query,
              max_count: query.max_count,
            },
          ]
        }))
        .filter(
          (config) =>
            config.social_media_config?.[0]?.json_query ||
            config.social_media_config?.[0]?.lucene_query ||
            config.social_media_config?.[0]?.max_count ||
            config.social_media_data_source === "MANUAL_UPLOAD",
        );

      const instagramConfigs = section.instagramQueries
        .map((query, queryIndex) => ({
          social_media_config_id: query.instagramId || undefined,
          social_media_platform: "instagram",
          social_media_config: [
            {
              json_query: query.json_query,
              lucene_query: query.lucene_query,
              max_count: query.max_count,
            },
          ],
          social_media_data_source: query.itemData?.social_media_data_source,
        }))
        .filter(
          (config) =>
            config.social_media_config?.[0]?.json_query ||
            config.social_media_config?.[0]?.lucene_query ||
            config.social_media_config?.[0]?.max_count ||
            config.social_media_data_source === "MANUAL_UPLOAD",
        );

      const threadsConfigs = section.threadsQueries
        .map((query, queryIndex) => ({
          social_media_config_id: query.threadsId || undefined,
          social_media_platform: "threads",
          social_media_config: [
            {
              json_query: query.json_query,
              lucene_query: query.lucene_query,
              max_count: query.max_count,
            },
          ],
          social_media_data_source: query.itemData?.social_media_data_source,
        }))
        .filter(
          (config) =>
            config.social_media_config?.[0]?.json_query ||
            config.social_media_config?.[0]?.lucene_query ||
            config.social_media_config?.[0]?.max_count ||
            config.social_media_data_source === "MANUAL_UPLOAD",
        );

      return {
        section_name: values[`sectionName_${sectionIndex}`] || null,
        ...(section.sectionId && { section_id: section.sectionId }),
        start_date: startDate || null,
        end_date: endDate || null,
        social_media: [
          ...newsConfigs,
          ...facebookConfigs,
          ...twitterConfigs,
          ...threadsConfigs,
          ...tiktokConfigs,
          ...instagramConfigs,
        ],
      };
    });

    return {
      client_space_id: clientId,
      name: formData.name,
      ...(formData.clientDetails && { client_info: formData.clientDetails }),
      ...(formData.problemStatement && {
        problem_statement: formData.problemStatement,
      }),
      ...(formData.actionSteps && {
        proposed_plan_of_action: formData.actionSteps,
      }),
      section_data: sectionData,
      analysis_id: analysisUuId || analysisId,
      is_scheduled: schedule === 1,
      custom_tags: values.custom_tags,
      schedule_end_date:
        schedule === 1
          ? moment(values?.scheduleEndDate).format("YYYY-MM-DDTHH:mm:ss[Z]")
          : undefined,
      ...(schedule === 1 && {
        run_every_n_hours: values?.scheduleInterval,
      }),
    };
  };

  const handleBlur = (field) => {
    form
      .validateFields([field])
      .then(() => {
        if (field === "problemStatement") {
          const value = form.getFieldValue("problemStatement");
          setShowActionSteps(value && value.trim().length > 0);
        }
        setIsNameValid(true);
        validateFormFields();
      })
      .catch(() => {
        setIsNameValid(false);
      });
  };

  const handleQueryBlur = () => {
    form.validateFields().then(() => {
      validateFormFields();
    });
  };

  useEffect(() => {
    if (currentStep === 2 && mode === "Edit") {
      setTimeout(() => {
        validateFormFields();
      }, [800]);
    }
  }, [mode, currentStep]);

  const validateFormFields = () => {
    const allFields = form.getFieldsValue(true);
    const requiredFields = ["name"];

    sections.forEach((section, sectionIndex) => {
      requiredFields.push(`sectionName_${sectionIndex}`);
      requiredFields.push(`queryDate_${sectionIndex}`);
    });

    const sectionNamesFilled = sections.every(
      (section, sectionIndex) => allFields[`sectionName_${sectionIndex}`]
    );

    const queryDateFilled = sections.every(
      (section, sectionIndex) => allFields[`queryDate_${sectionIndex}`]
    );

    // const isAnyNewsFilled = sections.some((section, sectionIndex) =>
    //   section.newsQueries.every(
    //     (query, queryIndex) =>
    //       (allFields[`news_keywords_${sectionIndex}_${queryIndex}`] ||
    //         (allFields[`news_categories_${sectionIndex}_${queryIndex}`] &&
    //           allFields[`news_countryCode_${sectionIndex}_${queryIndex}`] &&
    //           allFields[`news_publisher_${sectionIndex}_${queryIndex}`])) &&
    //       allFields[`news_articles_${sectionIndex}_${queryIndex}`]
    //   )
    // );

    // const isAnyFacebookFilled = sections.some((section, sectionIndex) =>
    //   section.facebookQueries.some(
    //     (query, queryIndex) =>
    //       (allFields[`facebook_keywords_${sectionIndex}_${queryIndex}`] ||
    //         allFields[`facebook_handle_${sectionIndex}_${queryIndex}`]) &&
    //       allFields[`facebook_articles_${sectionIndex}_${queryIndex}`]
    //   )
    // );

    // const isAnyTwitterFilled = sections.every((section, sectionIndex) =>
    //   section.twitterQueries.every(
    //     (query, queryIndex) =>
    //       allFields[`twitter_handle_${sectionIndex}_${queryIndex}`] ||
    //       (allFields[`twitter_hashtags_${sectionIndex}_${queryIndex}`] &&
    //         allFields[`twitter_hashtags_${sectionIndex}_${queryIndex}`].length >
    //           0)
    //   )
    // );

    setIsFormComplete(
      sectionNamesFilled && queryDateFilled
      // &&
      // isAnyNewsFilled &&
      // isAnyFacebookFilled &&
      // isAnyTwitterFilled
    );
  };

  const validateDomain = (_, value) => {
    const domainRegex = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (value && !domainRegex.test(value)) {
      return Promise.reject("Please enter a valid domain");
    }
    return Promise.resolve();
  };

  const onFinish = (values) => {
    if (currentStep === 1) {
      handleNext(values);
    } else {
      handleFinish(values);
    }
  };

  const onSaveDraft = () => {
    form.validateFields().then((values) => {
      handleSaveDraft(values);
    });
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().endOf("day");
  };

  const closeQueryBuilderDrawer = () => {
    setDrawerVisible(false);
  };

  const openQueryBuilderDrawer = () => {
    setDrawerVisible(false);
  };

  return {
    currentStep,
    sections,
    isFormComplete,
    isNameValid,
    showActionSteps,
    form,
    handleNext,
    handleBack,
    addSection,
    removeSection,
    addNewsQuery,
    addFacebookQuery,
    addTwitterQuery,
    removeQuery,
    handleFinish,
    handleSaveDraft,
    handleBlur,
    handleQueryBlur,
    validateDomain,
    mode,
    analysisCategories,
    loading,
    buttonLoading,
    saveDraftLoading,
    pageLoading,
    handleSectionNameBlur,
    onFinish,
    onSaveDraft,
    onRangeChange,
    setCurrentStep,
    validateFormFields,
    initialAnalysisData,
    onRadioChange,
    schedule,
    disabledDate,
    isScheduled,
    drawerVisible,
    setDrawerVisible,
    closeQueryBuilderDrawer,
    openQueryBuilderDrawer,
    queryData,
    setQueryData,
    addUpdateQuery,
    addTiktokQuery,
    addInstagramQuery,
    addThreadsQuery,
    addUpdateQueryInstagram,
    instaFormData,
    setInstaFormData,
  };
};

export default useCreateListing;
