import { Button, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import CenteredAiLoader from "src/components/centeredAiLoader";
import LoadingSpinner from "src/components/loadingSpinner";
import { ASK_AI, VIEWER } from "../../../constants/constants";
import { AI_LOADING_TEXT, COMBINATOR_AND, LABEL_ERROR_BOUNDARY_FALLBACK, LABEL_QUERY_SUMMARY } from "../../../constants/queryBuilderConstants";
import AudienceSize from "./AudienceSize";
import "./dataSourceTabLayout.style.less";
import QueryBuilder from "./QueryBuilder";
import RulesRecommendationModal from "./RulesRecommendationModal";
import useDataSourceTabLayout from "./useDataSourceTabLayout";

import { CLUSTER_BASED, RULE_BASED } from '../../../constants/constants';

// Main component for DataSourceTabLayout
const DataSourceTabLayout = ({ clientId,
  audienceName,
  audienceDescription,
  selectedAudienceData,
  setSelectedAudienceData,
  audienceSize,
  baseSize,
  audienceSizeLoading,
  getAudienceSize,
  errors,
  setErrors,
  behaviourDirectory,
  fromAudience,
  currentStep,
  selectedAudienceType,
  createAudienceLoading,
  onHandleBackFromDataSoruceTable,
  onHandleNextFromDataSoruceTable,
  handleSubmitFromDataSourceTable,
  audienceRole
}) => {

  const {
    loading,
    getAiRecommendedRules,
    rulesRecommendations,
    setRulesRecommendations,
    ruleRecommendationLoading,
    searchLoading,
    handleSearch,
    fields,
    browsedSelectedItems,
    setBrowsedSelectedItems,
    addFeaturesToFields
  } = useDataSourceTabLayout({ clientId, selectedAudienceData });

  const [conditions, setConditions] = useState({ combinator: COMBINATOR_AND, rules: [] });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [aiPrompt, setAiPrompt] = useState(audienceDescription);
  const [recommendationsApplied, setRecommendationsApplied] = useState(false);

  // Update AI prompt when audience description changes
  useEffect(() => {
    setAiPrompt(audienceDescription);
  }, [audienceDescription]);

  // Show AI recommendation modal
  const showAiRecommendationModal = () => {
    Modal.confirm({
      title: 'Get AI Recommended Rules',
      content: <p>Would you like to see AI-recommended rules?</p>,
      okText: 'Yes',
      okType: 'primary',
      cancelText: 'No',
      onOk: () => getAiRecommendedRules(audienceDescription),
    });
  };

  // Fetch AI recommended rules when conditions are met
  useEffect(() => {
    const isRulesEmpty = !selectedAudienceData.rules || Object.keys(selectedAudienceData.rules).length === 0 || selectedAudienceData.rules.rules.length === 0;
    if (!isRulesEmpty) {
      setConditions(selectedAudienceData.rules);
    } else if (audienceDescription.length > 0 && !loading) {
      showAiRecommendationModal();
    }
  }, [audienceDescription, loading]);

  // Update selected audience data when conditions change
  useEffect(() => {
    setSelectedAudienceData({ ...selectedAudienceData, rules: conditions });
  }, [conditions]);

  // Fetch audience size when recommendations are applied
  useEffect(() => {
    if (recommendationsApplied) {
      getAudienceSize();
      setRecommendationsApplied(false);
    }
  }, [selectedAudienceData]);

  // Show modal if there are rule recommendations
  useEffect(() => {
    if (Object.keys(rulesRecommendations).length > 0 && !isModalVisible) {
      setIsModalVisible(true);
    }
  }, [rulesRecommendations]);

  // Handle click on "Ask AI" button
  const handleAskAiClick = () => {
    setRulesRecommendations({});
    setIsModalVisible(true);
  };

  // Apply AI recommendations to conditions
  const handleApplyRecommendations = () => {
    setRecommendationsApplied(true);
    setConditions(rulesRecommendations);
    setIsModalVisible(false);
    setRulesRecommendations({});
  };

  // Cancel AI recommendations modal
  const handleCancel = () => {
    setIsModalVisible(false);
    setRulesRecommendations({});
  };

  // Request AI recommendations based on prompt
  const handleAskRecommendation = () => {
    getAiRecommendedRules(aiPrompt);
  };

  // Generate a human-readable query summary
  const generateQuery = (condition) => {
    const generateQueryRecursive = (condition) => {
      const combinator = condition.combinator.toUpperCase();
      const number = condition.number ? ` ${condition.number}` : '';
      const formattedCombinator = (combinator === 'ANY') ? `${combinator}${number} of the` : combinator;

      const rules = condition.rules.map((rule) => {
        if (Array.isArray(rule.rules) && rule.rules.length > 0) {
          return generateQueryRecursive(rule);
        } else {
          if (Array.isArray(rule.value)) {
            const values = rule.value.map(val => val.value_description).join(', ');
            return `${rule.field} ${rule.operator} (${values})`.trim();
          } else {
            const value = typeof rule.value === 'object' ? rule.value.value_description : rule.value;
            return `${rule.field} ${rule.operator} '${value}'`.trim();
          }
        }
      }).join(', ');

      return `${formattedCombinator} (${rules})`;
    };

    return generateQueryRecursive(condition);
  };

  // Render loading spinner if data is loading
  if (loading) return <LoadingSpinner centered />;

  // Render AI loader if AI recommendations are loading
  if (ruleRecommendationLoading && !isModalVisible)
    return <CenteredAiLoader text={AI_LOADING_TEXT} />;

  // Main component render
  return (
    <div id="data-source-tab-layout">
      <div className="buttonContainer">
        <div className="buttonLeft">
          <Button className={fromAudience ? 'small' : ''} type="primary" onClick={handleAskAiClick}>{ASK_AI}</Button>
        </div>
        <div className="buttonRight">
          {fromAudience && currentStep > 0 && selectedAudienceType !== CLUSTER_BASED && (
            <Button className={fromAudience ? 'small' : ''} type="default" onClick={onHandleBackFromDataSoruceTable} style={{ marginRight: '10px' }} size="small" disabled={createAudienceLoading}>
              Back
            </Button>
          )}
          {(selectedAudienceType === RULE_BASED && currentStep < 2) || (selectedAudienceType === CLUSTER_BASED && currentStep === 0) ? (
            <Button className={fromAudience ? 'small' : ''} type="primary" onClick={onHandleNextFromDataSoruceTable} size="small">
              Next
            </Button>
          ) : (
            selectedAudienceType !== CLUSTER_BASED && (
              <>
                <Button
                  className={fromAudience ? 'small' : ''}
                  type="primary"
                  onClick={() => handleSubmitFromDataSourceTable(false)}
                  size="small"
                  loading={createAudienceLoading}
                  disabled={createAudienceLoading}
                >
                  {audienceRole === VIEWER ? 'Next' : currentStep === 2 ? 'Save and Continue' : 'Save as Draft'}
                </Button>
              </>
            )
          )}
        </div>
      </div>
      <ErrorBoundary fallback={<div className="errorBoundaryFallback">{LABEL_ERROR_BOUNDARY_FALLBACK}</div>}>
        <div className="boolean-builder">
          <AudienceSize
            audienceSize={audienceSize}
            baseSize={baseSize}
            audienceSizeLoading={audienceSizeLoading}
            getAudienceSize={getAudienceSize}
          />
          <QueryBuilder
            conditions={conditions}
            setConditions={setConditions}
            fields={fields}
            searchLoading={searchLoading}
            handleSearch={handleSearch}
            isReadOnly={audienceRole === VIEWER}
            errors={errors}
            setErrors={setErrors}
            behaviourDirectory={behaviourDirectory}
            browsedSelectedItems={browsedSelectedItems}
            setBrowsedSelectedItems={setBrowsedSelectedItems}
            addFeaturesToFields={addFeaturesToFields}
          />
          <div id="query-output">
            <h2>{LABEL_QUERY_SUMMARY}</h2>
            <pre>{generateQuery(conditions)}</pre>
          </div>
        </div>
      </ErrorBoundary>
      <RulesRecommendationModal
        isModalVisible={isModalVisible}
        loading={ruleRecommendationLoading}
        aiPrompt={aiPrompt}
        handleAiPromptChange={(e) => setAiPrompt(e.target.value)}
        rulesRecommendations={rulesRecommendations}
        handleAskRecommendation={handleAskRecommendation}
        handleApplyRecommendations={handleApplyRecommendations}
        handleCancel={handleCancel}
      />
    </div>
  );
};

export default DataSourceTabLayout;