import { MenuUnfoldOutlined } from "@ant-design/icons";
import { Dropdown, Menu, notification, Tooltip } from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import GlobalTag from "src/components/globalTag/GlobalTag";
import AppUrls from "src/constants/appUrls";
import { getErrorMessageFromResponse, justFormatNumber, removeUnderscores } from "src/lib/utils";
import CreateClientApiServices from "src/modules/addNewClientSpace/services/createClientSpaceApiServices";
import { ClusterToolApiServices } from "src/modules/audienceTool/clusterTool/services/ClusterToolApiServices";
import { AUDIENCE_STATE_COLOR, AUDIENCES_CREATED, CLUSTER_BASED, CREATING_AUDIENCES, PROFILED, RULE_BASED, VIEWER } from "../../constants/constants";
import audienceListService from "../../services/audienceListService";

export default function useAudienceListingHook(id, history) {
  const [loading, setLoading] = useState(true);
  const [audienceListData, setAudienceListData] = useState([]);
  const [audienceListTotal, setAudienceListTotal] = useState(0);
  const [selectedAudience, setSelectedAudience] = useState(null);
  const [isAudienceDetailsOpen, setIsAudienceDetailsOpen] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [userLists, setUserLists] = useState([]);

  const [cloneModal, setCloneModal] = useState({
    visible: false,
    audienceProjectId: null,
    audienceProfileId: null,
    name: '',
    type: '',
    isAudienceProject: true,
  });
  const [usersAndPermissionsDrawer, setUsersAndPermissionDrawer] = useState({
    visible: false,
    data: null
  })
  const debouncedSearch = useRef();
  const timeRef = useRef();

  const filterRef = useRef({
    currentPage: 1,
    searchName: '',
    filterType: '',
    sortBy: 'updated_on',
    isAscending: false,
    statusFilter: '',
  });
  const closeCloneModal = () => {
    setCloneModal({
      visible: false,
      audienceProjectId: null,
      audienceProfileId: null,
      name: '',
      type: '',
      isAudienceProject: true
    })
  }

  const closeUsersAndPermissionsDrawer = () => {
    setUsersAndPermissionDrawer({ visible: false, data: null })
  }

  const handleAudienceClick = (audience) => {
    if ((audience?.state === "RELEVANT_FEATURES_FOUND" || audience?.state === "CREATING_AUDIENCES") && audience.type === CLUSTER_BASED && audience.configId !== undefined && audience.audienceConfigId !== undefined) {
      history.push(AppUrls.CLUSTER_CONFIG_FEATURES_TOOL(id, audience.configId, audience.audienceConfigId));
    } else if ((audience?.state === "PROJECT_BRIEF_WRITTEN" || audience?.state === "FINDING_RELEVANT_FEATURES") && audience.type === CLUSTER_BASED && audience.configId !== undefined && audience.audienceConfigId !== undefined) {
      history.push(AppUrls.SELECT_FEATURES_TOOL(id, audience.configId, audience.audienceConfigId));
    }
  };

  const openAudienceDetails = (audience) => {
    history.push(AppUrls.AUDIENCE_DETAIL(id, audience.audienceConfigId, audience.id));
  };

  const openClusterDetails = (audience) => {
    history.push(AppUrls.SHOW_CLUSTER_TOOL(id, audience.configId));
  }

  const handleCloseAudienceDetails = () => {
    setIsAudienceDetailsOpen(false);
    setSelectedAudience(null);
  };

  const handleAudienceEdit = (audienceId, configId) => {
    history.push(AppUrls.EDIT_AUDIENCE(id, audienceId, configId));
  };

  const menu = (record, recordId) => (
    <Menu style={{ backgroundColor: '#333', color: '#fff' }}>
      {
        record.type === CLUSTER_BASED && record.state === AUDIENCES_CREATED &&
        <Menu.Item key="details" style={{ backgroundColor: '#333', color: '#fff' }} onClick={() => openClusterDetails(record)}>
          Show Details
        </Menu.Item>
      }
      {
        record.type === CLUSTER_BASED && ['RELEVANT_FEATURES_FOUND', 'CREATING_AUDIENCES', 'PROJECT_BRIEF_WRITTEN', 'FINDING_RELEVANT_FEATURES'].includes(record.state) &&
        <Menu.Item key="edit" style={{ backgroundColor: '#333', color: '#fff' }} onClick={() => handleAudienceClick(record)}>
          Edit
        </Menu.Item>
      }
      {
        record.type === RULE_BASED && record.role !== VIEWER &&
        <Menu.Item key="create" style={{ backgroundColor: '#333', color: '#fff' }} onClick={() => history.push(AppUrls.CREATE_AUDIENCE(id) + `?audience-project-id=${record.id}&audience-project-name=${record.name}`)}>
          Create Audience
        </Menu.Item>
      }
      {
        record.role === 'OWNER' && (
          record.isActive ? (
            <Menu.Item key="archive" style={{ backgroundColor: '#333', color: '#fff' }} onClick={() => handleAudienceArchive(recordId, true)}>
              Archive
            </Menu.Item>
          ) : (
            <Menu.Item key="restore" style={{ backgroundColor: '#333', color: '#fff' }} onClick={() => handleAudienceArchive(recordId, false)}>
              Unarchive
            </Menu.Item>
          )
        )
      }
      {(record.role !== 'OWNER') && (
        (record.isHidden) ? (
          <Menu.Item key="unhide" style={{ backgroundColor: '#333', color: '#fff' }} onClick={() => handleHideAudience(recordId, false)}>
            Unhide
          </Menu.Item>
        ) : (
          <Menu.Item key="hide" style={{ backgroundColor: '#333', color: '#fff' }} onClick={() => handleHideAudience(recordId, true)}>
            Hide
          </Menu.Item>
        ))
      }
      <Menu.Item key="clone" style={{ backgroundColor: '#333', color: '#fff' }} onClick={() => {
        setCloneModal({
          visible: true,
          audienceProjectId: record.id,
          audienceProfileId: null,
          name: record.name,
          type: record.type,
          isAudienceProject: true,
        })
      }}>
        Clone
      </Menu.Item>
      {
        record.role !== VIEWER && (
          <Menu.Item key="users_And_permissions" style={{ backgroundColor: '#333', color: '#fff' }} onClick={() => {
            setUsersAndPermissionDrawer({
              visible: true,
              data: record
            })
          }}>
            Users & Permissions
          </Menu.Item>
        )
      }
      {
        record.type === CLUSTER_BASED && record.state === AUDIENCES_CREATED && (
          <>
            <Menu.Item key="download_model_files" style={{ backgroundColor: '#333', color: '#fff' }} onClick={() => handleClusterReport(record.configId, 'MODEL_FILES')}>
              Download Model Files
            </Menu.Item>
            <Menu.Item key="download_aggregated_feature_report" style={{ backgroundColor: '#333', color: '#fff' }} onClick={() => handleClusterReport(record.configId, 'AGGREGATED_FEATURE_REPORT')}>
              Download Aggregated Feature Report
            </Menu.Item>
          </>
        )
      }
    </Menu>
  );

  const ruleBasedMenu = (record) => (
    <Menu style={{ backgroundColor: '#333', color: '#fff' }}>
      {(record.state !== AUDIENCES_CREATED && record.state !== CREATING_AUDIENCES) && (
        <Menu.Item key="edit" style={{ backgroundColor: '#333', color: '#fff' }} onClick={() => handleAudienceEdit(record.projectId, record.audienceConfigId)}>
          {record.role === VIEWER ? 'View Audience' : 'Edit'}
        </Menu.Item>
      )}
      {(record.state === 'RELEVANT_FEATURES_FOUND' && record.role !== VIEWER) && (
        <Menu.Item key="edit" style={{ backgroundColor: '#333', color: '#fff' }} onClick={() => handleAudienceProfile(record.id)}>
          Profile Audience
        </Menu.Item>
      )}
      {(record.state === AUDIENCES_CREATED) && (
        <Menu.Item key="edit" style={{ backgroundColor: '#333', color: '#fff' }} onClick={() => openAudienceDetails(record)}>
          View
        </Menu.Item>
      )}
      {record.role === 'OWNER' && (
        record.isActive ? (
          <Menu.Item key="archive" style={{ backgroundColor: '#333', color: '#fff' }} onClick={() => handleAudienceProfileArchive(record.id, true)}>
            Archive
          </Menu.Item>
        ) : (
          <Menu.Item key="restore" style={{ backgroundColor: '#333', color: '#fff' }} onClick={() => handleAudienceProfileArchive(record.id, false)}>
            Unarchive
          </Menu.Item>
        ))
      }
      <Menu.Item key="clone" style={{ backgroundColor: '#333', color: '#fff' }} onClick={() => {
        setCloneModal({
          visible: true,
          audienceProjectId: record.projectId,
          audienceProfileId: record.id,
          name: record.name,
          type: record.audienceType,
          isAudienceProject: false,
        })
      }}>
        Clone
      </Menu.Item>
    </Menu>
  );

  const clusterBasedMenu = (record) => (
    <Menu style={{ backgroundColor: '#333', color: '#fff' }}>
      {(record.state === 'DRAFT') && (
        <Menu.Item key="edit" style={{ backgroundColor: '#333', color: '#fff' }} onClick={() => handleAudienceProfile(record.id)}>
          Profile Audience
        </Menu.Item>
      )}
      {(record.state === PROFILED) && (
        <Menu.Item key="edit" style={{ backgroundColor: '#333', color: '#fff' }} onClick={() => openAudienceDetails(record)}>
          View
        </Menu.Item>
      )}
      {record.role === 'OWNER' && (
        record.isActive ? (
          <Menu.Item key="archive" style={{ backgroundColor: '#333', color: '#fff' }} onClick={() => handleAudienceProfileArchive(record.id, true)}>
            Archive
          </Menu.Item>
        ) : (
          <Menu.Item key="restore" style={{ backgroundColor: '#333', color: '#fff' }} onClick={() => handleAudienceProfileArchive(record.id, false)}>
            Unarchive
          </Menu.Item>
        ))
      }
    </Menu>
  );

  const getColumns = () => {
    return [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: 150,
        sorter: true,
        sortOrder: filterRef.current.sortBy === 'name' && !filterRef.current.isAscending ? 'ascend' : filterRef.current.sortBy === 'name' && !filterRef.current.isAscending ? 'descend' : false,
        onHeaderCell: () => ({
          onClick: async () => {
            setLoading(true);
            filterRef.current.isAscending = !filterRef.current.isAscending;
            filterRef.current.sortBy = 'name';
            await refreshData();
            setLoading(false);
          },
        }),
        onCell: (record) => ({
          onClick: () => {
            if (record.projectId !== undefined) {
              if (record.audienceType === RULE_BASED || record.audienceType === CLUSTER_BASED) {
                if (record.state === PROFILED || record.state === AUDIENCES_CREATED) openAudienceDetails(record);
              }
            }
          },
          style: { cursor: 'pointer', height: '100%' },
        }),
        render: (text, record) => record.projectId !== undefined && record.audienceType === CLUSTER_BASED ? record.cluster_name : text,
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        width: 150,
        ellipsis: true,
        onCell: (record) => ({
          onClick: () => {
            if (record.projectId !== undefined) {
              if (record.audienceType === RULE_BASED || record.audienceType === CLUSTER_BASED) {
                if (record.state === PROFILED || record.state === AUDIENCES_CREATED) openAudienceDetails(record);
              }
            }
          },
          style: { cursor: 'pointer' },
        }),
        render: (text, record) => {
          const description = record.projectId === undefined && record.type === RULE_BASED ? "N/A" : record.audienceType === CLUSTER_BASED ? record.cluster_description : text;
          return (
            <Tooltip title={description}>
              {description.length > 25 ? `${description.substring(0, 25)}...` : description}
            </Tooltip>
          );
        },
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        width: 150,
        onCell: (record) => ({
          onClick: () => {
            if (record.projectId !== undefined && record.audienceType === CLUSTER_BASED && record.state === PROFILED) {
              openAudienceDetails(record);
            }
          },
          style: { cursor: 'pointer' },
        }),
        render: (text, record) => record.projectId !== undefined && record.audienceType === RULE_BASED ? removeUnderscores(RULE_BASED) : removeUnderscores(text),
      },
      {
        title: 'Status',
        dataIndex: 'state',
        key: 'state',
        width: 200,
        render: (_, record) => {
          if (record.projectId === undefined && record.type === RULE_BASED) {
            return "N/A";
          } else {
            return (
              <GlobalTag color={AUDIENCE_STATE_COLOR[record.isActive ? record.isHidden ? "HIDDEN" : record.state : 'ARCHIVED']}>
                {removeUnderscores(record.state)}
              </GlobalTag>
            );
          }
        },
        onCell: (record) => ({
          onClick: () => {
            if (record.projectId !== undefined) {
              if (record.state === PROFILED || record.state === AUDIENCES_CREATED) openAudienceDetails(record);
            }
          },
          style: { cursor: 'pointer' },
        }),
      },
      {
        title: 'Audience Size',
        dataIndex: 'audienceSize',
        key: 'audienceSize',
        width: 120,
        render: (_, record) => {
          if (record.projectId !== undefined) {
            return record.audienceSize !== 'N/A'
              ? justFormatNumber(record.audience_size)
              : record.audience_size;
          }
          return "N/A";
        },
        onCell: (record) => ({
          onClick: () => {
            if (record.projectId !== undefined && (record.state === PROFILED || record.state === AUDIENCES_CREATED)) {
              openAudienceDetails(record);
            }
          },
          style: { cursor: 'pointer' },
        }),
      },
      {
        title: 'Role',
        dataIndex: 'role',
        key: 'role',
        width: 100,
        render: (_, record) => record.role,
        onCell: (record) => ({
          onClick: () => {
            if (record.projectId !== undefined && (record.state === PROFILED || record.state === AUDIENCES_CREATED))
              openAudienceDetails(record);
          },
          style: { cursor: 'pointer' },
        }),
      },
      {
        title: 'Created On',
        dataIndex: 'createdOn',
        key: 'createdOn',
        width: 125,
        render: (_, record) => record.createdOn,
        sorter: true,
        sortOrder: filterRef.current.sortBy === 'created_on' && !filterRef.current.isAscending ? 'ascend' : filterRef.current.sortBy === 'created_on' && !filterRef.current.isAscending ? 'descend' : false,
        onHeaderCell: () => ({
          onClick: async () => {
            setLoading(true);
            filterRef.current.isAscending = !filterRef.current.isAscending;
            filterRef.current.sortBy = 'created_on';
            await refreshData();
            setLoading(false);
          },
        }),
        onCell: (record) => ({
          onClick: () => {
            if (record.projectId !== undefined && (record.state === PROFILED || record.state === AUDIENCES_CREATED))
              openAudienceDetails(record);
          },
          style: { cursor: 'pointer' },
        }),
      },
      {
        title: 'Updated On',
        dataIndex: 'updatedOn',
        key: 'updatedOn',
        width: 125,
        render: (_, record) => record.updatedOn,
        sorter: true,
        sortOrder: filterRef.current.sortBy === 'updated_on' && !filterRef.current.isAscending ? 'ascend' : filterRef.current.sortBy === 'updated_on' && !filterRef.current.isAscending ? 'descend' : false,
        onHeaderCell: () => ({
          onClick: async () => {
            setLoading(true);
            filterRef.current.isAscending = !filterRef.current.isAscending;
            filterRef.current.sortBy = 'updated_on';
            await refreshData();
            setLoading(false);
          },
        }),
        onCell: (record) => ({
          onClick: () => {
            if (record.projectId !== undefined && (record.state === PROFILED || record.state === AUDIENCES_CREATED))
              openAudienceDetails(record);
          },
          style: { cursor: 'pointer' },
        }),
      },
      {
        title: 'Created By',
        dataIndex: 'createdBy',
        key: 'createdBy',
        width: 125,
        render: (_, record) => record.createdBy,
        onCell: (record) => ({
          onClick: () => {
            if (record.projectId !== undefined && (record.state === PROFILED || record.state === AUDIENCES_CREATED))
              openAudienceDetails(record);
          },
          style: { cursor: 'pointer' },
        }),
      },
      {
        title: 'Actions',
        dataIndex: 'actions',
        key: 'actions',
        width: 80,
        render: (_, record) => {
          const menuOverlay =
            record.projectId === undefined
              ? menu(record, record.id)
              : record.audienceType === 'RULE_BASED'
                ? ruleBasedMenu(record)
                : clusterBasedMenu(record);
          return (
            <Dropdown overlay={menuOverlay} trigger={['click']}>
              <MenuUnfoldOutlined style={{ color: '#007bff', cursor: 'pointer' }} onClick={(e) => e.stopPropagation()} />
            </Dropdown>
          );
        },
      },
    ];
  };

  const convertAudienceData = (data) => {
    return data
      .map((item, index) => {
        // Helper to create audience profiles for RULE_BASED type
        const createRuleBasedProfiles = (item) =>
          item.audience_config.map((config) => ({
            ...config.audience_profiles[0],
            name: config.name,
            state: config.state,
            audienceType: config.audience_type,
            description: config.description,
            projectId: item.id,
            isActive: config.is_active,
            createdOn: moment(config.created_on).format("MMMM D, YYYY"),
            updatedOn: moment(config.updated_on).format("MMMM D, YYYY"),
            createdBy: `${item.created_by.first_name} ${item.created_by.last_name}`,
            role: item.role,
            audienceConfigId: config.id,

          }));

        // Helper to create audience profiles for CLUSTER_BASED type
        const createClusterBasedProfiles = (config) =>
          config.audience_profiles.map((profile) => ({
            ...profile,
            type: config.type_based_config?.clusters_type,
            projectId: item.id,
            isActive: profile.is_active,
            audienceType: config.audience_type,
            createdOn: moment(item.created_on).format("MMMM D, YYYY"),
            updatedOn: moment(item.updated_on).format("MMMM D, YYYY"),
            createdBy: `${item.created_by.first_name} ${item.created_by.last_name}`,
            role: item.role,
            audienceConfigId: config.id,

          }));


        const audienceProfiles =
          item.audience_config[0].audience_type === RULE_BASED
            ? createRuleBasedProfiles(item)
            : createClusterBasedProfiles(item.audience_config[0]);

        return {
          key: (index + 1).toString(),
          id: item.id,
          name: item.name,
          description: item.audience_config[0].description,
          type: item.audience_config[0].audience_type,
          jsonData: item.audience_config[0].json_data,
          createdOn: moment(item.created_on).format("MMMM D, YYYY"),
          updatedOn: moment(item.updated_on).format("MMMM D, YYYY"),
          state: item.audience_config[0].state,
          audienceSize:
            item.audience_config[0].audience_type === RULE_BASED &&
              item.audience_config[0]?.audience_profiles[0]?.audience_size != null
              ? item.audience_config[0]?.audience_profiles[0]?.audience_size
              : "N/A",
          createdBy: `${item.created_by.first_name} ${item.created_by.last_name}`,
          isActive: item.is_active,
          isHidden: item.is_hidden,
          audienceProfiles: audienceProfiles,
          configId:
            item.audience_config[0].audience_type === CLUSTER_BASED
              ? item.audience_config[0]?.type_based_config?.id
              : undefined,
          audienceConfigId: item.audience_config[0]?.id,
          role: item.role,
        };

      })
      .flat();
  };


  const combineInternalAndExternalUsers = (list) => {
    const internalUsers = list.data.internal || [];
    const externalUsers = list.data.external || [];
    return [...internalUsers, ...externalUsers];
  };

  const fetchData = async () => {
    try {
      const { currentPage, searchName, filterType, sortBy, isAscending, statusFilter } = filterRef.current;
      const res = await audienceListService.getAudienceList(id, currentPage, 10, searchName, filterType, sortBy, isAscending, statusFilter);
      const list = await CreateClientApiServices.getUsersByClient(id);
      const combinedUsers = combineInternalAndExternalUsers(list);
      setUserLists(combinedUsers);
      setAudienceListTotal(res.data.total);
      setAudienceListData(convertAudienceData(res.data.data));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const handleAudienceArchive = async (audienceId, action) => {
    try {
      await audienceListService.archiveAudience(audienceId, action);
      notification.success({
        message: `Audience ${action ? 'archived' : 'restored'} successfully`,
        duration: 6,
        placement: "bottomRight"
      });
      setLoading(true);
      fetchData();
    } catch (error) {
      const { name, details, msg } = getErrorMessageFromResponse(error);
      const errorMessage = details?.error || msg || `Failed to ${action ? 'archive' : 'restore'} audience`;
      notification.error({
        key: details?.resource_name || "Error",
        message: errorMessage,
        duration: 5,
        placement: "bottomRight",
      });
    }
  };

  const handleAudienceProfileArchive = async (audienceId, action) => {
    try {
      await audienceListService.archiveAudienceProfile(audienceId, action);
      notification.success({
        message: `Audience ${action ? 'archived' : 'restored'} successfully`,
        duration: 6,
        placement: "bottomRight"
      });
      setLoading(true);
      fetchData();
    } catch (error) {
      const { name, details, msg } = getErrorMessageFromResponse(error);
      const errorMessage = details?.error || msg || `Failed to ${action ? 'archive' : 'restore'} audience`;
      notification.error({
        key: details?.resource_name || "Error",
        message: errorMessage,
        duration: 5,
        placement: "bottomRight",
      });
    }
  };

  const handleHideAudience = async (audienceId, action) => {
    try {
      await audienceListService.hideAudience(audienceId, action);
      notification.success({
        message: `Audience ${action ? 'hidden' : 'restored'} successfully`,
        duration: 6,
        placement: "bottomRight"
      });
      setLoading(true);
      fetchData();
    } catch (error) {
      const { name, details, msg } = getErrorMessageFromResponse(error);
      const errorMessage = details?.error || msg || `Failed to ${action ? 'hide' : 'restore'} audience`;
      notification.error({
        key: details?.resource_name || "Error",
        message: errorMessage,
        duration: 5,
        placement: "bottomRight",
      });
    }
  };


  const handleAudienceProfile = async (audienceProfileId) => {
    try {
      setLoading(true);
      const response = await audienceListService.profileAudience(audienceProfileId);
      notification.success({
        message: `Audience Profiling initiated successfully`,
        duration: 6,
        placement: "bottomRight"
      });
      fetchData();
    } catch (error) {
      setLoading(false);
      const { name, details, msg } = getErrorMessageFromResponse(error);
      const errorMessage = details?.error || msg || `Failed to Profile audience`;
      notification.error({
        key: details?.resource_name || "Error",
        message: errorMessage,
        duration: 5,
        placement: "bottomRight",
      });
    }
  }

  const handleClusterReport = async (clusterId, reportType) => {
    try {
      setLoading(true);
      const payload = {
        clusters_config_id: clusterId,
        report_type: reportType,
      };
      const res = await ClusterToolApiServices.dowloadReports(payload);
      const anchor = document.createElement("a");
      anchor.href = res.data;
      anchor.style.display = "none";
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: "bottomRight",
      });
    }
  }

  const refreshData = async () => {
    try {
      const { currentPage, searchName, filterType, sortBy, isAscending, statusFilter } = filterRef.current;
      const res = await audienceListService.getAudienceList(id, currentPage, 10, searchName, filterType, sortBy, isAscending, statusFilter);
      setAudienceListTotal(res.data.total);
      setAudienceListData(convertAudienceData(res.data.data));
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, []);

  useEffect(() => {
    timeRef.current = window.setInterval(async () => {
      if (!loading) {
        await refreshData();
      }
    }, 10000);
    return () => {
      if (timeRef.current) {
        window.clearInterval(timeRef.current);
      }
    };
  }, [loading]);

  const setCurrentPage = async (page) => {
    setLoading(true);
    filterRef.current.currentPage = page;
    await refreshData();
    setLoading(false);
  };

  const setSearchName = async (name) => {
    setLoading(true);
    filterRef.current.searchName = name;
    await refreshData();
    setLoading(false);
  };

  const setFilterType = async (type) => {
    setLoading(true);
    filterRef.current.filterType = type;
    await refreshData();
    setLoading(false);
  };

  const setSortBy = async (sort) => {
    setLoading(true);
    filterRef.current.sortBy = sort;
    await refreshData();
    setLoading(false);
  };

  const setIsAscending = async (ascending) => {
    setLoading(true);
    filterRef.current.isAscending = ascending;
    await refreshData();
    setLoading(false);
  };

  const setStatusFilter = async (status) => {
    setLoading(true);
    filterRef.current.statusFilter = status;
    await refreshData();
    setLoading(false);
  };

  const handleClone = async () => {
    setLoading(true);
    await refreshData();
    setLoading(false);
  }

  const handleClusterClone = (typeConfigId, configId) => {
    history.push(AppUrls.CLUSTER_CONFIG_FEATURES_TOOL(id, typeConfigId, configId));
  }

  return {
    loading,
    audienceListData,
    audienceListTotal,
    currentPage: filterRef.current.currentPage,
    setCurrentPage,
    isAudienceDetailsOpen,
    handleCloseAudienceDetails,
    selectedAudience,
    searchName: filterRef.current.searchName,
    setSearchName,
    filterType: filterRef.current.filterType,
    setFilterType,
    sortBy: filterRef.current.sortBy,
    setSortBy,
    debouncedSearch,
    isAscending: filterRef.current.isAscending,
    setIsAscending,
    handleAudienceClick,
    handleAudienceEdit,
    handleAudienceArchive,
    statusFilter: filterRef.current.statusFilter,
    setStatusFilter,
    openClusterDetails,
    cloneModal,
    setCloneModal,
    closeCloneModal,
    handleClone,
    closeUsersAndPermissionsDrawer,
    usersAndPermissionsDrawer,
    expandedRowKeys,
    getColumns,
    userLists,
    handleClusterClone
  };
}