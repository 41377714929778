/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/sort-comp */
/* eslint-disable class-methods-use-this */
import axios from "axios";
import React from "react";
import Cookie from "src/lib/cookie";
import { getErrorMessageFromResponse, getExpiryDateFromToken, isTokenValid } from "src/lib/utils";
import ApiUrls from "src/constants/apiUrls";
import AppUrls from "src/constants/appUrls";
import { message } from "antd";

const AppContext = React.createContext({});
AppContext.displayName = "AppContext";

class AppContextClass extends React.Component {
  /**
   *
   * @param {Object} props
   */
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      userData: null,
      isLoggedIn: null,
      metaData: null,
      count: 1,
      clientSpaceData: null,
    };
  }

  clearTokenFromStorage = () => Cookie.remove("token");

  clearDisclaimerCookieFromStorage = () => Cookie.remove('Disclaimer');

  setTokenToStorage = (token, shouldRemember = false) => {
    if (token && isTokenValid(token)) {
      Cookie.set("token", token, shouldRemember ? getExpiryDateFromToken(token) : undefined);
    } else {
      Cookie.remove("token");
    }
  };

  logout = async () => {
    try {
      this.setState({ isLoading: true });
      await axios.get(ApiUrls.LOGOUT, {
        headers: {
          Authorization: Cookie.get("token"),
        },
      });
      this.clearTokenFromStorage();
      this.clearDisclaimerCookieFromStorage();
      this.checkIsLoggedIn();
      this.setState({
        isLoading: false,
        userData: null,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
        userData: null,
      });
    }
  };

  checkIsLoggedIn = async () => {
    if (!Cookie.get("token")) {
      this.setState({ isLoggedIn: false });
    }
    if (!isTokenValid(Cookie.get("token"))) {
      this.setState({ isLoggedIn: false });
    } else {
      this.setState({ isLoggedIn: true });
    }
  };

  loginUserWithToken = async (token, shouldRememberUser = true, temporaryToken = "") => {
    try {
      this.setState({ isLoading: true, count: 1 });
      this.setTokenToStorage(token, shouldRememberUser);
      const res = await this.getMetaData();
      this.checkIsLoggedIn();
      this.setState({ isLoading: false, metaData: res });
      // Step 3
    } catch (ex) {
      this.logout();
    }
  };

  loginUserWithUserData = (userData, shouldRememberUser = true, temporaryToken = "") => {
    this.loginUserWithToken(userData.token, shouldRememberUser, userData.temporary_token);
  };

  loginWithOkta = async (token) => {
    Cookie.set("oktaLogin", "true");
    await this.loginUserWithToken(token);
  };

  getMetaData = async () => {
    try {
      const res = await axios.get(ApiUrls.META_DATA, {
        headers: {
          Authorization: Cookie.get("token"),
          "Cache-Control": "no-cache",
        },
      });
      return res?.data?.data;
    } catch (error) {
      const { msg, details, status } = getErrorMessageFromResponse(error);
      if (status === 403) {
        Cookie.remove("token");
        window.location.href = AppUrls.AUTH_LOGIN;
      } else {
        let count = this.state.count;
        if (count < 3) {
          this.setState({
            count: count + 1,
          });
          await this.getMetaData();
        } else {
          message.error(msg || "Failed to fetch the AI Apps please try after some time.");
          this.setState({
            count: 1,
          });
        }
      }
    }
  };

  updateMetaData = async () => {
    try {
      const res = await this.getMetaData();
      this.setState({ metaData: res });
    } catch (error) {
      
    }
  }

  updateClientSpaceData = (val) => {
    this.setState({
      clientSpaceData : val
    })
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    if (Cookie.get("token")) {
      const res = await this.getMetaData();
      this.setState({ metaData: res });
    }
    this.checkIsLoggedIn();
    this.setState({ isLoading: false });
  }

  render() {
    const { children } = this.props;

    const contextVal = {
      ...this.state,
      checkIsLoggedIn: this.checkIsLoggedIn,
      setTokenToStorage: this.setTokenToStorage,
      clearTokenFromStorage: this.clearTokenFromStorage,
      clearDisclaimerCookieFromStorage: this.clearDisclaimerCookieFromStorage,
      logout: this.logout,
      loginUserWithToken: this.loginUserWithToken,
      loginUserWithUserData: this.loginUserWithUserData,
      loginWithOkta: this.loginWithOkta,
      getMetaData: this.getMetaData,
      updateMetaData: this.updateMetaData,
      updateClientSpaceData: this.updateClientSpaceData,
    };

    return (
      <AppContext.Provider value={contextVal}>
        {children && children.constructor === Function.constructor ? children(contextVal) : children}
      </AppContext.Provider>
    );
  }
}
/**
 * AppContext
 * @class
 * @property {Object} Context Context instance
 * @property {Object} Consumer Context Consumer
 *
 */

AppContextClass.Context = AppContext;
AppContextClass.Consumer = AppContext.Consumer;

export default AppContextClass;
