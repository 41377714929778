import { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom";
import { Form, notification } from "antd";
import moment from "moment";
import AppUrls from "src/constants/appUrls";
import { getErrorMessageFromResponse } from "src/lib/utils";
import mediaPlanService from "../../../services/mediaPlanService";
import audienceListService from "../../../services/audienceListService";

const useCreateMediaPlan = () => {
  const history = useHistory();
  const { clientId, mediaPlanId } = useParams();
  const [mediaPlanData, setMediaPlanData] = useState(null);
  const { state } = useLocation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [metaData, setMetaData] = useState({});
  const [configureLoading, setConfigureLoading] = useState(false);
  const [audienceOptions, setAudienceOptions] = useState([]);

  const filterRef = {
    currentPage: 1,
    searchName: "",
    filterType: "",
    sortBy: "created_on",
    isAscending: false,
    statusFilter: "",
  };

  const createMediaplanRecommendations = async (paylod) => {
    try {
      setConfigureLoading(true);
      const res = await mediaPlanService.createMediaplanRecommendation(
        paylod
      );
      history.push(AppUrls.MEDIA_PLAN_AUDIENCE_INFO(clientId, res.data.id));
      setConfigureLoading(false);
    } catch (error) {
      setConfigureLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        message: msg,
        duration: 5,
        placement: "bottomRight",
      });
    }
  };

  const getMetaData = async () => {
    try {
      const res = await mediaPlanService.getMetaData(clientId);
      setMetaData(res?.data);
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        message: msg,
        duration: 5,
        placement: "bottomRight",
      });
    }
  };

  const onFinish = (values) => {
    const audiences = values.audience?.map((audience) => {
      return { 
        audience_id: audience,
        start_date: values.dateRange
        ? moment(values.dateRange[0]).format("YYYY-MM-DD")
        : null,
      end_date: values.dateRange
        ? moment(values.dateRange[1]).format("YYYY-MM-DD")
        : null,
        budget: (values.budget/values.audience?.length).toFixed(0),
        json_data: {
          goals: values.goals,
          channels: values.channel,
        }
      };
    });
    const transformedValues = {
      client_space_id: clientId,
      name: values.name,
      budget: values.budget,
      audience_mapping: audiences,
      json_data: {
        channels: values.channel,
        brand: values.brand,
        category: values.category,
        kpis: values.kpis,
        goals: values.goals,
      },
      start_date: values.dateRange
        ? moment(values.dateRange[0]).format("YYYY-MM-DD")
        : null,
      end_date: values.dateRange
        ? moment(values.dateRange[1]).format("YYYY-MM-DD")
        : null,
    };
    createMediaplanRecommendations(transformedValues);
  };

  const getAudience = async () => {
    try {
      const res = await audienceListService.getAudienceList(
        clientId,
        filterRef.currentPage,
        10,
        filterRef.searchName,
        filterRef.filterType,
        filterRef.sortBy,
        filterRef.isAscending,
        filterRef.statusFilter
      );
      await getMetaData();
      setAudienceOptions(
        res.data.data?.map((audience) => ({
          label: audience.name,
          value: audience.audience_config?.[0].id,
        }))
      );
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        message: msg,
        duration: 5,
        placement: "bottomRight",
      });
    }
  };

  const getMediaPlan = async () => {
    try {
      const res = await mediaPlanService.getMediaPlanBasicInfo(mediaPlanId);
      setMediaPlanData(res.data);
      const audiences  = res?.data?.audience_mapping?.audience_mapping?.map((audience) => audience.audience_id);
      const transformedValues = {
        name: res.data.name,
        budget: res.data.budget,
        audience: audiences,
        dateRange: [moment(res.data.start_date), moment(res.data.end_date)],
        goals: res.data.json_data.goals,
        channel: res.data.json_data.channels,
        brand: res.data.json_data.brand,
        category: res.data.json_data.category,
        kpis: res.data.json_data.kpis,
      };
      form.setFieldsValue(transformedValues);
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        message: msg,
        duration: 5,
        placement: "bottomRight",
      });
    }
  }

  const fetchData = async () => {
    setLoading(true);
    await getAudience();
    if (mediaPlanId) {
      await getMediaPlan();
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchData();
    getAudience();
  }, []);

  return {
    configureLoading,
    loading,
    form,
    onFinish,
    state,
    history,
    audienceOptions,
    metaData,
    mediaPlanData,
    mediaPlanId,
    clientId
  };
};

export default useCreateMediaPlan;
