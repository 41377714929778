import { notification } from "antd";
import { useEffect, useRef, useState } from "react";
import AppUrls from "src/constants/appUrls";
import { getErrorMessageFromResponse } from "src/lib/utils";
import { ClusterToolApiServices } from "../../services/ClusterToolApiServices";
import { useLocation } from "react-router-dom";

export default function useSelectFeaturesHook({ history, match }) {
  const clusterId = match?.params?.clusterId;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const audienceConfigId = queryParams.get('audience-config-id');
  const clientSpaceId = match?.params?.clientId;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const formRef = useRef(null);
  const [initialValues, setInitialValues] = useState({ features: [], add_e1_features: false });
  const [configureLoading, setConfigureLoading] = useState(false);
  const [featureByCategory, setFeatureByCategory] = useState({});
  const [categorySelected, setCategorySelected] = useState({});
  const [featureData, setFeatureData] = useState({});
  const [viewMode, setViewMode] = useState(false);
  const [savedChanges, setSavedChanges] = useState(false);
  const [audienceRole, setAudienceRole] = useState(null);

  const getData = async () => {
    try {
      setLoading(true);
      const res = await ClusterToolApiServices.getClusterConfig(clusterId);
      if (res?.data?.state === "FINDING_RELEVANT_FEATURES" || res?.data?.state === "CREATING_AUDIENCES") {
        setViewMode(true);
      }
      let obj = {};
      let featureObj = {};
      res.data.features.forEach((item) => {
        featureObj[item.id] = item;
        if (obj[item?.category]) {
          obj[item?.category].push(item);
        } else {
          obj[item?.category] = [item];
        }
      });
      setFeatureData(featureObj);
      let categoryObj = {};
      Object.keys(obj).forEach((item) => {
        categoryObj[item] = true;
      });
      setCategorySelected(categoryObj);
      setFeatureByCategory(obj);
      let tempInitialValues = {};
      if (res.data?.state === "PROJECT_BRIEF_WRITTEN") {
        Object.keys(obj).forEach((item) => {
          tempInitialValues[item] = obj[item].map((feature) => {
            return feature.id;
          });
        });
      } else {
        Object.keys(obj).forEach((item) => {
          let tempArr = [];
          obj[item].forEach((feature) => {
            if (featureObj[feature.id]?.is_selected) {
              tempArr.push(feature.id);
            }
          })
          tempInitialValues[item] = tempArr
        });
      }
      setInitialValues({ add_e1_features: res?.data?.add_e1_features, ...tempInitialValues });
      setAudienceRole(res.data.role);
      setData(res.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: "bottomRight",
      });
    }
  };

  const updateCluster = async (val) => {
    try {
      setConfigureLoading(true);
      const payload = {
        audience_config_id: audienceConfigId,
        add_e1_features: val.add_e1_features,
        features: val?.features,
        trigger_feature_selection: true,
      };
      const res = await ClusterToolApiServices.createClusterConfig(payload);
      setConfigureLoading(false);
      if (match.path.includes('audience-tool')) {
        history.push(AppUrls.AUDIENCE_TOOL(clientSpaceId));
      } else {
        history.push(AppUrls.CLUSTER_TOOL(clientSpaceId));
      }
    } catch (error) {
      setConfigureLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: "bottomRight",
      });
    }
  };

  const selectAllFeatures = (getFieldValue, setFieldsValue, checked) => {
    setSavedChanges(true);
    let obj = {};
    if (checked) {
      setCategorySelected({ ...categorySelected, ...Object.keys(featureByCategory).reduce((acc, item) => ({ ...acc, [item]: true }), {}) });
      Object.keys(featureByCategory).forEach((item) => {
        obj[item] = featureByCategory[item].map((feature) => {
          return feature.id;
        });
      });
    } else {
      setCategorySelected({ ...categorySelected, ...Object.keys(featureByCategory).reduce((acc, item) => ({ ...acc, [item]: false }), {}) });
      Object.keys(featureByCategory).forEach((item) => {
        obj[item] = [];
      });
    }
    setFieldsValue({ ...obj });
  };

  const selectSpeceficFeatures = (getFieldValue, setFieldsValue, checked, category) => {
    let obj = {};
    setSavedChanges(true);
    if (checked) {
      setCategorySelected({ ...categorySelected, [category]: true });
      obj[category] = featureByCategory[category].map((feature) => {
        return feature.id;
      });
    } else {
      setCategorySelected({ ...categorySelected, [category]: false });
      obj[category] = [];
    }
    setFieldsValue({ ...obj });
  };

  useEffect(() => {
    getData();
  }, []);

  return {
    loading,
    data,
    initialValues,
    formRef,
    selectAllFeatures,
    updateCluster,
    configureLoading,
    featureByCategory,
    categorySelected,
    selectSpeceficFeatures,
    featureData,
    clusterId,
    clientSpaceId,
    viewMode,
    savedChanges,
    setSavedChanges,
    audienceConfigId,
    audienceRole
  };
}
