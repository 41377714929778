import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import { getErrorMessageFromResponse, numberWithCommas } from "src/lib/utils";
import { notification } from "antd";
import { set } from "dot-object";
import appUrls from "src/constants/appUrls";
import mediaPlanService from "../../../services/mediaPlanService";

const colors = [
  "#5470C6",
  "#91CC75",
  "#FAC858",
  "#EE6666",
  "#73C0DE",
  "#3BA272",
  "#FC8452",
  "#9A60B4",
  "#EA7CCC",
  "#3EAF7C",
  "#E57373",
  "#81C784",
  "#64B5F6",
  "#FFB74D",
  "#4DB6AC",
  "#BA68C8",
  "#7986CB",
  "#F06292",
  "#FFD54F",
  "#4FC3F7",
];

export default function useMediaplanOutput() {
  const [loading, setLoading] = useState(true);
  const [gantChartData, setGantChartData] = useState([]);
  const { clientId, mediaPlanId } = useParams();
  const [chhanelDataOptions, setChannelDataOption] = useState({});
  const [audienceSpend, setAudienceSpend] = useState({});
  const [funnelStage, setFunnelStage] = useState({});
  const [spendByWeek, setSpendByWeek] = useState({});
  const responseId = mediaPlanId;
  const history = useHistory();

  const goBackToScenario = () => {
    history.push(
      appUrls.MEDIA_PLAN_SCENARIO_PLANNING_SCREEN(clientId, responseId)
    );
  };

  const funnelOption = (data) => {
    let option = {
      xAxis: [
        {
          type: "category",
          data: data.map((item) => item.business_goal),
          axisLabel: {
            interval: 0,
            rotate: 30,
            hideOverlap: true,
            formatter: (value) => {
              const len = value.length;
              const ellipsis = len > 17 ? "..." : "";
              if (len > 17) {
                return value.slice(0, 4) + ellipsis + value.slice(-14);
              }
              return value;
            },
            textStyle: {
              color: "#ffffff",
            },
          },
          splitLine: {
            lineStyle: {
              color: "#ff00ff",
              type: "dashed",
            },
          },
        },
      ],
      grid: {
        top: "15%",
      },
      tooltip: {
        trigger: "item",
        formatter: function (params) {
          return `<b>${params?.name}</b>: $${numberWithCommas(params.value)}`;
        },
      },
      yAxis: {
        type: "value",
        axisLabel: {
            color: '#ffffff',
            formatter: function (params) {
                return `$${numberWithCommas(params)}`;
            }
          },
      },
      color: colors,
      series: data.map((item, idx) => {
        return {
          name: item?.cluster_name,
          type: "bar",
          stack: "stack",
          data: data.map((item, index) => {
            if (index === idx) {
              return item?.business_goal_value?.[0]?.budget;
            } else {
              return null;
            }
          }),
        };
      }),
    };
    return option;
  };

  const audienceOption = (data) => {
    let option = {
      xAxis: [
        {
          type: "category",
          data: data.map((item) => item.audience_name),
          axisLabel: {
            interval: 0,
            rotate: 30,
            hideOverlap: true,
            formatter: (value) => {
              const len = value.length;
              const ellipsis = len > 17 ? "..." : "";
              if (len > 17) {
                return value.slice(0, 4) + ellipsis + value.slice(-14);
              }
              return value;
            },
            textStyle: {
              color: "#ffffff",
            },
          },
          splitLine: {
            lineStyle: {
              color: "#ff00ff",
              type: "dashed",
            },
          },
        },
      ],
      grid: {
        top: "15%",
      },
      tooltip: {
        trigger: "item",
        formatter: function (params) {
          return `<b>${params?.name}</b>: $${numberWithCommas(params.value)}`;
        },
      },
      yAxis: {
        type: "value",
        axisLabel: {
            color: '#ffffff',
            formatter: function (params) {
                return `$${numberWithCommas(params)}`;
            }
          },
      },
      color: colors,
      series: data.map((item, idx) => {
        return {
          name: item?.audience_name,
          type: "bar",
          stack: "stack",
          data: data.map((item, index) => {
            if (index === idx) {
              return item?.audience_name_value?.[0]?.budget;
            } else {
              return null;
            }
          }),
        };
      }),
    };
    return option;
  };

  const getAudienceSpendData = async () => {
    try {
      const payload = {
        response_id: responseId,
        group_by: ["audience_name"],
      };
      const res = await mediaPlanService.getMediaPlanOutput(payload);
      const formmatedData = audienceOption(res.data);
      setAudienceSpend(formmatedData);
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        message: msg,
        duration: 5,
        placement: "bottomRight",
      });
    }
  };

  const getFunnelSpendData = async () => {
    try {
      let payload = {
        response_id: responseId,
        group_by: ["business_goal"],
      };
      const res = await mediaPlanService.getMediaPlanOutput(payload);
      const formmatedData = funnelOption(res.data);
      setFunnelStage(formmatedData);
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        message: msg,
        duration: 5,
        placement: "bottomRight",
      });
    }
  };

  const chhanelOption = (data) => {
    let obj = {};
    if (data) {
      obj = {
        tooltip: {
          trigger: "item",
          formatter: (params) => {
            const { name, percent } = params;
            return `
                  <div style="text-align: left;">
                    <strong>${name}</strong>: $${numberWithCommas(
              params.data.value
            )}
                  </div>
                `;
          },
        },
        series: [
          {
            type: "pie",
            color: colors,
            radius: "50%",
            data: data?.map((item) => {
              return {
                value: item?.channel_value?.[0]?.budget,
                name: item?.channel,
              };
            }),
            label: {
              color: "white",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "14",
                fontWeight: "bold",
                textStyle: {
                  color: "#ffffff",
                },
              },
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
    }
    return obj;
  };

  const chhanelSpend = async () => {
    try {
      const payload = {
        response_id: responseId,
        group_by: ["channel"],
      };
      const res = await mediaPlanService.getMediaPlanOutput(payload);
      const formmatedData = chhanelOption(res.data);
      setChannelDataOption(formmatedData);
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        message: msg,
        duration: 5,
        placement: "bottomRight",
      });
    }
  };

  const spendByWeekOption = (data) => {
    const weekNumbers = data.map((item) => `Week ${item.week_number}`);
    const businessGoals = [
      ...new Set(
        data.flatMap((item) =>
          item.week_number_value.map((goal) => goal.business_goal)
        )
      ),
    ];
    const seriesData = businessGoals.map((goal) => ({
      name: goal,
      type: "bar",
      stack: "total",
      emphasis: { focus: "series" },
      data: Array(weekNumbers.length).fill(0), // Initialize with zeros
    }));

    data.forEach((weekData, weekIndex) => {
      weekData.week_number_value.forEach((goalData) => {
        const goalIndex = businessGoals.indexOf(goalData.business_goal);
        if (goalIndex !== -1) {
          // Assuming we want to stack by 'budget', you can change this to any other metric
          seriesData[goalIndex].data[weekIndex] =
            goalData.business_goal_value[0].budget;
        }
      });
    });
    let option = {
      tooltip: {
        trigger: "axis",
        formatter: function (params) {
            let res = `${params[0].name}<br/>`;
            params.forEach((param) => {
              res += `${param.marker} ${param.seriesName}: $${numberWithCommas(
                param.value
              )}<br/>`;
            });
            return res;
        },
      },
      xAxis: {
        type: "category",
        data: weekNumbers,
        axisLabel: {
          color: "#ffffff",
        }
      },
      yAxis: {
        type: "value",
        axisLabel: {
            color: '#ffffff',
            formatter: function (params) {
                return `$${numberWithCommas(params)}`;
            }
          },
      },
      series: seriesData,
      colors: colors,
    };

    return option;
  };

  const getSpendByWeek = async () => {
    try {
      const payload = {
        response_id: responseId,
        group_by: ["week_number", "business_goal"],
      };
      const res = await mediaPlanService.getMediaPlanOutput(payload);
      const formmatedData = spendByWeekOption(res.data);
      setSpendByWeek(formmatedData);
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        message: msg,
        duration: 5,
        placement: "bottomRight",
      });
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await mediaPlanService.getMediaPlanFlightingResponse(
        responseId
      );
      await chhanelSpend();
      await getAudienceSpendData();
      await getFunnelSpendData();
      await getSpendByWeek();
      setGantChartData(res.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        message: msg,
        duration: 5,
        placement: "bottomRight",
      });

    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return {
    loading,
    gantChartData,
    chhanelDataOptions,
    audienceSpend,
    funnelStage,
    spendByWeek,
    goBackToScenario,
  };
}