import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import formatMediaData from "../../../constants/constants";
import { Tag } from "antd";
import { formatString } from "src/lib/utils";
import AppUrls from "src/constants/appUrls";
import { TagsFilled } from "@ant-design/icons";
import GlobalTag from "src/components/globalTag/GlobalTag";
import { NEW_CAMPAIGN_STATUS_BACKGROUND_COLOR } from "src/components/globalTag/globalTagConstant";
import mediaPlanService from "../../../services/mediaPlanService";

export default function useMediaPlanListing() {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [mediaData, setMediaData] = useState({
    data: [],
    current_page: 1,
    total: 0,
    per_page: 10,
    total_pages: 0,
  });
  const history = useHistory();

  const { clientId } = useParams();

  // Fetch media plans based on updated payload
  const getMediaPlan = async (page) => {
    try {
      setLoading(true);
      const res = await mediaPlanService.getMediaPlanList(clientId, page);
      const formattedData = formatMediaData(res);
      setMediaData({
        data: formattedData,
        current_page: res.data.current_page,
        total: res.data.total,
        per_page: res.data.per_page,
        total_pages: res.data.total_pages,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching media plans:", error);
    }
  };

  const handleMediaPlanClick = (state, mediaPlanId, responseId) => {
    if (state === "DRAFT") {
      history.push(AppUrls.MEDIA_PLAN_AUDIENCE_INFO(clientId, mediaPlanId));
    } else if (state === "CREATED") {
      history.push(
        AppUrls.MEDIA_PLAN_SCENARIO_PLANNING_SCREEN(clientId, responseId)
      );
    }
  };

  const column_media_listing = (
    openActionRow,
    toggleActionMenu,
    closeActionMenu
  ) => [
    {
      title: "Name",
      key: "name",
      render: (_, data) => (
        <span
          onClick={() => {
            handleMediaPlanClick(data?.state, data?.id, data?.response_id);
          }}
          style={{ cursor: "pointer" }}
        >
          {data?.name}
        </span>
      ),
    },
    {
      title: "Budget",
      dataIndex: "budget",
      key: "budget",
      render: (budget) =>
        `${budget.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })}`,
    },
    {
      title: "Status",
      dataIndex: "state",
      key: "state",
      render: (state) => {
        return (
          <GlobalTag color={NEW_CAMPAIGN_STATUS_BACKGROUND_COLOR[state]}>
            {formatString(state)}
          </GlobalTag>
        );
      },
    },
    {
      title: "Goals",
      width: 250,
      key: 'goals',
      render: (_, record) => record.json_data?.goals?.join(", "),
    },
    {
      title: "Audience",
      key: 'audience',
      width: 250,
      dataIndex: 'audience_mapping',
      render: (audience) => {
        let newAudience = audience?.map((ele, idx) => ele.audience_name);
        return newAudience?.join(", ");
      }
    },
    {
      title: "Channels",
      key: "channels",
      width: 250,
      render: (_, record) => {
        let channelArr = record.json_data?.channels?.map((ele) =>ele)
        return channelArr?.join(", ");
      }
    },
  ];

  useEffect(() => {
    getMediaPlan(page);
  }, [page]); // Only run this effect once when the component mounts

  // Handle pagination changes
  const onPaginationChange = (page) => {
    setPage(page);
  };

  return {
    mediaData,
    page,
    loading,
    onPaginationChange,
    history,
    clientId,
    column_media_listing,
  };
}
