import { useState, useEffect, useContext } from 'react';
import { getErrorMessageFromResponse } from 'src/lib/utils';
import { notification } from 'antd';
import audienceListService from '../../services/audienceListService';
import AppContext from 'src/context/appContext';

const useAudienceUsersAndPermissionsHook = ({ data }) => {
  const appContext = useContext(AppContext.Context);
  const userEmail = appContext?.metaData?.email;
  const [addUserModal, setAddUserModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [configureLoading, setConfigureLoading] = useState(false);
  const [userData, setUserData] = useState({
    users: [],
    enabledForAll: false,
  })

  const formatData = (res) => {
    let users = res?.data?.user_roles;
      let filteredUsers = [];
      const ownerEmail = users?.find(item => item?.role === 'OWNER');
      const ownerAdmin = users?.find(item => item?.user?.email === userEmail);
      if (ownerEmail?.user?.email === ownerAdmin?.user?.email) {
        let tempArr = users?.filter(item => item?.role !== 'OWNER');
        filteredUsers = [ownerEmail, ...tempArr];  
      } else {
        let tempArr = users?.filter(item => item?.role !== 'OWNER');
        let arr = tempArr.filter(item => item?.user?.email !== userEmail);
        filteredUsers = [ownerAdmin, ownerEmail, ...arr];
      }
      setUserData({
        enabledForAll: res.data?.enabled_for_all,
        users: filteredUsers,
      })
  }

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await audienceListService.getAudienceUsers(data.id);
      formatData(res);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const {msg} = getErrorMessageFromResponse(error);
      notification.error({
        message: msg,
        duration: 5,
        placement: 'bottomRight'
      })
    }
  }

  const deleteUser = async (id) => {
    try {
      setConfigureLoading(true);
      const res = await audienceListService.deleteUserFromAudience(data?.id, id);
      formatData(res);
      setConfigureLoading(false);
      notification.success({
        message: 'User deleted successfully',
        duration: 5,
        placement: 'bottomRight'
      })
    } catch (error) {
      setConfigureLoading(false);
      const {msg} = getErrorMessageFromResponse(error);
      notification.error({
        message: msg,
        duration: 5,
        placement: 'bottomRight'
      })
    }
  }

  const updateUserAccess = async (action, value) => {
    try {
      setConfigureLoading(true);
      let payload = {};
      if (action === 'ENABLE_ALL') {
         payload = {
          audience_project_id: data.id,
          enabled_for_all: value,
        }
      } else if (action === 'UPDATE_USER') {
         payload = {
          audience_project_id: data.id,
          user_role: {
            ...value,
          }
        }
      }
      const res = await audienceListService.addUserToAudience(payload);
      formatData(res);
      notification.success({
        message: `${action === 'ENABLE_ALL' ? 'Audience enabled for all GALE users': 'User access updated successfully'}`,
        duration: 5,
        placement: 'bottomRight'
      })
      setConfigureLoading(false);
    } catch (error) {
      setConfigureLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return { 
    loading,
    userData,
    userEmail,
    addUserModal,
    setAddUserModal,
    deleteUser,
    updateUserAccess,
    configureLoading,
    setUserData,
    formatData
  };
};

export default useAudienceUsersAndPermissionsHook;