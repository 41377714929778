import { notification } from "antd";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import AppUrls from "src/constants/appUrls";
import { getErrorMessageFromResponse } from "src/lib/utils";
import audienceListService from "../../../services/audienceListService";
import createAudienceService from "../../../services/createAudienceService";

export default function useAudienceDetailsHook(audienceId, clientId, audienceProfileId) {

  const [audienceLoading, setAudienceLoading] = useState(false);
  const [audienceData, setAudienceData] = useState(null);
  const [audienceFeatures, setAudienceFeatures] = useState({ demographic_features: [], behaviour_features: [] });
  const [overViewIndexingTraitData, setOverviewIndexingTraitData] = useState([]);
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [featureSortBy, setFeatureSortBy] = useState("-index_value");
  const [featureListTotalCount, setFeatureListTotalCount] = useState(0);
  const [isFeatureLoading, setIsFeatureLoading] = useState(false);
  const [overViewFeatureType, setOverViewFeatureType] = useState("");
  const [behaviourFeaturesCategories, setBehaviourFeaturesCategories] = useState([]);
  const [brandAffinityCategories, setBrandAffinityCategories] = useState([]);
  const [searchFeatureLoading, setSearchFeatureLoading] = useState(false);
  const [audienceProfile, setAudienceProfile] = useState(null);

  const handleChatWithPersona = () => {
    if (audienceProfile?.ai_app_id !== null) {
      window.open(AppUrls.APP_PAGE(clientId, audienceProfile?.ai_app_id), '_blank');
    }
  }

  const getFeatureData = async (orderBy, featureName, featureType, groupByParent) => {
    const profileId = audienceProfileId;
    if (profileId === null) {
      return;
    }
    setIsFeatureLoading(true);
    const featureData = await audienceListService.getAudienceFeatureProfiles(profileId, orderBy, featureName, currentPage, featureType, groupByParent);
    setFeatureListTotalCount(featureData.data.total);
    setOverviewIndexingTraitData(featureData.data.data)
    setIsFeatureLoading(false);
  }

  const extractAllNodes = (data, path = []) => {
    let allNodes = [];

    for (const [key, value] of Object.entries(data)) {
      const levelName = path.length === 0 ? "parent_folder" : `sub_folder${path.length}`;
      const currentPath = [...path, { directory_level: levelName, level_value: key }];

      // Add the current node to the list
      allNodes.push({
        leaf_node: key,
        directory_path: currentPath,
      });

      if (typeof value === 'object' && value !== null && Object.keys(value).length > 0) {
        // If the value is a non-empty object, recurse into it
        allNodes = allNodes.concat(extractAllNodes(value, currentPath));
      }
    }

    return allNodes;
  };

  const extractBrandAffinityFeatures = (data, path = []) => {
    let allNodes = [];
    for (const [key, value] of Object.entries(data)) {
      const levelName = path.length === 0 ? "parent_folder" : `sub_folder${path.length}`;
      const currentPath = [...path, { directory_level: levelName, level_value: key }];
      if (key !== 'Product Potential') {
        continue;
      }
      // Add the current node to the list
      if (key !== 'Product Potential') {
        allNodes.push({
          leaf_node: key,
          directory_path: currentPath,
        });
      }

      if (typeof value === 'object' && value !== null && Object.keys(value).length > 0) {
        // If the value is a non-empty object, recurse into it
        allNodes = allNodes.concat(extractAllNodes(value, currentPath));
      }
    }

    return allNodes;
  };

  const getAudienceBehaviouralDirectoryPayload = (clientId) => {
    return {
      'client_space_id': clientId,
      'with_features': false,
    };
  };

  const addSearchBehaviourFields = (fields, behaviourFeatureNames = []) => {
    const existingFieldNames = new Set(fields.behaviour_features.map((field) => field.name));
    const behaviourTransformedFields = behaviourFeatureNames
      .filter((behaviour) => !existingFieldNames.has(behaviour))
      .map((behaviour) => ({
        feature_name: behaviour,
        feature_readable_name: behaviour,
      }));

    return {
      ...fields,
      behaviour_features: [...fields.behaviour_features, ...behaviourTransformedFields],
    };
  };

  const handleSearch = useCallback(
    debounce(async (value) => {
      setSearchFeatureLoading(true);
      try {
        const res = await createAudienceService.getAudienceFeature(clientId, value);
        if (res.data?.behaviour_features && res.data.behaviour_features.length > 0) {
          const newUpdatedFields = addSearchBehaviourFields(audienceFeatures, res.data.behaviour_features);
          setAudienceFeatures(newUpdatedFields);
        }
        setSearchFeatureLoading(false);
      } catch (error) {
        setSearchFeatureLoading(false);
        console.error("Error fetching search results:", error);
      }
    }, 800),
    [clientId, audienceFeatures]
  );

  useEffect(() => {
    async function fetchData() {
      try {
        setAudienceLoading(true);
        const audience = await audienceListService.getAudienceConfigById(audienceId);
        const metaDataRes = await createAudienceService.getAudienceMetadata(clientId);
        const filteredDemographicData = metaDataRes.data.filter((feature) => feature.feature_values.length > 0);
        const behaviouralDirectoryPayload = getAudienceBehaviouralDirectoryPayload(clientId);
        const audienceBehaviouralFeatures = await createAudienceService.getAudienceBehaviouralDirectory(behaviouralDirectoryPayload);
        const audienceBehaviourFolders = extractAllNodes(audienceBehaviouralFeatures.data);
        const brandAffinityFolder = extractBrandAffinityFeatures(audienceBehaviouralFeatures.data);
        setBehaviourFeaturesCategories(audienceBehaviourFolders)
        setBrandAffinityCategories(brandAffinityFolder)
        setAudienceFeatures({ 'demographic_features': filteredDemographicData, behaviour_features: [] })
        await getFeatureData("-index_value", "");
        const audienceProfile = audience?.data?.audience_profiles.find(profile => profile.id === audienceProfileId);
        setAudienceProfile(audienceProfile);
        setAudienceData(audience.data);
        setAudienceLoading(false);
      } catch (error) {
        setAudienceLoading(false);
        const { name, details, msg } = getErrorMessageFromResponse(error);
        const errorMessage = details?.error || msg || 'Failed to get audience';
        notification.error({
          key: details?.resource_name || "Error",
          message: errorMessage,
          duration: 5,
          placement: "bottomRight",
        });
      }
    }
    fetchData();
  }, [audienceId]);

  useEffect(() => {
    getFeatureData(featureSortBy, selectedFeature, overViewFeatureType, false);
  }, [currentPage, featureSortBy]);

  return { audienceLoading, audienceData, handleChatWithPersona, audienceFeatures, overViewIndexingTraitData, getFeatureData, setSelectedFeature, selectedFeature, currentPage, setCurrentPage, setFeatureSortBy, featureSortBy, featureListTotalCount, isFeatureLoading, overViewFeatureType, setOverViewFeatureType, behaviourFeaturesCategories, brandAffinityCategories, handleSearch, searchFeatureLoading, audienceProfileId, audienceProfile };

}
