import { css } from "@linaria/core";

export const dashboardContainer = css`
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: var(--color-full-black);
  @media screen and (min-width: 768px) {
    padding: 3rem 54px;
  }
`;

export const contentParent = css`
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding:0 20px;
`;

export const topBar = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: var(--color-full-black);
  padding-right: 30px;
`;

export const taskList = css`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-right: 16px;
`;

export const loadingContainer = css`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
`;

export const emptyStateContainer = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 40vh;
  text-align: center;
  padding: 32px;
  background: var(--color-90-black);
  border-radius: 8px;
  margin-top: 24px;

  .ant-empty-image {
    opacity: 0.5;
  }
`;

export const emptyStateDescription = css`
  color: var(--color-60-gray);
  font-size: 16px;
  margin: 24px 0;
`;

export const customErrorMessage = css`
  .ant-message-error {
    .ant-message-notice-content {
      color: #ff4d4f;
      background-color: #fff2f0;
      border: 1px solid #ffccc7;
    }
  }
`;

export const loaderTrigger = css`
  width: 100%;
  height: 1px;
  visibility: hidden;
`;

export const loadingMoreContainer = css`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
`;
