import { Checkbox, Col, Collapse, Form, Modal, Row } from "antd";
import React from "react";
import { PrimaryButton, SecondaryButton } from "src/components/basic";
import CaretIconGenerator from "src/components/CaretIconGenerator";
import ConditionalRender from "src/components/conditionalRender";
import LoadingSpinner from "src/components/loadingSpinner";
import AppUrls from "src/constants/appUrls";
import { VIEWER } from "src/modules/audienceTool/audienceManager/constants/constants";
import NcBreadCrumb from "src/modules/newsAndCulture/components/NcBreadCrumb";
import Style from "./selectFeatures.style";
import useSelectFeaturesHook from "./useSelectFeaturesHook";

export default function SelectFeatures({ history, match }) {
  const {
    loading,
    data,
    initialValues,
    formRef,
    selectAllFeatures,
    updateCluster,
    configureLoading,
    featureByCategory,
    categorySelected,
    selectSpeceficFeatures,
    featureData,
    clusterId,
    clientSpaceId,
    viewMode,
    savedChanges,
    setSavedChanges,
    audienceConfigId,
    audienceRole
  } = useSelectFeaturesHook({
    history,
    match,
  });
  if (loading) {
    return <LoadingSpinner centered />;
  }
  return (
    <>
      <NcBreadCrumb className={Style.breadCrumb} />
      <Row justify="space-between">
        <Col>
          <h1>Select Features</h1>
        </Col>
        <Col>
          <Row gutter={[20, 0]}>
            <Col>
              <SecondaryButton
                onClick={() => {
                  if (match.path.includes('audience-tool')) {
                    history.push(AppUrls.EDIT_CLUSTER_TOOL(clientSpaceId, clusterId, audienceConfigId));
                  } else {
                    history.push(AppUrls.EDIT_CLUSTER(clientSpaceId, clusterId, audienceConfigId));
                  }
                }}
                className="small"
              >
                Back
              </SecondaryButton>
            </Col>
            <ConditionalRender shouldRender={data?.state !== "PROJECT_BRIEF_WRITTEN"}>
              <Col>
                <PrimaryButton
                  disabled={data?.state === "FINDING_RELEVANT_FEATURES"}
                  loading={configureLoading}
                  className="small"
                  onClick={() => {
                    if (data?.state === "CREATING_AUDIENCES") {
                      if (match.path.includes('audience-tool')) {
                        history.push(AppUrls.CLUSTER_CONFIG_FEATURES_TOOL(clientSpaceId, clusterId, audienceConfigId))
                      } else {
                        history.push(AppUrls.CLUSTER_CONFIG_FEATURES(clientSpaceId, clusterId, audienceConfigId))
                      }
                    }
                    else if (savedChanges) {
                      Modal.warn({
                        title: "You have modified some values, hence the model will rerun",
                        okText: "Re-run",
                        cancelText: "Discard Changes",
                        okCancel: true,
                        onCancel: () => {
                          if (match.path.includes('audience-tool')) {
                            history.push(AppUrls.CLUSTER_CONFIG_FEATURES_TOOL(clientSpaceId, clusterId, audienceConfigId))
                          } else {
                            history.push(AppUrls.CLUSTER_CONFIG_FEATURES(clientSpaceId, clusterId, audienceConfigId))
                          }
                        },
                        onOk: async () => {
                          formRef.current.submit()
                        },
                      });
                    } else {
                      if (match.path.includes('audience-tool')) {
                        history.push(AppUrls.CLUSTER_CONFIG_FEATURES_TOOL(clientSpaceId, clusterId, audienceConfigId))
                      } else {
                        history.push(AppUrls.CLUSTER_CONFIG_FEATURES(clientSpaceId, clusterId, audienceConfigId))
                      }
                    }
                  }}
                >
                  Next
                </PrimaryButton>
              </Col>
            </ConditionalRender>
            <ConditionalRender shouldRender={data?.state === "PROJECT_BRIEF_WRITTEN"}>
              <Col>
                <PrimaryButton disabled={viewMode || audienceRole === VIEWER} loading={configureLoading} className="small" onClick={() => formRef.current.submit()}>
                  Save and continue
                </PrimaryButton>
              </Col>
            </ConditionalRender>
          </Row>
        </Col>
      </Row>
      <h3>
        Choose the features you'd like to use for propensity modeling. These features will help us craft the best clusters for you. 💡
      </h3>
      <Form
        initialValues={initialValues}
        ref={formRef}
        requiredMark={false}
        layout="vertical"
        onFinish={(val) => {
          const categories = Object.keys(featureByCategory);
          let features = [];
          let featureObj = featureData;
          categories.forEach((category) => {
            if (val[category]) {
              val[category].forEach((featureId) => {
                let obj = featureData[featureId];
                featureObj = { ...featureObj, [featureId]: { ...featureObj[featureId], is_selected: true } };
                obj = { ...obj, is_selected: true };
                features.push(obj);
              });
            }
          });
          if (features.length < 5) {
            formRef.current.setFields([
              {
                name: "feature_parent",
                errors: ["Select Atleast 5 Features"],
              },
            ]);
          } else {
            const totalSelectedFeatures = features.map((feature) => feature.id);
            Object.keys(featureObj).forEach((featureId) => {
              let tempObj = featureObj[featureId];
              if (!totalSelectedFeatures.includes(featureId)) {
                tempObj = { ...tempObj, is_selected: false };
                features.push(tempObj);
              } else {
              }
            });
            updateCluster({ add_e1_features: val.add_e1_features, features });
          }
        }}
        onValuesChange={(changedValues, allValues) => {
          if (!savedChanges) {
            setSavedChanges(true);
          }
          if (changedValues?.features) {
            formRef.current.setFields([
              {
                name: "feature_parent",
                errors: [],
              },
            ]);
          }
        }}
      >
        <Form.Item name="feature_parent">
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue, setFieldsValue }) => {
              return (
                <>
                  <div className={Style.columnContainer}>
                    <Checkbox defaultChecked onChange={(e) => selectAllFeatures(getFieldValue, setFieldsValue, e.target.checked)}>
                      {" "}
                      Select All
                    </Checkbox>
                  </div>
                  <div className={Style.featureContainer}>
                    <div style={{ marginLeft: "1rem" }}>
                      {Object.keys(featureByCategory)?.map((item) => {
                        return (
                          <div className={Style.categoryContainer}>
                            <div style={{ marginTop: "4px" }}>
                              <Checkbox
                                checked={categorySelected[item]}
                                onChange={(e) => selectSpeceficFeatures(getFieldValue, setFieldsValue, e.target.checked, item)}
                              ></Checkbox>
                            </div>
                            <div style={{ flexGrow: "1" }}>
                              <Collapse
                                className={"analysisCollapse"}
                                expandIcon={({ isActive }) => <CaretIconGenerator size={16} rotate={isActive ? 180 : 0} />}
                                defaultActiveKey={["1"]}
                              >
                                <Collapse.Panel header={item} key="1">
                                  <div>
                                    <Form.Item name={item} noStyle>
                                      <Checkbox.Group>
                                        <Row gutter={[20, 20]}>
                                          {featureByCategory[item].map((feature, idx) => {
                                            return (
                                              <Col span={12} key={idx}>
                                                <div className={Style.columnContainer}>
                                                  <Checkbox value={feature.id}>{feature?.feature_readable_name}</Checkbox>
                                                </div>
                                              </Col>
                                            );
                                          })}
                                        </Row>
                                      </Checkbox.Group>
                                    </Form.Item>
                                  </div>
                                </Collapse.Panel>
                              </Collapse>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </>
              );
            }}
          </Form.Item>
        </Form.Item>
        <h3 style={{ marginTop: "2rem" }}>
          <i>
            Do you want to include more behavioural features from the E1 segments? Selecting this could significantly increase the modelling
            time.
          </i>
        </h3>
        <Form.Item name="add_e1_features" valuePropName="checked" noStyle>
          <Checkbox>Add behavioural features for modelling</Checkbox>
        </Form.Item>
      </Form>
    </>
  );
}
