import { DeleteOutlined } from "@ant-design/icons";
import { Checkbox, Col, List, Modal, Row, Select } from "antd";
import React from "react";
import { PrimaryButton } from "src/components/basic";
import ConditionalRender from "src/components/conditionalRender";
import LoadingSpinner from "src/components/loadingSpinner";
import { formatString } from "src/lib/utils";
import { AUDIENCE_USER_ROLE_OPTIONS } from "../../constants/constants";
import AudienceAddUserForm from "./AudienceAddUserForm";
import "./AudienceUsersAndPermissions.less";
import useAudienceUsersAndPermissionsHook from "./useAudienceUsersAndPermissionsHook";

const AudienceUsersAndPermissions = ({ data, userLists }) => {
  const {
    loading,
    userData,
    userEmail,
    addUserModal,
    setAddUserModal,
    deleteUser,
    updateUserAccess,
    configureLoading,
    setUserData,
    formatData
  } = useAudienceUsersAndPermissionsHook({
    data,
  });
  if (loading) {
    return <LoadingSpinner centered={true} />;
  }
  return (
    <div className="container">
      <Row gutter={[20, 0]} justify="end" style={{ marginBottom: "1rem" }}>
        <Col>
          <PrimaryButton
            className="small"
            comfy
            onClick={() => setAddUserModal(true)}
          >
            Add User
          </PrimaryButton>
        </Col>
      </Row>
      <Checkbox
        disabled={configureLoading}
        checked={userData.enabledForAll}
        onChange={(e) => {
          updateUserAccess("ENABLE_ALL", e.target.checked);
        }}
        style={{ marginBottom: "1rem" }}
      >
        Enable for everyone with client space access
      </Checkbox>
      <List
        className="list"
        loading={configureLoading}
        dataSource={userData.users || []}
        header={
          <div className="listHeader">
            <div style={{ width: "37%" }}>Email</div>
            <div style={{ width: "37%" }}>Name</div>
            <div style={{ width: "26%" }}>Role</div>
          </div>
        }
        renderItem={(item, idx) => {
          let name = "";
          if (item?.user?.first_name) {
            name = name + item?.user?.first_name;
          }
          if (item?.user?.last_name) {
            name = name + " " + item?.user?.last_name;
          }
          let isOwner = false;
          if (item?.role === "OWNER") {
            isOwner = true;
          }
          return (
            <div className="listItem">
              <div style={{ width: "37%" }}>{item?.user?.email}</div>
              <div style={{ width: "37%" }}>{name}</div>
              <div style={{ width: "26%" }} className="deleteContainer">
                <ConditionalRender shouldRender={isOwner}>
                  Owner
                </ConditionalRender>
                <ConditionalRender shouldRender={!isOwner}>
                  {item?.user?.email === userEmail ? (
                    "Admin"
                  ) : (
                    <Select
                      className="small"
                      value={item?.role}
                      style={{ width: "50%" }}
                      onChange={(val) => {
                        updateUserAccess("UPDATE_USER", {
                          email: item?.user?.email,
                          role: val
                        });
                      }}
                    >
                      {AUDIENCE_USER_ROLE_OPTIONS?.map((ele) => {
                        if (ele !== "OWNER") {
                          return (
                            <Select.Option key={ele} value={ele}>
                              {formatString(ele)}
                            </Select.Option>
                          );
                        }
                      })}
                    </Select>
                  )}
                </ConditionalRender>
                <div>
                  {isOwner || item?.user?.email === userEmail ? (
                    ""
                  ) : (
                    <DeleteOutlined onClick={() => deleteUser(item?.user?.id)} />
                  )}
                </div>
              </div>
            </div>
          );
        }}
      />
      <Modal
        title="Add User"
        maskClosable={false}
        destroyOnClose
        open={addUserModal}
        onCancel={() => setAddUserModal(false)}
        footer={null}
      >
        <AudienceAddUserForm
          setAddUserModal={setAddUserModal}
          audienceId={data?.id}
          formatData={formatData}
          userLists={userLists}
        />
      </Modal>
    </div>
  );
};

export default AudienceUsersAndPermissions;
