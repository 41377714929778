import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import AppUrls from "src/constants/appUrls";
import { Layout } from "antd";
import "./MediaPlanLayout.less";
import assets from "src/assets";

import CreateMediaPlanForm from "../CreateMediaPlan/CreateMediaPlanForm";
import { MediaPlanAudienceInformation } from "../mediaPlanAudienceInformation/MediaPlanAudienceInformation";
import { MediaplanScenarioPlan } from "../mediaPlanScenario/MediaplanScenarioPlan";
import MediaPlansListing from "../mediaPlanListing/MediaPlansListing";
import MediaplanOutput from "../mediaPlanOutput/MediaplanOutput";

const { Content } = Layout;

const MediaPlanLayout = () => {
  return (
    <>
      <Layout className="layout">
        <Content className="page-container">
          <Switch>
            <Route
              exact
              path={AppUrls.MEDIA_PLAN_LISTING(":clientId")}
              component={MediaPlansListing}
            />
            <Route
              path={[AppUrls.CREATE_MEDIA_PLAN(":clientId"), AppUrls.EDIT_MEDIA_PLAN(":clientId", ":mediaPlanId")]}
              component={CreateMediaPlanForm}
            />
            <Route
              path={AppUrls.MEDIA_PLAN_AUDIENCE_INFO(
                ":clientId",
                ":mediaPlanId"
              )}
              component={MediaPlanAudienceInformation}
            />
            <Route
              path={AppUrls.MEDIA_PLAN_SCENARIO_PLANNING_SCREEN(
                ":clientId",
                ":mediaPlanId"
              )}
              component={MediaplanScenarioPlan}
            />
              <Route
              path={AppUrls.MEDIA_PLAN_SCENARIO_OUTPUT_SCREEN(
                ":clientId",
                ":mediaPlanId"
              )}
              component={MediaplanOutput}
            />
            <Redirect to={AppUrls.MEDIA_PLAN_LISTING(":clientId")} />
          </Switch>
        </Content>
      </Layout>
      <div className={"decorativeBackgroundImage"}>
        <img
          className="select-disable"
          alt="decorative element"
          src={assets.getImage("ClientSpaceWave")}
        />
      </div>
    </>
  );
};

export default MediaPlanLayout;
