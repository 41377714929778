import { Col, Form, Input, Row } from "antd";
import React from "react";
import { PrimaryButton, SecondaryButton } from "src/components/basic";
import useAudienceCloneForm from "./useAudienceCloneForm";

const AudienceCloneForm = ({ data, closeCloneModal, onClone, handleAudienceEdit, handleClusterClone }) => {
  const { formRef, loading, handleClone } = useAudienceCloneForm({
    closeCloneModal,
    onClone,
    data,
    handleAudienceEdit,
    handleClusterClone
  });

  return (
    <div>
      <Form
        ref={formRef}
        onFinish={handleClone}
      >
        <Form.Item
          name="cloned_project_name"
          rules={[
            {
              required: true,
              message: "Please enter name",
            },
          ]}
        >
          <Input placeholder="Enter name" />
        </Form.Item>
        <Row justify="space-between" style={{ marginTop: "5rem" }}>
          <Col>
            <SecondaryButton
              disabled={loading}
              onClick={() => closeCloneModal()}
              className="small"
              comfy
            >
              Cancel
            </SecondaryButton>
          </Col>

          <Col>
            <PrimaryButton
              loading={loading}
              className="small"
              onClick={() => formRef.current.submit()}
            >
              Clone Audience
            </PrimaryButton>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AudienceCloneForm;
