import { notification } from "antd";
import axios from "axios";
import { useState, useEffect, useRef } from "react";
import { formatString, getErrorMessageFromResponse } from "src/lib/utils";
import CustomGptApiServices from "../services/customGptApiServices";
import { CustomGptService } from "../services/customGptServices";
import papa from "papaparse";

export default function useSqlCapabilityHook({ id, aiAppData, configData, updateCustomApp, onClose, reload }) {
  const [connectionString, setConnectionString] = useState([]);
  const [loading, setIsLoading] = useState(true);
  const [configureLoading, setConfigureLoading] = useState(false);
  const formRef = useRef(null);
  const [initialValues, setInitialValues] = useState({ tables: [{ columns: [undefined] }] });
  const [templateData, setTemplateData] = useState([]);

  const getConnectionString = async () => {
    try {
      setIsLoading(true);
      const capabilityData = aiAppData?.capabilities?.find((ele) => ele.capability_name === "SqlCapability");
      const formattedSqlInitialValue = CustomGptService.formatSqlToolInitialValues(capabilityData, configData);
      setInitialValues({ ...initialValues, ...formattedSqlInitialValue });
      const clientSpaceId = aiAppData?.client_space?.id;
      const res = await CustomGptApiServices.getSqlConnection(id, clientSpaceId);
      setConnectionString(res?.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const setInitialTemplate = () => {
    try {
      const capabilityData = aiAppData?.capabilities?.find((ele) => ele.capability_name === "SqlCapability");
      if (capabilityData) {
        const tables = capabilityData?.fields_config?.tables;
        const formattedTables = [];
        tables.forEach((ele) => {
          const basicPayload = {
            Connection: ele?.connection_id,
            "Table name": ele?.name,
            Schema: ele?.schema,
            Description: ele?.description,
            "Is active": ele?.is_active ? "true" : "false",
          };
          const columns = ele.columns;
          columns.forEach((item) => {
            formattedTables.push({
              ...basicPayload,
              "Column name": item?.name,
              "Column description": item?.description,
              "Column type": item?.type,
              "Column is active": item?.is_active ? "true" : "false",
            });
          });
        });
        setTemplateData(formattedTables);
      }
    } catch (error) {}
  };

  const configureCapability = async (payload) => {
    try {
      setConfigureLoading(true);
      const formattedPayload = {
        fields_config: payload,
        capability_name: "SqlCapability",
      };
      const res = await CustomGptApiServices.configureCapabilities(id, formattedPayload);
      updateCustomApp(res.data);
      notification.success({
        duration: 5,
        message: "Sql tool configured successfully",
        placement: "bottomRight"
      });
      setConfigureLoading(false);
      reload();
    } catch (error) {
      setConfigureLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: "bottomRight"
      });
    }
  };

  const commonProps = {
    delimiter: ",",
    header: true,
    worker: true,
    skipEmptyLines: true,
  };

  function validateCSVAndGetRecords(file) {
    return new Promise((resolve, reject) => {
      parseCSV(file)
        .then((results) => {
          try {
            validateCSV(results);
            resolve(results);
          } catch (error) {
            reject(error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  
  function parseCSV(file) {
    return new Promise((resolve, reject) => {
      papa.parse(file, {
        ...commonProps,
        skipEmptyLines: true,
        complete: resolve,
        error: reject,
      });
    });
  }
  
  function validateCSV(results) {
    const foundColumns = results.meta.fields;
    if (!results.data.length || foundColumns.length !== csvColumns.length || foundColumns.find((c) => !csvColumns.includes(c))) {
      throw new Error("Invalid_CSV");
    }
  }

  const formattedCsvHeaders = {
    Connection: "connection_id",
    "Table name": "name",
    Schema: "schema",
    Description: "description",
    "Column name": "name",
    "Column description": "description",
    "Column type": "type",
    "Is active": "is_active",
  };

  const csvColumns = [
    "Connection",
    "Table name",
    "Schema",
    "Description",
    "Column name",
    "Is active",
    "Column description",
    "Column type",
    "Column is active",
  ];

  const parseCsv = async (file) => {
    try {
      setIsLoading(true);
      const res = await validateCSVAndGetRecords(file);
      const formatInitialValues = CustomGptService.formatBulkUploadValues(res, formattedCsvHeaders);
      setInitialValues({ ...initialValues, ...formatInitialValues });
      setIsLoading(false);
      notification.success({
        duration: 5,
        message: "Csv upload successfull",
        placement: "bottomRight"
      });
    } catch (error) {
      setIsLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: formatString(msg),
        placement: "bottomRight"
      });
    }
  };

  useEffect(() => {
    getConnectionString();
    setInitialTemplate();
  }, []);
  return {
    connectionString,
    loading,
    formRef,
    initialValues,
    configureCapability,
    configureLoading,
    validateCSVAndGetRecords,
    csvColumns,
    formattedCsvHeaders,
    parseCsv,
    templateData,
  };
}
