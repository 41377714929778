import { DownloadOutlined, EyeOutlined, MenuUnfoldOutlined, WechatWorkOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Modal, Tabs, Typography } from 'antd';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import LoadingSpinner from 'src/components/loadingSpinner';
import { formatNumberWithPercentageAndCeil, justFormatNumber } from 'src/lib/utils';
import NcBreadCrumb from 'src/modules/newsAndCulture/components/NcBreadCrumb';
import { CLUSTER_BASED, RULE_BASED } from '../../../constants/constants';
import AttitudinalOverview from './AttitudinalOverview';
import './audienceDetails.style.less';
import AudienceOverview from './AudienceOverview';
import BrandAffinities from './BrandAffinities';
import DemographicOverview from './DemographicOverview';
import DownloadManagerComponent from './DownloadManagerComponent';
import GeographicHeatMap from './GeographicHeatMap';
import useAudienceDetailsHook from './useAudienceDetailsHook';

const { Title, Text } = Typography;
const { TabPane } = Tabs;

const AudienceDetailsComponent = ({ history, match }) => {
  const clientId = match.params.clientId;
  const audienceId = match.params.audienceId;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const audienceProfileId = queryParams.get('audience-profile-id');
  const { audienceData, audienceLoading, handleChatWithPersona, audienceFeatures, overViewIndexingTraitData, getFeatureData, setSelectedFeature, selectedFeature, currentPage, setCurrentPage, setFeatureSortBy, featureSortBy, featureListTotalCount, isFeatureLoading, overViewFeatureType, setOverViewFeatureType, behaviourFeaturesCategories, brandAffinityCategories, handleSearch, searchFeatureLoading, audienceProfile } = useAudienceDetailsHook(audienceId, clientId, audienceProfileId);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDownloadModalVisible, setIsDownloadModalVisible] = useState(false);
  const [activeTab, setActiveTab] = useState('1');

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showDownloadModal = () => {
    setIsDownloadModalVisible(true);
  };

  const handleDownloadModalOk = () => {
    setIsDownloadModalVisible(false);
  };

  const handleDownloadModalCancel = () => {
    setIsDownloadModalVisible(false);
  };

  // Generate a human-readable query summary
  const generateQuery = (condition) => {
    if (condition === undefined) {
      return '';
    }
    const generateQueryRecursive = (condition) => {
      const combinator = condition.combinator.toUpperCase();
      const number = condition.number ? ` ${condition.number}` : '';
      const formattedCombinator = (combinator === 'ANY') ? `${combinator}${number} of the` : combinator;

      const rules = condition.rules.map((rule) => {
        if (Array.isArray(rule.rules) && rule.rules.length > 0) {
          return generateQueryRecursive(rule);
        } else {
          if (Array.isArray(rule.value)) {
            const values = rule.value.map(val => val.value_description).join(', ');
            return `${rule.field} ${rule.operator} (${values})`.trim();
          } else {
            const value = typeof rule.value === 'object' ? rule.value.value_description : rule.value;
            return `${rule.field} ${rule.operator} '${value}'`.trim();
          }
        }
      }).join(', ');

      return `${formattedCombinator} (${rules})`;
    };

    return generateQueryRecursive(condition);
  };

  const menuOverlay = (
    <Menu style={{ backgroundColor: 'var(--color-charcoal-black)', color: 'var(--color-white)' }}>
      {audienceProfile?.sql_query && audienceProfile?.sql_query !== null && audienceData?.audience_type === RULE_BASED && (
        <Menu.Item key="showQuery" style={{ backgroundColor: 'var(--color-charcoal-black)', color: 'var(--color-white)' }} onClick={showModal}>
          <EyeOutlined /> Show Query
        </Menu.Item>
      )}
      <Menu.Item key="chatWithPersona" style={{ backgroundColor: 'var(--color-charcoal-black)', color: 'var(--color-white)' }} onClick={handleChatWithPersona}>
        <WechatWorkOutlined /> Chat with Persona
      </Menu.Item>
      <Menu.Item key="downloadCSV" style={{ backgroundColor: 'var(--color-charcoal-black)', color: 'var(--color-white)' }} onClick={showDownloadModal}>
        <DownloadOutlined /> Download Report
      </Menu.Item>
    </Menu>
  );

  if (audienceLoading) {
    return <LoadingSpinner centered />;
  }

  return (
    <div className="audience-details-container">
      <NcBreadCrumb className='breadCrumb' />
      <div className="header">
        <div className="header-content">
          <Title level={2} className="title">
            {audienceData?.audience_type === CLUSTER_BASED ? audienceProfile.cluster_name : audienceData?.name}
          </Title>
          <Dropdown overlay={menuOverlay} trigger={['click']}>
            <MenuUnfoldOutlined
              style={{ color: 'var(--color-white)', fontSize: '20px', cursor: 'pointer' }}
              onClick={(e) => e.stopPropagation()} />
          </Dropdown>
        </div>
        <Text className="description">
          {audienceData?.audience_type === CLUSTER_BASED ? audienceProfile.cluster_description : audienceData?.description}
        </Text>
        <Text className="audience-size">Audience Size: {justFormatNumber(audienceProfile?.audience_size)} ({formatNumberWithPercentageAndCeil((audienceProfile?.audience_size / audienceProfile?.base_size) * 100)} of total population)</Text>
      </div>

      <div className="audience-persona-section">
        <div className="persona-section">
          <div className="persona-description">
            <Text className="persona-description-text">
              <b>Persona Description:</b> {audienceProfile?.persona_description}
            </Text>
          </div>
        </div>
      </div>

      <Tabs activeKey={activeTab} className="tabs" onChange={handleTabChange}>
        <TabPane tab="Overview" key="1">
          <AudienceOverview
            audienceTrait={overViewIndexingTraitData}
            getFeatureData={getFeatureData}
            audienceFeatures={audienceFeatures}
            setSelectedFeature={setSelectedFeature}
            selectedFeature={selectedFeature}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            setFeatureSortBy={setFeatureSortBy}
            featureSortBy={featureSortBy}
            featureListTotalCount={featureListTotalCount}
            isFeatureLoading={isFeatureLoading}
            overViewFeatureType={overViewFeatureType}
            setOverViewFeatureType={setOverViewFeatureType}
            handleSearch={handleSearch}
            searchFeatureLoading={searchFeatureLoading}
          />
        </TabPane>
        <TabPane tab="Demographic Overview" key="2">
          <DemographicOverview audienceFeatures={audienceFeatures} audienceId={audienceProfileId} />
        </TabPane>
        <TabPane tab="Geographic Heatmap" key="3">
          <GeographicHeatMap audienceId={audienceProfileId} />
        </TabPane>
        <TabPane tab="Attitudinal Overview" key="4">
          <AttitudinalOverview audienceId={audienceProfileId} behaviourFeaturesCategories={behaviourFeaturesCategories} activeTab={activeTab} />
        </TabPane>
        <TabPane tab="Brand Affinities" key="5">
          <BrandAffinities audienceId={audienceProfileId} brandAffinityCategories={brandAffinityCategories} activeTab={activeTab} />
        </TabPane>
      </Tabs>
      <Modal
        title="Query"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="submit" type="primary" onClick={handleOk}>
            OK
          </Button>,
        ]}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab="Query Summary" key="1">
            <pre className="sql-query">{generateQuery(audienceData?.type_based_config?.json_data?.rules)}</pre>
          </TabPane>
          <TabPane tab="SQL Query" key="2">
            <pre className="sql-query">{audienceProfile?.sql_query}</pre>
          </TabPane>
        </Tabs>
      </Modal>
      <Modal
        title="Download Manager"
        visible={isDownloadModalVisible}
        onOk={handleDownloadModalOk}
        onCancel={handleDownloadModalCancel}
        footer={null}
      >
        <DownloadManagerComponent audienceProfileId={audienceProfileId} handleDownloadModalCancel={handleDownloadModalCancel} />
      </Modal>
    </div >
  );
};

export default AudienceDetailsComponent;