export const SET_TASKS = "SET_TASKS";
export const APPEND_TASKS = "APPEND_TASKS";
export const SET_LOADING = "SET_LOADING";
export const SET_LOADING_MORE = "SET_LOADING_MORE";
export const SET_SEARCH_TERM = "SET_SEARCH_TERM";
export const SET_SORT_BY = "SET_SORT_BY";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const SET_TOTAL_PAGES = "SET_TOTAL_PAGES";
export const UPDATE_TASKS = "UPDATE_TASKS";
export const SET_TABLE_DATA_SOURCE = "SET_TABLE_DATA_SOURCE";
export const SET_TOTAL = "SET_TOTAL";
