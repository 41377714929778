import { useEffect, useRef } from "react";

export default function useInputContainerHook({data, generatingResponse}) {
  let placeholderName = data?.assistant_display_name;
  if (placeholderName === "Chat") {
    placeholderName = data?.client_space?.name;
  }

  const formRef = useRef();

  useEffect(() => {
    if (!generatingResponse) {
      formRef?.current?.setFieldsValue({
        "user_message": ""
      });
    }
  },[generatingResponse]);

  return {
    placeholderName,
    formRef
  }
}