import { Button, Checkbox, Form, InputNumber, Select } from 'antd';
import React from 'react';
import { removeUnderscores } from 'src/lib/utils';
import { BEHAVIOURAL, BRAND_AFFINITY, DEMOGRAPHIC, GEOGRAPHIC } from '../../../constants/audienceDetailsConstants';
import useDownloadManagerHook from './useDownloadManagerHook';

const { Option } = Select;

const DownloadManagerComponent = ({ audienceProfileId, handleDownloadModalCancel }) => {
    const {
        selectedFeatureType,
        selectedColumns,
        numRows,
        columns,
        sortOrder,
        handleFeatureTypeChange,
        handleColumnChange,
        handleNumRowsChange,
        handleSortOrderChange,
        handleDownload,
        isDownloading
    } = useDownloadManagerHook(audienceProfileId, handleDownloadModalCancel);

    const isFormValid = selectedColumns.length > 0 && numRows > 0;

    return (
        <Form layout="vertical">
            <Form.Item label="Feature Type">
                <Select
                    style={{ width: '100%' }}
                    placeholder="Select a feature Type"
                    onChange={handleFeatureTypeChange}
                    value={selectedFeatureType}
                >
                    <Option value={DEMOGRAPHIC}>{removeUnderscores(DEMOGRAPHIC)}</Option>
                    <Option value={BEHAVIOURAL}>{removeUnderscores(BEHAVIOURAL)}</Option>
                    <Option value={BRAND_AFFINITY}>{removeUnderscores(BRAND_AFFINITY)}</Option>
                    <Option value={GEOGRAPHIC}>{removeUnderscores(GEOGRAPHIC)}</Option>
                    <Option value="">All</Option>
                </Select>
            </Form.Item>
            <Form.Item label="Columns">
                <Checkbox.Group
                    options={columns.map(col => ({ label: col.label, value: col.value }))}
                    defaultValue={selectedColumns}
                    onChange={handleColumnChange}
                />
            </Form.Item>
            <Form.Item label="Number of Rows">
                <InputNumber min={1} value={numRows} onChange={handleNumRowsChange} style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item label="Sort Order">
                <Select
                    style={{ width: '100%' }}
                    placeholder="Select Sort Order"
                    onChange={handleSortOrderChange}
                    value={sortOrder}
                >
                    <Option value="-index_value">Index - Highest to Lowest</Option>
                    <Option value="index_value">Index - Lowest to Highest</Option>
                    <Option value="-audience_feature_percentage">Audience Percentage - Highest to Lowest</Option>
                    <Option value="audience_feature_percentage">Audience Percentage - Lowest to Highest</Option>
                </Select>
            </Form.Item>
            <Form.Item>
                <Button type="primary" onClick={handleDownload} loading={isDownloading} disabled={!isFormValid || isDownloading} style={{ width: '100%' }}>
                    Download CSV
                </Button>
            </Form.Item>
        </Form>
    );
};

export default DownloadManagerComponent;