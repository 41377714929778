import { useState, useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import { numberWithCommas } from "src/lib/utils";
import mediaPlanService from "../../../services/mediaPlanService";

const useMediaPlanScenarioPlan = (initialScenario) => {
  const [loading, setLoading] = useState(true);
  const [columns, setColumns] = useState([]);
  const [scenario, setScenario] = useState(null);
  const { clientId, mediaPlanId } = useParams();
  const history = useHistory();
  const formRef = useRef(null);
  const responseId = mediaPlanId;

  const getScenarioPlan = async () => {
    try {
      setLoading(true);
      const res = await mediaPlanService.getMediaPlanScenario(responseId);
      let column = Object.keys(res.data.format);
      let formattedColumns = column.map((item) => {
        return {
          title: item,
          dataIndex: item,
          key: item,
          render: (text) => {
            const unit = res.data.format?.[item]?.unit;
            if (text) {
              let formatNumber = text;
              if (Number(text) === formatNumber && formatNumber % 1 !== 0) {
                formatNumber = formatNumber.toFixed(2);
              }
              if (unit) {
                if (unit !== "$") {
                  return `${formatNumber}${unit}`;
                } else if (unit === "$") {
                  return `${unit}${numberWithCommas(formatNumber)}`;
                }
              } else {
                return text;
              }
            } else {
              return "N/A";
            }
          },
        };
      });
      setColumns(formattedColumns);
      setScenario(res.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const getColumns = (column) => {
    let formattedColumns = column.map((item) => {
      let titleCheck = item.split("_");
      let newTitle  = titleCheck.join(" ");
      return {
        title: newTitle,
        dataIndex: item,
        key: item,
        render: (text) => {
          const unit = scenario?.format?.[item]?.unit;
          if (text) {
            let formatNumber = text;
            if (Number(text) === formatNumber && formatNumber % 1 !== 0) {
              formatNumber = formatNumber.toFixed(2);
            }
            if (unit) {
              if (unit !== "$") {
                return `${formatNumber}${unit}`;
              } else if (unit === "$") {
                return `${unit}${numberWithCommas(formatNumber)}`;
              }
            } else {
              if (Number(formatNumber)) {
                return numberWithCommas(formatNumber);
              } else {
                return text;
              }
            }
          } else {
            return "N/A";
          }
        },
      };
    });
    return formattedColumns;
  };

  useEffect(() => {
    getScenarioPlan();
  }, []);

  return {
    loading,
    formRef,
    scenario,
    columns,
    getColumns,
    history,
    clientId,
    responseId
  };
};

export default useMediaPlanScenarioPlan;
