
export const TAG_COLOR_NAME = {
  GREEN:'GREEN',
  RED:'RED',
  YELLOW:'YELLOW',
  ORANGE:'ORANGE',
  BLUE:'BLUE',
  PURPLE:'PURPLE',
  GRAY:'GRAY',
  CYAN:'CYAN',
}

export const TAG_TXT_COLOR_MAP = {
  GREEN:'var(--color-tag-green-txt)',
  RED:'var(--color-tag-red-txt)',
  YELLOW:'var(--color-tag-yellow-txt)',
  ORANGE:'var(--color-tag-orange-txt)',
  BLUE:'var(--color-tag-blue-txt)',
  PURPLE:'var(--color-tag-purple-txt)',
  GRAY:'var(--color-tag-gray-txt)',
  CYAN:'var(--color-tag-cyan-txt)',
}
export const TAG_BORDER_COLOR_MAP ={
  GREEN:'var(--color-tag-green-border)',
  RED:'var(--color-tag-red-border)',
  YELLOW:'var(--color-tag-yellow-border)',
  ORANGE:'var(--color-tag-orange-border)',
  BLUE:'var(--color-tag-blue-border)',
  PURPLE:'var(--color-tag-purple-border)',
  GRAY:'var(--color-tag-gray-border)',
  CYAN:'var(--color-tag-cyan-border)',
}

export const TAG_BACKGROUND_COLOR_MAP = {
  GREEN:'var(--color-tag-green-background)',
  RED:'var(--color-tag-red-background)',
  YELLOW:'var(--color-tag-yellow-background)',
  ORANGE:'var(--color-tag-orange-background)',
  BLUE:'var(--color-tag-blue-background)',
  PURPLE:'var(--color-tag-purple-background)',
  GRAY:'var(--color-tag-gray-background)',
  CYAN:'var(--color-tag-cyan-background)',
}

export const ACTIVATION_GROUP_STATUS_COLOR = {
  PUBLISHED: TAG_COLOR_NAME.GREEN,
  ERROR: TAG_COLOR_NAME.RED,
  DRAFT: TAG_COLOR_NAME.YELLOW,
  PUBLISHING: TAG_COLOR_NAME.YELLOW,
};

export const EVALUATION_STATUS_COLOR = {
  COMPLETED: TAG_COLOR_NAME.GREEN,
  ERROR: TAG_COLOR_NAME.RED,
  IN_PROGRESS: TAG_COLOR_NAME.YELLOW,
  PENDING: TAG_COLOR_NAME.YELLOW,
};

export const JOB_TRIGGER_STATUS_COLOR ={
  IN_PROGRESS: TAG_COLOR_NAME.BLUE,
  IN_QUEUE: TAG_COLOR_NAME.ORANGE,
  DONE: TAG_COLOR_NAME.GREEN,
  null: TAG_COLOR_NAME.YELLOW,
  ERROR: TAG_COLOR_NAME.RED,
}

export const USE_LISTING_STATUS_COLOR = {
  'IN_PROGRESS': TAG_COLOR_NAME.BLUE,
  'IN_QUEUE': TAG_COLOR_NAME.ORANGE,
  'DONE': TAG_COLOR_NAME.GREEN,
  'DRAFT': TAG_COLOR_NAME.YELLOW,
  'null': TAG_COLOR_NAME.YELLOW,
  'ERROR': TAG_COLOR_NAME.RED,
};

export const NEW_CAMPAIGN_STATUS_BACKGROUND_COLOR = {
  DONE: TAG_COLOR_NAME.GREEN,          
  DRAFT: TAG_COLOR_NAME.YELLOW,
  ERROR: TAG_COLOR_NAME.RED,
  SELECTING_FEATURES: TAG_COLOR_NAME.YELLOW,
  CREATING_CLUSTERS : TAG_COLOR_NAME.YELLOW,
  SELECTED_FEATURES: TAG_COLOR_NAME.ORANGE,
  FINDING_RELEVANT_FEATURES: TAG_COLOR_NAME.YELLOW,
  RELEVANT_FEATURES_FOUND: TAG_COLOR_NAME.GREEN,
  CREATING_AUDIENCES: TAG_COLOR_NAME.YELLOW,
  CREATED_SAMPLE_CLUSTERS: TAG_COLOR_NAME.GREEN,
  AUDIENCES_CREATED: TAG_COLOR_NAME.GREEN,
  CREATING_FINAL_CLUSTERS: TAG_COLOR_NAME.YELLOW,
  CREATED_FINAL_CLUSTERS: TAG_COLOR_NAME.ORANGE,
  CREATING: TAG_COLOR_NAME.YELLOW,
  CREATED: TAG_COLOR_NAME.GREEN,
  PROJECT_BRIEF_WRITTEN: TAG_COLOR_NAME.YELLOW,
};